import React from 'react'
import { Link } from 'react-router-dom'

const Tables = () => {
  return (
    <div>
        <h3>STATISTICAL TABLES</h3>
        <ul><Link to="/normal-dist-table">Normal Distribution</Link></ul>
        <ul><Link to="/chi-square-dist-table">Chi-Square Distribution</Link></ul>
        <ul><Link to="/binomial-dist-table">Binomial Distribution</Link></ul>
        <ul><Link to="/student-t-dist-table">Student’s Distribution (t Distribution)</Link></ul>
        <ul><Link to="/f-dist-table">F Distribution</Link></ul>
        <ul><Link to="/mann-whitney-test-stats">Quantiles of the Mann-Whitney Test Statistic</Link></ul>
        <ul><Link to="/kruskal-wallis-stats">Quantiles of the Kruskal-Wallis Test Statistics for Small Sample Sizes</Link></ul>
        <ul><Link to="/wilcoxon-signed-rank-test">Quantiles of the Wilcoxon Signed Rank Test Statistic</Link></ul>
        <ul><Link to="/kendall-test-stats">Quantiles of Kendall’s Test Statistic</Link></ul>
        <ul><Link to="/kilmogorov-test-stats">Quantiles of the Kilmogorov Test Statistic</Link></ul>
        <ul><Link to="/smirnov-test-stats-equal">Quantiles of the Smirnov Test Statistic for Two Samples of Equal Size</Link></ul>
        <ul><Link to="/smirnov-test-stats-different">Quantiles of the Smirnov Test Statistic for Two Samples of Different Size</Link></ul>
    </div>
  )
}

export default Tables
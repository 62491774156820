// Imports
import ___CSS_LOADER_API_SOURCEMAP_IMPORT___ from "../../node_modules/css-loader/dist/runtime/sourceMaps.js";
import ___CSS_LOADER_API_IMPORT___ from "../../node_modules/css-loader/dist/runtime/api.js";
var ___CSS_LOADER_EXPORT___ = ___CSS_LOADER_API_IMPORT___(___CSS_LOADER_API_SOURCEMAP_IMPORT___);
// Module
___CSS_LOADER_EXPORT___.push([module.id, `.navbar {
    position: sticky;
    top: 0;
    z-index: 10;
  }`, "",{"version":3,"sources":["webpack://./src/components/Nav.css"],"names":[],"mappings":"AAAA;IACI,gBAAgB;IAChB,MAAM;IACN,WAAW;EACb","sourcesContent":[".navbar {\n    position: sticky;\n    top: 0;\n    z-index: 10;\n  }"],"sourceRoot":""}]);
// Exports
___CSS_LOADER_EXPORT___.locals = {};
export default ___CSS_LOADER_EXPORT___;

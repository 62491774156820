import React from "react";

const About = () => {
  return (
    <div id="Recommended Topics" className="section">
      <h2>
        <i>About Me</i>
      </h2>
      <p>
        I am Nafisat Yusuf, a statistician with extensive experience in academic
        research and consulting projects. A graduate of Statistics from Kano
        University of Science and Technology, Wudil, I was one of the brightest
        female student in the department of Statistics in my time, and I am also a member
        of the Nigerian Statistical Association and have contributed to girls'
        child development in northern Nigeria. My most recent work as an author
        is on the performance of “MCD” and “MRCD” at different thresholds in the
        detection of outliers using the R statistical package.
      </p>{" "}
      <p>
        I am popularly known as Mrs. Statistics, and I love the challenge of
        finding patterns in data and uncovering new insights. I get to work on a
        variety of interesting projects, such as cyberstalking, cyberbullying,
        and human behavior. Likewise, I am very open to collaborating with
        talented people to make a positive impact in the world.
      </p>{" "}
      <p>
        Not only that, but I am also a statistical detective. Solving puzzles
        and finding patterns in data. I have been using my skills to help
        businesses make better decisions since my undergraduate studies. I am
        also a passionate educator, and I love teaching others about statistics.
        Not only that, but I believe that everyone should have access to the
        power of data analysis.
      </p>
      <h2>
        <i>The beauty behind Statistics</i>
      </h2>
      <p>
        Statistics represents the captivating realm of deciphering insights from
        data, truly a remarkable pursuit. At its core, it ushers in the thrill
        of discovery, enabling us to forge new frontiers of understanding. I
        aspire to instill this perspective within you – unveiling statistics as
        a pivotal key capable of unveiling hidden treasures within your data.
      </p>
      <p>
        Wholeheartedly, I concur! When you possess curiosity, a foundation in
        statistics, and access to data, the possibilities stretch endlessly
        before you. A diverse array of fascinating domains (my website) is at
        your fingertips, awaiting your exploration.
      </p>
      <p>
        That juncture where the complexities of reality converge with the quest
        for structured, accurate information is incredibly exhilarating. This
        juncture melds the grand aspirations of researchers with the gritty
        reality of the world we inhabit. It's an interaction that I've
        thoroughly discussed with another statistician, and I'm committed to
        delving into further. It's where the practical application truly takes
        flight.
      </p>
      <p>
        Statistics serve as the modern-day data-engineering instruments for
        scientific explorers. These tools empower you to navigate tons of data,
        paving the way for your discoveries.
      </p>
      <h2>
        <i>Statistics as the Science of Empiricals</i>
      </h2>
      <p>
        In our evolving world, where data is taking center stage, drawing
        accurate conclusions hinges on proper data analysis. Surprisingly, even
        a slight misstep can result in significant consequences. As you
        encounter statistical analyses conducted by others, a question arises:
        can you place your trust in their findings, or are ulterior motives at
        play?
      </p>
      <p>
        Statistical analyses can also lead astray. I intend to impart the
        correct utilization of these tools, enabling you to derive accurate
        conclusions while exercising caution towards potentially misleading
        ones.
      </p>
      <p>
        Your appreciation for these tools will grow when faced with big data on
        a spreadsheet, tasked with extracting their significance for the broader
        audience.
      </p>
    </div>
  );
};

export default About;

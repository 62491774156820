import React from "react";
import "./Population.css";
const Probabilty = () => {
  return (
    <div>
      <div className="table-of-contents">
        <h2>Table of Contents</h2>
        <ul>
          <li>
            <a href="#What Is a Probability Distribution?">
              What Is a Probability Distribution?
            </a>
          </li>
          <li>
            <a href="#How Probability Distributions Work">
              How Probability Distributions Work
            </a>
          </li>
          <li>
            <a href="#Types of Probability Distributions">
              Types of Probability Distributions
            </a>
          </li>
          <li>
            <a href="#Uses in Investing">Uses in Investing</a>
          </li>
          <li>
            <a href="#Examples of Probabilty">Examples of Probabilty</a>
          </li>
        </ul>
      </div>
      <p>
        <i>
          <b>Backend from</b>: investopedia.com
        </i>
      </p>
      <div id="What Is a Probability Distribution?" className="section">
        <h1>Probability Distribution Explained: Types and Uses in Investing</h1>
        <h2>What Is a Probability Distribution?</h2>
        <p>
          A probability distribution is a statistical function that describes
          all the possible values and likelihoods that a random variable can
          take within a given range. This range will be bounded between the
          minimum and maximum possible values, but precisely where the possible
          value is likely to be plotted on the probability distribution depends
          on a number of factors. These factors include the distribution's mean
          (average), standard deviation, skewness, and kurtosis.{" "}
        </p>
        <p>
          There are several <a href="/sampling">sampling</a> techniques used by
          researchers and statisticians, each with its own benefits and
          drawbacks.{" "}
        </p>

        <p>
          {" "}
          <h5>
            <i>key Points;</i>
          </h5>
          <ul>
            <li>
              A probability distribution depicts the expected outcomes of
              possible values for a given data-generating process.{" "}
            </li>

            <li>
              Probability distributions come in many shapes with different
              characteristics, as defined by the mean, standard deviation,
              skewness, and kurtosis.
            </li>
            <li>
              Investors use probability distributions to anticipate returns on
              assets such as stocks over time and to hedge their risk.
            </li>
          </ul>
        </p>
      </div>
      <div id="How Probability Distributions Work" className="section">
        <h2>How Probability Distributions Work</h2>
        <p>
          Perhaps the most common probability distribution is the normal
          distribution, or "bell curve," although several distributions exist
          that are commonly used. Typically, the data-generating process of some
          phenomenon will dictate its probability distribution. This process is
          called the probability density function.{" "}
        </p>
        <p>
          Probability distributions can also be used to create cumulative
          distribution functions (CDFs), which add up the probability of
          occurrences cumulatively and will always start at zero and end at
          100%.{" "}
        </p>
        <p>
          Academics, financial analysts, and fund managers alike may determine a
          particular stock's probability distribution to evaluate the possible
          expected returns that the stock may yield in the future.{" "}
        </p>{" "}
        <p>
          The stock's history of returns, which can be measured from any time
          interval, will likely be composed of only a fraction of the stock's
          returns, which will subject the analysis to sampling error. By
          increasing the sample size, this error can be dramatically reduced.
        </p>
      </div>
      <div id="Types of Probability Distributions" className="section">
        <h2>Types of Probability Distributions</h2>
        <p>
          There are many different classifications of probability distributions.
          Some of them include the normal distribution:
        </p>
        <ul>
          <li>
            <b>chi-square distribution</b>
          </li>
          <li>
            <b>binomial distribution</b>
          </li>
          <li>
            <b>Poisson distribution</b>
          </li>
          <li>
            <b>Normal distribution</b>
          </li>
          <li>
            <b>Exponential distribution</b>
          </li>
        </ul>
        <p>
          The different probability distributions serve different purposes and
          represent different data generation processes.
        </p>
        <p>
          {" "}
          <h3>Binomial Distribution</h3>
          The binomial distribution, for example, evaluates the probability of
          an event occurring several times over a given number of trials and
          given the event's probability in each trial. It may be generated, for
          example, by keeping track of how many free throws a basketball player
          makes in a game, where 1 = a basket and 0 = a miss.
        </p>{" "}
        <p>
          Another typical example would be to use a fair coin and figure out the
          probability of that coin coming up heads in 10 straight flips. A
          binomial distribution is discrete, as opposed to continuous, since
          only 1 or 0 is a valid response.{" "}
        </p>{" "}
        <p>
          {" "}
          <h3>Normal Distribution</h3>
          The most commonly used distribution is the normal distribution, which
          is used frequently in finance, investing, science, and engineering.
          The normal distribution is fully characterized by its mean and
          standard deviation, meaning the distribution is not skewed and does
          exhibit kurtosis.
        </p>{" "}
        <p>
          This makes the distribution symmetric and it is depicted as a
          bell-shaped curve when plotted. A normal distribution is defined by a
          mean (average) of zero and a standard deviation of 1.0, with a skew of
          zero and kurtosis = 3.{" "}
        </p>{" "}
        <p>
          In a normal distribution, approximately 68% of the data collected will
          fall within +/- one standard deviation of the mean; approximately 95%
          within +/- two standard deviations; and 99.7% within three standard
          deviations. Unlike the binomial distribution, the normal distribution
          is continuous, meaning that all possible values are represented (as
          opposed to just 0 and 1 with nothing in between).{" "}
        </p>
      </div>
      <div id="Types of Probability Distributions" className="section">
        <h2>Probability Distributions Used in Investing</h2>
        <p>
          Stock returns are often assumed to be normally distributed but in
          reality, they exhibit kurtosis with large negative and positive
          returns seeming to occur more than would be predicted by a normal
          distribution.
        </p>{" "}
        <p>
          In fact, because stock prices are bounded by zero but offer a
          potentially unlimited upside, the distribution of stock returns has
          been described as log-normal. This shows up on a plot of stock returns
          with the tails of the distribution having a greater thickness.{" "}
        </p>{" "}
        <p>
          Probability distributions are often used in risk management as well to
          evaluate the probability and amount of losses that an investment
          portfolio would incur based on a distribution of historical returns.{" "}
        </p>{" "}
        <p>
          One popular risk management metric used in investing is value-at-risk
          (VaR). VaR yields the minimum loss that can occur given a probability
          and time frame for a portfolio. Alternatively, an investor can get a
          probability of loss for an amount of loss and time frame using VaR.
          Misuse and overreliance on VaR have been implicated as one of the
          major causes of the 2008 financial crisis.{" "}
        </p>{" "}
      </div>
      <div id="Examples of Probabilty" className="section">
        <h2>Examples of Probability Distribution</h2>
        <p>
          As a simple example of a probability distribution, let us look at the
          number observed when rolling two standard six-sided dice. Each die has
          a 1/6 probability of rolling any single number, one through six, but
          the sum of two dice will form the probability distribution depicted in
          the image below. Seven is the most common outcome (1+6, 6+1, 5+2, 2+5,
          3+4, 4+3). Two and twelve, on the other hand, are far less likely (1+1
          and 6+6).
        </p>{" "}
       {" "}
      </div>
    </div>
  );
};

export default Probabilty;

import {useRef, useEffect, useState} from 'react';


const Nationalbs = () => {

const [delay, setDelay] = useState(5);
  const interval = useRef();

  useEffect(() => {
    interval.current = setInterval(() => {
      setDelay(prev => prev - 1);
    }, 1000);

    return () => clearInterval(interval.current);
  }, []);

  useEffect(() => {
    if (delay === 0) {
      clearInterval(interval.current);
      window.open('https://www.nigerianstat.gov.ng/', '_blank', 'noopener,noreferrer');
    }
  }, [delay]);

  return <div>The National Bureau of Statistics page will open in {delay}</div>;
};

export default Nationalbs
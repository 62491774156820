import React from "react";
import "./Population.css"
const Mean = () => {
  return (
    <div>
      <div className="table-of-contents">
        <h2>Table of Contents</h2>
        <ul>
          <li>
            <a href="#What Is a Mean?">What Is a Mean?</a>
          </li>
          <li>
            <a href="#Understanding Mean">Understanding Mean</a>
          </li>
          <li>
            <a
              href="#
Arithmetic vs. Geometric Mean"
            >
              Arithmetic vs. Geometric Mean
            </a>
          </li>
          <li>
            <a href="#Examples of Samples">Examples of Samples</a>
          </li>
        </ul>
      </div>
      <p>
        <i>
          <b>Backend from</b>: investopedia.com
        </i>
      </p>
      <div id="What Is a Mean?" className="section">
        <h1>What Is a Mean? Definition in Math and Formula for Calculation</h1>
        <h2>What Is a Mean?</h2>
        <p>
          <p>
            Mean is the simple mathematical average of a set of two or more
            numbers.
          </p>
          The mean for a given set of numbers can be computed in more than one
          way, including the arithmetic mean method, which uses the sum of the
          numbers in the series, and the geometric mean method, which is the
          average of a set of products. However, all the primary methods of
          computing a simple average produce the same approximate result most of
          the time.{" "}
        </p>

        <p>
          {" "}
          <h5>
            <i>key Points;</i>
          </h5>
          <ul>
            <li>
              The mean is the mathematical average of a set of two or more
              numbers.{" "}
            </li>

            <li>
              The arithmetic mean and the geometric mean are two types of mean
              that can be calculated.
            </li>
            <li>
              The formula for calculating the arithmetic mean is to add up the
              numbers in a set and divide by the total quantity of numbers in
              the set.
            </li>
            <li>
              The formula for calculating the geometric mean is to multiply all
              the values in a data set, then take the root of the sum equal to
              the quantity of values within that data set.{" "}
            </li>
            <li>
              A mean helps you to assess a set of numbers by telling you the
              average, helping to contextualize each data point.{" "}
            </li>
          </ul>
        </p>
      </div>
      <div id="Understanding Mean" className="section">
        <h2>Understanding Mean</h2>
        <p>
          The mean is a statistical indicator that can be used to gauge
          performance over time. Specific to investing, the mean is used to
          understand the performance of a company’s stock price over a period of
          days, months, or years{" "}
        </p>
        <p>
          An analyst who wants to measure the trajectory of a company’s stock
          value in, say, the last 10 days would sum up the closing price of the
          stock in each of the 10 days. The sum total would then be divided by
          the number of days to get the arithmetic mean. The geometric mean will
          be calculated by multiplying all of the values together. The nth root
          of the product total is then taken—in this case, the 10th root—to get
          the mean.{" "}
        </p>
      </div>
      <div id="Arithmetic vs. Geometric Mean" className="section">
        <h2>Formulas for Arithmetic Mean and Geometric Mean</h2>
        <p>
          Calculations for both the arithmetic and geometric means are fairly
          similar. The calculated amount for one will not substantially vary
          from another. However, there are subtle differences between the two
          approaches that do lead to different numbers.
        </p>
        <p>
          {" "}
          <h3>Arithmetic Mean</h3>
          The formula for calculating the arithmetic mean is to add up all
          figures and divide by the quantity of figures used. For example, the
          arithmetic mean of the numbers 4 and 9 is found by adding 4 and 9
          together, then dividing by 2 (the quantity of numbers we are using).
          The arithmetic mean in this example is 6.5
        </p>{" "}
        <p>
          <h3>Pros</h3>
          <ul>
            <li>It is easier to calculate.</li>
            <li>It is simpler for following along and audit results.</li>
            <li>Its calculated value is a finite number.</li>
            <li>It has more widespread use in algebraic computations.</li>
            <li>It is often the fastest type of mean to calculate.</li>
          </ul>
          <h3>Cons</h3>
          <ul>
            <li>
              It is highly affected by material outliers or extreme numbers
              outside of a data set.
            </li>
            <li>It is not as useful for skewed distributions.</li>
            <li>
              It is not useful when using time series data (or other series of
              data with varying basis).
            </li>
            <li>
              It weighs every item equally, diminishing the importance of more
              impactful data points.
            </li>
          </ul>
        </p>{" "}
        <p>
          {" "}
          <h3>Geometric Mean</h3>
          The geometric mean is more complicated and uses a more complex
          formula. To get the formula for calculating the geometric mean is to
          multiply all values within a data set. Then, take the root of the sum
          equal to the quantity of values within that data set. For example, to
          calculate the geometric of the values 4 and 9, multiply the two
          numbers together to get 36. Then, take the square root (since there
          are two values). The geometric mean in this example is 6.
        </p>{" "}
        <p>
          <h3>Pros</h3>
          <ul>
            <li>It is less likely to be impacted by extreme outliers.</li>
            <li>It returns a more accurate measurement for more volatile data sets.</li>
            <li>It considers the effects of compounding.</li>
            <li>It is more accurate when using a data set over a long period of time (due to compounding).</li>
            
          </ul>
          <h3>Cons</h3>
          <ul>
            <li>
            It can’t be used if any value within the data set is 0 or negative.
            </li>
            <li>Its formula is more complex and not easily used.</li>
            <li>
            Its calculation is not transparent and more difficult to audit.
            </li>
            <li>
            It is less prevalent and not used as much as other methods.
            </li>
          </ul>
        </p>{" "}
        </div>
    </div>
  );
};

export default Mean;

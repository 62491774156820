import React from "react";

const Typeii = () => {
  return (
    <div>
      <div className="table-of-contents">
        <h2>Table of Contents</h2>
        <ul>
          <li>
            <a href="#What Is a Type I Error?">What Is a Type I Error?</a>
          </li>
          <li>
            <a href="#How It Works">How It Works</a>
          </li>
          <li>
            <a href="#Type I vs. Type II Error">Type I vs. Type II Error</a>
          </li>
          <li>
            <a href="#Examples">Examples</a>
          </li>
          {/* <li>
                <a href="#Understanding Statistical Data">
                  Understanding Statistical Data
                </a>
              </li>
              <li>
                <a href="#Levels of Measurement">Levels of Measurement</a>
              </li>
              <li>
                <a href="#Sampling Techniques">Sampling Techniques</a>
              </li> */}
          {/* <li>
                <a href="#Uses of Statistics">Uses of Statistics</a>
              </li> */}
        </ul>
      </div>
      <p>
        <i>
          <b>Backend from</b>: investopedia.com
        </i>
      </p>
      <div id="What Is a Type I Error?" className="section">
        <h1>Type II Error Explained, Example II & vs. Type I Error</h1>
        <h2>What Is a Type I Error?</h2>
        <p>
          A type II error is a statistical term used within the context of
          hypothesis testing that describes the error that occurs when one fails
          to reject a null hypothesis that is actually false. A type II error
          produces a false negative, also known as an error of omission. For
          example, a test for a disease may report a negative result when the
          patient is infected. This is a type II error because we accept the
          conclusion of the test as negative, even though it is incorrect.{" "}
        </p>{" "}
        <p>
          A Type II error can be contrasted with a type I error is the rejection
          of a true null hypothesis, whereas a type II error describes the error
          that occurs when one fails to reject a null hypothesis that is
          actually false. The error rejects the alternative hypothesis, even
          though it does not occur due to chance.
        </p>
        <p>
          {" "}
          <h5>
            <i>key Points;</i>
          </h5>
          <ul>
            <li>
              A type II error is defined as the probability of incorrectly
              failing to reject the null hypothesis, when in fact it is not
              applicable to the entire population.{" "}
            </li>

            <li>A type II error is essentially a false negative.</li>
            <li>
              A type II error can be reduced by making more stringent criteria
              for rejecting a null hypothesis, although this increases the
              chances of a false positive.
            </li>
            <li>
              The sample size, the true population size, and the pre-set alpha
              level influence the magnitude of risk of an error.{" "}
            </li>
            <li>
              Analysts need to weigh the likelihood and impact of type II errors
              with type I errors.
            </li>
          </ul>
        </p>
      </div>
      <div id="How It Works" className="section">
        <h2>How a Type I Error Works</h2>
        <p>
          A type II error, also known as an error of the second kind or a beta
          error, confirms an idea that should have been rejected, such as, for
          instance, claiming that two observances are the same, despite them
          being different. A type II error does not reject the null hypothesis,
          even though the alternative hypothesis is the true state of nature. In
          other words, a false finding is accepted as true.{" "}
        </p>
        <p>
          A type II error can be reduced by making more stringent criteria for
          rejecting a null hypothesis (H0). For example, if an analyst is
          considering anything that falls within the +/- bounds of a 95%
          confidence interval as statistically insignificant (a negative
          result), then by decreasing that tolerance to +/- 90%, and
          subsequently narrowing the bounds, you will get fewer negative
          results, and thus reduce the chances of a false negative.{" "}
        </p>
        <p>
          {" "}
          Taking these steps, however, tends to increase the chances of
          encountering a type I error—a false-positive result. When conducting a
          hypothesis test, the probability or risk of making a type I error or
          type II error should be considered.
        </p>
      </div>
      <div id="Type I vs. Type II Error" className="section">
        <h2>Type I Errors vs. Type II Errors</h2>
        <p>
          The difference between a type II error and a type I error is that a
          type I error rejects the null hypothesis when it is true (i.e., a
          false positive). The probability of committing a type I error is equal
          to the level of significance that was set for the hypothesis test.
          Therefore, if the level of significance is 0.05, there is a 5% chance
          a type I error may occur.{" "}
        </p>
        <p>
          The probability of committing a type II error is equal to one minus
          the power of the test, also known as beta. The power of the test could
          be increased by increasing the sample size, which decreases the risk
          of committing a type II error.
        </p>
      </div>
      <div id="Examples" className="section">
        <h2>Examples of Type I Errors</h2>
        <p>
          Assume a biotechnology company wants to compare how effective two of
          its drugs are for treating diabetes. The null hypothesis states the
          two medications are equally effective. A null hypothesis, H0, is the
          claim that the company hopes to reject using the one-tailed test. The
          alternative hypothesis, Ha, states the two drugs are not equally
          effective. The alternative hypothesis, Ha, is the state of nature that
          is supported by rejecting the null hypothesis.
        </p>{" "}
        <p>
          The biotech company implements a large clinical trial of 3,000
          patients with diabetes to compare the treatments. The company randomly
          divides the 3,000 patients into two equally sized groups, giving one
          group one of the treatments and the other group the other treatment.
          It selects a significance level of 0.05, which indicates it is willing
          to accept a 5% chance it may reject the null hypothesis when it is
          true or a 5% chance of committing a type I error.
        </p>{" "}
        <p>
          Assume the beta is calculated to be 0.025, or 2.5%. Therefore, the
          probability of committing a type II error is 97.5%. If the two
          medications are not equal, the null hypothesis should be rejected.
          However, if the biotech company does not reject the null hypothesis
          when the drugs are not equally effective, a type II error occurs.
        </p>{" "}
        <h3>What Is the Difference Between Type I and Type II Errors?</h3>
        <p>
          A type I error occurs if a null hypothesis is rejected that is
          actually true in the population. This type of error is representative
          of a false positive. Alternatively, a type II error occurs if a null
          hypothesis is not rejected that is actually false in the population.
          This type of error is representative of a false negative.
        </p>
      </div>
    </div>
  );
};

export default Typeii;

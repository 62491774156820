import React from 'react'
import { Link } from 'react-router-dom';
import { MDBTable, MDBTableHead, MDBTableBody } from 'mdb-react-ui-kit';

const Normaldist = () => {
  return (
    <div>
      <h3> Noramal Distribution </h3>
      <div>
      <Link to="/downloads">download Normal table</Link>
      </div>
      
      <p>B(x;n,p) = SUM b(y;n,p)</p>
    <MDBTable>
      <MDBTableHead>
        <tr>
          <th scope='col'>z</th>
          <th scope='col'>0.00</th>
          <th scope='col'>0.01</th>
          <th scope='col'>0.02</th>
          <th scope='col'>0.03</th>
          <th scope='col'>0.04</th>
          <th scope='col'>0.05</th>
          <th scope='col'>0.06</th>
          <th scope='col'>0.07</th>
          <th scope='col'>0.08</th>
          <th scope='col'>0.09</th>
        </tr>
      </MDBTableHead>
      <MDBTableBody>
        <tr>
          <th scope='row'>0.0</th>
          
          <td>0.5000</td>
          <td>0.5040</td>
          <td>0.5080</td>
          <td>0.5120</td>
          <td>0.5160</td>
          <td>0.5199</td>
          <td>0.5239</td>
          <td>0.5279</td>
          <td>0.5319</td>
          <td>0.5359</td>
        </tr>

        <tr className='table-primary'>
          <th scope='row'>0.1</th>
         
          <td>0.5398</td>
          <td>0.5438</td>
          <td>0.5478</td>
          <td>0.5517</td>
          <td>0.5557</td>
          <td>0.5596</td>
          <td>0.5636</td>
          <td>0.5675</td>
          <td>0.5714</td>
          <td>0.5753</td>
        </tr>
        <tr className='table-secondary'>
          <th scope='row'>0.2</th>
          
          <td>0.5793</td>
          <td>0.5832</td>
          <td>0.5871</td>
          <td>0.5910</td>
          <td>0.5948</td>
          <td>0.5987</td>
          <td>0.6026</td>
          <td>0.6064</td>
          <td>0.6103</td>
          <td>0.6141</td>
        </tr>
        <tr className='table-success'>
          <th scope='row'>0.3</th>
         
          <td>0.6179</td>
          <td>0.6217</td>
          <td>0.6255</td>
          <td>0.6293</td>
          <td>0.6331</td>
          <td>0.6268</td>
          <td>0.6406</td>
          <td>0.6443</td>
          <td>0.6480</td>
          <td>0.6517</td>
        </tr>
        <tr className='table-danger'>
          <th scope='row'>0.4</th>
       
          <td>0.6554</td>
          <td>0.6591</td>
          <td>0.6628</td>
          <td>0.6664</td>
          <td>0.6700</td>
          <td>0.6736</td>
          <td>0.6772</td>
          <td>0.6808</td>
          <td>0.6844</td>
          <td>0.6879</td>
        </tr>
        <tr className='table-secondary'>
          <th scope='row'>0.5</th>
          
          <td>0.6915</td>
          <td>0.6950</td>
          <td>0.6985</td>
          <td>0.7019</td>
          <td>0.7054</td>
          <td>0.7088</td>
          <td>0.7123</td>
          <td>0.7157</td>
          <td>0.7190</td>
          <td>0.7224</td>
        </tr>


        <tr>
          <th scope='row'>0.6</th>
          
          <td>0.7257</td>
          <td>0.7291</td>
          <td>0.7324</td>
          <td>0.7357</td>
          <td>0.7389</td>
          <td>0.7422</td>
          <td>0.7454</td>
          <td>0.7486</td>
          <td>0.7517</td>
          <td>0.7549</td>
        </tr>

        <tr className='table-primary'>
          <th scope='row'>0.7</th>
        
          <td>0.7580</td>
          <td>0.7611</td>
          <td>0.7642</td>
          <td>0.7673</td>
          <td>0.7704</td>
          <td>0.7734</td>
          <td>0.7764</td>
          <td>0.7794</td>
          <td>0.7823</td>
          <td>0.7852</td>
        </tr>
        <tr className='table-secondary'>
          <th scope='row'>0.8</th>
         
          <td>0.7881</td>
          <td>0.7910</td>
          <td>0.7939</td>
          <td>0.7967</td>
          <td>0.7995</td>
          <td>0.8023</td>
          <td>0.8051</td>
          <td>0.8078</td>
          <td>0.8106</td>
          <td>0.8133</td>
        </tr>
        <tr className='table-success'>
          <th scope='row'>0.9</th>
          
          <td>0.8159</td>
          <td>0.8186</td>
          <td>0.8212</td>
          <td>0.8238</td>
          <td>0.8264</td>
          <td>0.8289</td>
          <td>0.8315</td>
          <td>0.8340</td>
          <td>0.8365</td>
          <td>0.8389</td>
        </tr>
        <tr className='table-danger'>
          <th scope='row'>1.0</th>
         
          <td>0.8413</td>
          <td>0.8438</td>
          <td>0.8461</td>
          <td>0.8485</td>
          <td>0.8508</td>
          <td>0.8531</td>
          <td>0.8554</td>
          <td>0.8577</td>
          <td>0.8599</td>
          <td>0.8621</td>
          
        </tr>
        <tr className='table-success'>
          <th scope='row'>1.1</th>
          
          <td>0.8643</td>
          <td>0.8665</td>
          <td>0.8686</td>
          <td>0.8708</td>
          <td>0.8729</td>
          <td>0.8749</td>
          <td>0.8770</td>
          <td>0.8790</td>
          <td>0.8810</td>
          <td>0.8830</td>
        </tr>

        <tr className='table-primary'>
          <th scope='row'>1.2</th>
        
          <td>0.8849</td>
          <td>0.8869</td>
          <td>0.8888</td>
          <td>0.8907</td>
          <td>0.8925</td>
          <td>0.8944</td>
          <td>0.8962</td>
          <td>0.8980</td>
          <td>0.8997</td>
          <td>0.9015</td>
        </tr>
        <tr className='table-secondary'>
          <th scope='row'>1.3</th>
          
          <td>0.9032</td>
          <td>0.9049</td>
          <td>0.9066</td>
          <td>0.9082</td>
          <td>0.9099</td>
          <td>0.9115</td>
          <td>0.9131</td>
          <td>0.9147</td>
          <td>0.9162</td>
          <td>0.9177</td>
        </tr>
        <tr className='table-success'>
          <th scope='row'>1.4</th>
          
          <td>0.9192</td>
          <td>0.9207</td>
          <td>0.9222</td>
          <td>0.9236</td>
          <td>0.9251</td>
          <td>0.9265</td>
          <td>0.9279</td>
          <td>0.9292</td>
          <td>0.9306</td>
          <td>0.9319</td>
        </tr>
        <tr className='table-primary'>
          <th scope='row'>1.5</th>
         
          <td>0.9332</td>
          <td>0.9345</td>
          <td>0.9357</td>
          <td>0.9370</td>
          <td>0.9382</td>
          <td>0.9394</td>
          <td>0.9406</td>
          <td>0.9418</td>
          <td>0.9429</td>
          <td>0.9441</td>
        </tr>
        <tr className='table-secondary'>
          <th scope='row'>1.6</th>
         
          <td>0.9452</td>
          <td>0.9463</td>
          <td>0.9474</td>
          <td>0.9484</td>
          <td>0.9495</td>
          <td>0.9505</td>
          <td>0.9515</td>
          <td>0.9525</td>
          <td>0.9535</td>
          <td>0.9545</td>
        </tr>


        <tr>
          <th scope='row'>1.7</th>
         
          <td>0.9554</td>
          <td>0.9564</td>
          <td>0.9573</td>
          <td>0.9582</td>
          <td>0.9591</td>
          <td>0.9599</td>
          <td>0.9608</td>
          <td>0.9616</td>
          <td>0.9625</td>
          <td>0.9633</td>
        </tr>

        <tr className='table-primary'>
          <th scope='row'>1.8</th>
          
          <td>0.9641</td>
          <td>0.9649</td>
          <td>0.9656</td>
          <td>0.9664</td>
          <td>0.9671</td>
          <td>0.9678</td>
          <td>0.9686</td>
          <td>0.9693</td>
          <td>0.9699</td>
          <td>0.9706</td>
        </tr>
        <tr className='table-secondary'>
          <th scope='row'>1.9</th>
          
          <td>0.9713</td>
          <td>0.9719</td>
          <td>0.9726</td>
          <td>0.9732</td>
          <td>0.9738</td>
          <td>0.9744</td>
          <td>0.9750</td>
          <td>0.9756</td>
          <td>0.9761</td>
          <td>0.9767</td>
        </tr>
        <tr className='table-success'>
          <th scope='row'>2.0</th>
         
          <td>0.9772</td>
          <td>0.9778</td>
          <td>0.9783</td>
          <td>0.9788</td>
          <td>0.9793</td>
          <td>0.9798</td>
          <td>0.9803</td>
          <td>0.9808</td>
          <td>0.9812</td>
          <td>0.9817</td>
        </tr>
        <tr className='table-danger'>
          <th scope='row'>2.1</th>
          
          <td>0.9821</td>
          <td>0.9826</td>
          <td>0.9830</td>
          <td>0.9834</td>
          <td>0.9838</td>
          <td>0.9842</td>
          <td>0.9846</td>
          <td>0.9850</td>
          <td>0.9854</td>
          <td>0.9857</td>
        </tr>
        <tr className='table-secondary'>
          <th scope='row'>2.2</th>
        
          <td>0.9861</td>
          <td>0.9864</td>
          <td>0.9868</td>
          <td>0.9871</td>
          <td>0.9875</td>
          <td>0.9878</td>
          <td>0.9881</td>
          <td>0.9884</td>
          <td>0.9887</td>
          <td>0.9890</td>
        </tr>
        <tr className='table-success'>
          <th scope='row'>2.3</th>
         
          <td>0.9893</td>
          <td>0.9896</td>
          <td>0.9898</td>
          <td>0.9901</td>
          <td>0.9904</td>
          <td>0.9906</td>
          <td>0.9909</td>
          <td>0.9911</td>
          <td>0.9913</td>
          <td>0.9916</td>
        </tr>

        <tr className='table-primary'>
          <th scope='row'>2.4</th>
          
          <td>0.9918</td>
          <td>0.9920</td>
          <td>0.9922</td>
          <td>0.9925</td>
          <td>0.9927</td>
          <td>0.9929</td>
          <td>0.9931</td>
          <td>0.9932</td>
          <td>0.9934</td>
          <td>0.9936</td>
        </tr>
        <tr className='table-secondary'>
          <th scope='row'>2.5</th>
         
          <td>0.9938</td>
          <td>0.9940</td>
          <td>0.9941</td>
          <td>0.9943</td>
          <td>0.9945</td>
          <td>0.9946</td>
          <td>0.9948</td>
          <td>0.9949</td>
          <td>0.9951</td>
          <td>0.9952</td>
        </tr>
        <tr className='table-success'>
          <th scope='row'>2.6</th>
         
          <td>0.9953</td>
          <td>0.9955</td>
          <td>0.9956</td>
          <td>0.9957</td>
          <td>0.9959</td>
          <td>0.9960</td>
          <td>0.9961</td>
          <td>0.9962</td>
          <td>0.9963</td>
          <td>0.9964</td>
        </tr>
        <tr className='table-danger'>
          <th scope='row'>2.7</th>
          
          <td>0.9965</td>
          <td>0.9966</td>
          <td>0.9967</td>
          <td>0.9968</td>
          <td>0.9969</td>
          <td>0.9970</td>
          <td>0.9971</td>
          <td>0.9972</td>
          <td>0.9973</td>
          <td>0.9975</td>
          
        </tr>
        <tr className='table-secondary'>
          <th scope='row'>2.8</th>
          
          <td>0.9974</td>
          <td>0.9975</td>
          <td>0.9976</td>
          <td>0.9977</td>
          <td>0.9977</td>
          <td>0.9978</td>
          <td>0.9979</td>
          <td>0.9979</td>
          <td>0.9980</td>
          <td>0.9981</td>
        </tr>
        <tr className='table-success'>
          <th scope='row'>2.9</th>
         
          <td>0.9981</td>
          <td>0.9982</td>
          <td>0.9982</td>
          <td>0.9983</td>
          <td>0.9984</td>
          <td>0.9984</td>
          <td>0.9985</td>
          <td>0.9985</td>
          <td>0.9986</td>
          <td>0.9986</td>
        </tr>

        <tr className='table-primary'>
          <th scope='row'>3.0</th>
         
          <td>0.9987</td>
          <td>0.9987</td>
          <td>0.9987</td>
          <td>0.9988</td>
          <td>0.9988</td>
          <td>0.9989</td>
          <td>0.9989</td>
          <td>0.9989</td>
          <td>0.9990</td>
          <td>0.9990</td>
        </tr>
        <tr className='table-secondary'>
          <th scope='row'>3.1</th>
         
          <td>0.9990</td>
          <td>0.9991</td>
          <td>0.9991</td>
          <td>0.9991</td>
          <td>0.9992</td>
          <td>0.9992</td>
          <td>0.9992</td>
          <td>0.9992</td>
          <td>0.9993</td>
          <td>0.9993</td>
        </tr>
        <tr className='table-success'>
          <th scope='row'>3.2</th>
          
          <td>0.9993</td>
          <td>0.9993</td>
          <td>0.9994</td>
          <td>0.9994</td>
          <td>0.9994</td>
          <td>0.9994</td>
          <td>0.9994</td>
          <td>0.9995</td>
          <td>0.9995</td>
          <td>0.9995</td>
        </tr>
        <tr className='table-primary'>
          <th scope='row'>3.3</th>
         
          <td>0.9995</td>
          <td>0.9995</td>
          <td>0.9995</td>
          <td>0.9996</td>
          <td>0.9996</td>
          <td>0.9996</td>
          <td>0.9996</td>
          <td>0.9996</td>
          <td>0.9996</td>
          <td>0.9997</td>
        </tr>

        <tr className='table-success'>
          <th scope='row'>3.4</th>
          
          <td>0.9997</td>
          <td>0.9997</td>
          <td>0.9997</td>
          <td>0.9997</td>
          <td>0.9997</td>
          <td>0.9997</td>
          <td>0.9997</td>
          <td>0.9997</td>
          <td>0.9997</td>
          <td>0.9998</td>
        </tr>
      </MDBTableBody>
    </MDBTable>
  </div>  
  )
}

export default Normaldist
import React from "react";

const Mode = () => {
  return (
    <div>
      <div className="table-of-contents">
        <h2>Table of Contents</h2>
        <ul>
          <li>
            <a href="#What Is the Mode?">What Is the Mode?</a>
          </li>
          <li>
            <a href="#Understanding the Mode">Understanding the Mode</a>
          </li>
          <li>
            <a
              href="#
        Examples of the Mode"
            >
              Examples of the Mode
            </a>
          </li>
          <li>
            <a href="#Mode vs. Mean vs. Median">Mode vs. Mean vs. Median</a>
          </li>
          <li>
            <a href="#Pros and Cons of Mode">Pros and Cons of Mode</a>
          </li>
        </ul>
      </div>
      <p>
        <i>
          <b>Backend from</b>: investopedia.com
        </i>
      </p>
      <div id="What Is the Mode?" className="section">
        <h1>Mode: What It Is in Statistics and How to Calculate It</h1>
        <h2>What Is the Mode?</h2>

        <p>
          The mode is the value that appears most frequently in a data set. A
          set of data may have one mode, more than one mode, or no mode at all.
          Other popular measures of central tendency include the mean, or the
          average of a set, and the median, the middle value in a set.
        </p>

        <p>
          {" "}
          <h5>
            <i>key Points;</i>
          </h5>
          <ul>
            <li>
              In statistics, the mode is the most commonly observed value in a
              set of data.{" "}
            </li>

            <li>
              For the normal distribution, the mode is also the same value as
              the mean and median.
            </li>
            <li>
              In many cases, the modal value will differ from the average value
              in the data.
            </li>
          </ul>
        </p>
      </div>
      <div id="Understanding the Mode" className="section">
        <h2>Understanding the Mode</h2>
        <p>
          In statistics, data can be distributed in various ways. The most often
          cited distribution is the classic normal (bell-curve) distribution. In
          this, and some other distributions, the mean (average) value falls at
          the midpoint, which is also the peak frequency of observed values.{" "}
        </p>
        <p>
          For such a distribution, the mean, median, and mode are all the same
          values. This means that this value is the average value, the middle
          value, and also the mode—the most frequently occurring value in the
          data.
        </p>
        <p>
          Mode is most useful as a measure of central tendency when examining
          categorical data, such as models of cars or flavors of soda, for which
          a mathematical average median value based on ordering can not be
          calculated.
        </p>
      </div>
      <div id="Examples of the Mode" className="section">
        <h2>Examples of the Mode</h2>
        <p>
          For example, in the following list of numbers, 16 is the mode since it
          appears more times in the set than any other number:
        </p>
        <ul>
          <li>3, 3, 6, 9, 16, 16, 16, 27, 27, 37, 48</li>
        </ul>
        <p>
          {" "}
          A set of numbers can have more than one mode (this is known as bimodal
          if there are two modes) if there are multiple numbers that occur with
          equal frequency, and more times than the others in the set.
        </p>{" "}
        <ul>
          <li>3, 3, 3, 9, 16, 16, 16, 27, 37, 48</li>
        </ul>
        <p>
          In the above example, both the number 3 and the number 16 are modes as
          they each occur three times and no other number occurs more often.
        </p>
        <p>
          If no number in a set of numbers occurs more than once, that set has
          no mode:
        </p>
        <ul>
          <li>3, 6, 9, 16, 27, 37, 48</li>
        </ul>
        <p>
          A set of numbers with two modes is bimodal, a set of numbers with
          three modes is trimodal, and any set of numbers with more than one
          mode is multimodal.
        </p>
      </div>
      <div id="Mode vs. Mean vs. Median" className="section">
        <h2>Mode vs. Mean vs. Median</h2>
        <p>
          Mean, median, and mode are all different ways of noting the center of
          a data set.{" "}
        </p>
        <p>
          Mode is the most common set of numbers, while mean is the average and
          median is the midpoint.
        </p>
        <h3>Mean</h3>
        <p>
          Mean is the average of a set of numbers. To calculate the mean, begin
          by adding up all of the data points and dividing by the total number
          of data points. For example, suppose you have the following series of
          numbers:
        </p>
        <ul>
          <li>3, 3, 6, 9, 16, 16, 16, 27, 27, 37, 48</li>
        </ul>
        <p>
          Added together, you get 208. Divide 208 by 11 (the number of data
          points) to get the mean, which is 18.9.
        </p>
        <h3>Mean</h3>
        <p>
          Mean is the average of a set of numbers. To calculate the mean, begin
          by adding up all of the data points and dividing by the total number
          of data points. For example, suppose you have the following series of
          numbers:
        </p>
        <ul>
          <li>3, 3, 6, 9, 16, 16, 16, 27, 27, 37, 48</li>
        </ul>
        <p>
          Added together, you get 208. Divide 208 by 11 (the number of data
          points) to get the mean, which is 18.9.
        </p>
        <h3>Median</h3>
        <p>
          The median is the data point in the middle of a set. To find the
          median, the numbers in the set must be arranged from smallest to
          largest. Let's use the numbers in the example above:
        </p>
        <ul>
          <li>3, 3, 6, 9, 16, 16, 16, 27, 27, 37, 48</li>
        </ul>
        <p>
          The median is 16, the data point in the exact middle of the set. This
          set has an odd number of data points, which makes it easier to find
          the middle. For a set with an even number of data points, you'd take
          the mean of the two middle numbers to find the median.
        </p>
      </div>
      <div id="Pros and Cons of Mode" className="section">
      <h2>Advantages and Disadvantages of Mode</h2>
      <p>
          <h3>Pros</h3>
          <ul>
            <li>The mode is easy to understand and calculate.</li>
            <li>The mode is not affected by extreme values.</li>
            <li>The mode is easy to identify in a data set and in a discrete frequency distribution.</li>
            <li>The mode is useful for qualitative data.</li>
            <li>The mode can be computed in an open-ended frequency table.</li>
            <li>The mode can be located graphically.</li>
          </ul>
          <h3>Cons</h3>
          <ul>
            <li>
            The mode is not defined when there are no repeats in a data set.
            </li>
            <li>The mode is not based on all values.</li>
            <li>
            The mode is unstable when the data consist of a small number of values.
            </li>
            <li>
            Sometimes the data has one mode, more than one mode, or no mode at all.
            </li>
          </ul>
        </p>
      </div>
    </div>
  );
};

export default Mode;

import Container from "react-bootstrap/Container";
import Nav from "react-bootstrap/Nav";
import Navbar from "react-bootstrap/Navbar";
import NavDropdown from "react-bootstrap/NavDropdown";
import "./Nav.css";

function Navigator() {
  window.onscroll = function () {
    let navbar = document.querySelector(".navbar");
    if (window.pageYOffset > 0) {
      navbar.classList.add("sticky");
    } else {
      navbar.classList.remove("sticky");
    }
  };
  return (
    <Navbar bg="light" expand="lg">
      <Container fluid>
        <Navbar.Brand href="/">FEENSTATIS</Navbar.Brand>
        <Navbar.Toggle aria-controls="navbarScroll" />
        <Navbar.Collapse id="navbarScroll">
          <Nav
            className="me-auto my-2 my-lg-0"
            style={{ maxHeight: "100px" }}
            navbarScroll
          >
            <Nav.Link href="/about">About-Me</Nav.Link>
            <Nav.Link href="/contactus">Contact</Nav.Link>
            <NavDropdown title="Resources" id="navbarScrollingDropdown">
              <NavDropdown.Item href="/basic-statistics">
                Basic Statistics
              </NavDropdown.Item>
              <NavDropdown.Divider />
              <NavDropdown.Item href="/category/statistics">
                Statistics
              </NavDropdown.Item>
              <NavDropdown.Divider />
              <NavDropdown.Item href="/probabilitydistribution">
                Probability Distribution
              </NavDropdown.Item>
              <NavDropdown.Divider />
              <NavDropdown.Item href="/bestfits">
              Line of Best Fit
              </NavDropdown.Item>
              <NavDropdown.Divider />
              <NavDropdown.Item href="/statistics/stochastic">
                Stochastic Process
              </NavDropdown.Item>
              <NavDropdown.Divider />
              <NavDropdown.Item href="/statistics/null-hypothesis">
                Null Hypothesis
              </NavDropdown.Item>
              <NavDropdown.Item href="/statistics/alternative-hypothesis">
                Alternative Hypothesis
              </NavDropdown.Item>
              <NavDropdown.Divider />
              <NavDropdown.Item href="/statistics/errori&ii">
                Type I & II Error
              </NavDropdown.Item>
            </NavDropdown>
            <Nav.Link href="/category/publication">Publications</Nav.Link>
            <Nav.Link href="/data">Data</Nav.Link>
            <NavDropdown title="Projects" id="navbarScrollingDropdown">
              <NavDropdown.Item href="/works_comm_development">
                Actual Work & Community Services
              </NavDropdown.Item>
              <NavDropdown.Item href="/awards&certs">Awards & Certifications</NavDropdown.Item>
              {/* <NavDropdown.Divider /> */}
              {/* <NavDropdown.Item href="#action5">
                Comperative Studies
              </NavDropdown.Item> */}
            </NavDropdown>
            <Nav.Link href="#" disabled>
              News
            </Nav.Link>
            <Nav.Link href="/tables">Statis-Table</Nav.Link>
          </Nav>
          {/* <Form className="d-flex">
            <Form.Control
              type="search"
              placeholder="Search"
              className="me-2"
              aria-label="Search"
            />
            <Button variant="outline-success">Search</Button>
          </Form> */}
        </Navbar.Collapse>
      </Container>
    </Navbar>
  );
}

export default Navigator;

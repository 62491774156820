import React from "react";
import { Row, Col } from "react-bootstrap";
import categories from "./categories";
import { LinkContainer } from "react-router-bootstrap";
import awards from "../src/pages/notes/statistics/img/awards.jpg"
import awards2 from "../src/pages/notes/statistics/img/awards.jpg"
import "./Home.css";

const Home = () => { 
  return (
    <div>
      {/* <img
        alt=""
         src="https://slidemodel.com/wp-content/uploads/FF0115-01-free-teamwork-infographic-template-16x9-3.jpg"
        className="home-banner"
      /> */}
      <div className="featured-products-container mt-4">
        <h2>Usa Energy Infographic</h2>
        {/** last product from backend */}
      </div>
       {/* sale banner */}
      <div className="sale__banner--container mt-4">
        <img
          alt=""
          src="https://cdn.statcdn.com/CMS/GettingStartedCMS/3a41c483c55a83b6e228d00410a3dced.png"
        />
        <img alt="awards" src={awards} />
      </div>
      <div className="recent-products-container mt-4">
        <h2>Tutorials</h2>
        <Row>
          {categories.map((category) => (
            <LinkContainer
              to={`/category/${category.name.toLocaleLowerCase()}`}
            >
              <Col md={4}>
                <div
                  className="category-tile"
                  style={{
                    backgroundImage: `linear-gradient(rgba(0, 0, 0, 0.5), rgba(0, 0, 0, 0.5)), url(${category.img})`,
                    gap: "10px",
                  }}
                >
                  {category.name}
                </div>
              </Col>
            </LinkContainer>
          ))}
        </Row>
      </div>
    </div>
  );
};

export default Home;

import React from 'react'
import { Row, Col } from 'react-bootstrap'
import datas from '../Data'
import {LinkContainer} from 'react-router-bootstrap'

const Datum = () => {
  
  

  return (
    <div>
        <div className="recent-products-container mt-4">
          <h2> Opensource Data</h2>
          <Row>
            {datas.map((category) => (
              <LinkContainer to={`/${category.name.toLocaleLowerCase()}`}>
                <Col md={4}>
                  <div className='category-tile' style={{backgroundImage: `linear-gradient(rgba(0, 0, 0, 0.5), rgba(0, 0, 0, 0.5)), url(${category.img})`, gap: "10px"}}>
                      {category.name}
                  </div>
                </Col>

              </LinkContainer>
            ))}

          </Row>
          
        </div>
    </div>
  )
}

export default Datum
import React from "react";
import "./Population.css";
import desk from "./img/desk.png"

const Samples = () => {
  return (
    <div>
      <div className="table-of-contents">
        <h2>Table of Contents</h2>
        <ul>
          <li>
            <a href="#What Is a Sample?">What Is a Sample?</a>
          </li>
          <li>
            <a href="#Understanding Samples">Understanding Samples</a>
          </li>
          <li>
            <a href="#Types of Sampling">Types of Sampling</a>
          </li>
          <li>
            <a href="#Examples of Samples">Examples of Samples</a>
          </li>
        </ul>
      </div>
      <p>
        <i>
          <b>Backend from</b>: investopedia.com
        </i>
      </p>
      <div id="What Is a Sample?" className="section">
        <h1>Sample: What It Means in Statistics, Types, and Examples</h1>
        <h2>What Is a Sample?</h2>
        <p>
          A sample refers to a smaller, manageable version of a larger group. It
          is a subset containing the characteristics of a larger{" "}
          <a href="/statistics/population">population</a>. Samples are used in
          statistical testing when population sizes are too large for the test
          to include all possible members or observations. A sample should
          represent the population as a whole and not reflect any bias toward a
          specific attribute. There are several sampling techniques used by
          researchers and statisticians, each with its own benefits and
          drawbacks.{" "}
        </p>
        <p>
          There are several <a href="/sampling">sampling</a> techniques used by
          researchers and statisticians, each with its own benefits and
          drawbacks.{" "}
        </p>

        <p>
          {" "}
          <h5>
            <i>key Points;</i>
          </h5>
          <ul>
            <li>
              In statistics, a sample is an analytic subset of a larger
              population.{" "}
            </li>

            <li>
              The use of samples allows researchers to conduct their studies
              with more manageable data and in a timely manner.
            </li>
            <li>
              Randomly drawn samples do not have much bias if they are large
              enough, but achieving such a sample may be expensive and
              time-consuming.
            </li>
            <li>
              In simple random sampling, every entity in the population is
              identical, while stratified random sampling divides the overall
              population into smaller groups.{" "}
            </li>
          </ul>
        </p>
      </div>
      <div id="Understanding Samples" className="section">
        <h2>Understanding Samples</h2>
        <p>
          A sample is an unbiased number of observations taken from a
          population. In simple terms, a population is the total number of
          observations (i.e., individuals, animals, items, data, etc.) contained
          in a given group or context. A sample, in other words, is a portion,
          part, or fraction of the whole group, and acts as a subset of the
          population.1 Samples are used in a variety of settings where research
          is conducted. Scientists, marketers, government agencies, economists,
          and research groups are among those who use samples for their studies
          and measurements.{" "}
        </p>
        <p>
          Using whole populations for research comes with challenges.
          Researchers may have problems gaining ready access to entire
          populations. And, because of the nature of some studies, researchers
          may have difficulties getting the results they need in a timely
          fashion. This is why people samples are used. Using a smaller number
          of people who represent the entire population can still produce valid
          results while reducing time and resources.{" "}
        </p>
        <p>
          Samples used by researchers must resemble the broader population in
          order to make accurate inferences or predictions. All the participants
          in the sample should share the same characteristics and qualities.1
          So, if the study is about male college freshmen, the sample should be
          a small percentage of males that fit this description. Similarly, if a
          research group conducts a study on the sleep patterns of single women
          over 50, the sample should only include women within this demographic.{" "}
        </p>
        <p>
          {" "}
          <h3>
            <i>Special Considerations</i>
          </h3>{" "}
          Consider a team of academic researchers who want to know how many
          students studied for less than 40 hours for the CFA exam and still
          passed. Since more than 200,000 people take the exam globally each
          year, reaching out to each and every exam participant would burn time
          and resources.
        </p>
        <p>
          {" "}
          In fact, by the time the data from the population has been collected
          and analyzed, a couple of years would have passed, making the analysis
          worthless since a new population would have emerged. What the
          researchers can do instead is take a sample of the population and get
          data from this sample.
        </p>
        <p>
          {" "}
          In order to achieve an unbiased sample, the selection has to be random
          so everyone from the population has an equal and likely chance of
          being added to the sample group. This is similar to a lottery draw and
          is the basis for simple random sampling.
        </p>
      </div>
      <div id="Types of Sampling" className="section">
        <h2>Types of Sampling</h2>
        <ul>
          <li>
            <b>Simple Random Sampling</b>
          </li>
          <li>
            <b>Stratified Random Sampling</b>
          </li>
        </ul>
        <p>
          {" "}
          <h3>Simple Random Sampling</h3>
          Simple random sampling is ideal if every entity in the population is
          identical. If the researchers don’t care whether their sample subjects
          are all male or all female or a combination of both sexes in some
          form, simple random sampling may be a good selection technique.
        </p>{" "}
        <p>
          Let's say there were 200,000 test-takers who sat for the CFA exam in
          2021, out of which 40% were women and 60% were men. The random sample
          drawn from the population should, therefore, have 400 women and 600
          men for a total of 1,000 test-takers.{" "}
        </p>{" "}
        <p>
          But what about cases where knowing the ratio of men to women that
          passed a test after studying for less than 40 hours is important?
          Here, a stratified random sample would be preferable to a simple
          random sample.{" "}
        </p>
        <p>
          {" "}
          <h3>Stratified Random Sampling</h3>
          This type of sampling, also referred to as proportional random
          sampling or quota random sampling, divides the overall population into
          smaller groups. These are known as strata. People within the strata
          share similar characteristics.
        </p>{" "}
        <p>
          What if age was an important factor that researchers would like to
          include in their data? Using the stratified random sampling technique,
          they could create layers or strata for each age group. The selection
          from each stratum would have to be random so that everyone in the
          bracket has a likely chance of being included in the sample. For
          example, two participants, Alex and David, are 22 and 24 years old,
          respectively. The sample selection cannot pick one over the other
          based on some preferential mechanism. They both should have an equal
          chance of being selected from their age group. The strata could look
          something like this:{" "}
        </p>{" "}
        {/* image example here */}
        <img className="img_img"
          alt="desk"
          src={desk}
        />
        <p>
          From the table, the population has been divided into age groups. For
          example, 30,000 people within the age range of 20 to 24 years old took
          the CFA exam in 2021. Using this same proportion, the sample group
          will have (30,000 ÷ 200,000) × 1,000 = 150 test-takers that fall
          within this group. Alex or David—or both or neither—may be included
          among the 150 random exam participants of the sample.{" "}
        </p>{" "}
        <p>
          There are many more strata that could be compiled when deciding on a
          sample size. Some researchers might populate the job functions,
          countries, marital status, etc., of the test-takers when deciding how
          to create the sample.{" "}
        </p>{" "}
      </div>
      <div id="Examples of Samples" className="section">
        <h2>Examples of Samples</h2>
        <p>
          In 2021, the population of the world was nearly 7.9 billion, out of
          which 49.6% were female and 50% were male.4 The World Bank Group.
          "Population, Female (% of Total Population)." 56 The total number of
          people in any given country can also be a population size. The total
          number of students in a city can be taken as a population, and the
          total number of dogs in a city is also a population size. Samples can
          be taken from these populations for research purposes.
        </p>{" "}
        <p>
          Following our CFA exam example, the researchers could take a sample of
          1,000 CFA participants from the total 200,000 test-takers—the
          population—and run the required data on this number. The mean of this
          sample would be taken to estimate the average of CFA exam takers that
          passed even though they only studied for less than 40 hours
        </p>{" "}
        <p>
          The sample group taken should not be biased. This means that if the
          sample mean of the 1,000 CFA exam participants is 50, the population
          mean of the 200,000 test-takers should also be approximately 50.
        </p>
        <p>
          As an illustration, assume that the population being studied is all of
          the zeroes depicted in the image below. The red circles form a sample
          of the population of all circles on the page.
        </p>{" "}
      </div>
    </div>
  );
};

export default Samples;

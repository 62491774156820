// Imports
import ___CSS_LOADER_API_SOURCEMAP_IMPORT___ from "../node_modules/css-loader/dist/runtime/sourceMaps.js";
import ___CSS_LOADER_API_IMPORT___ from "../node_modules/css-loader/dist/runtime/api.js";
var ___CSS_LOADER_EXPORT___ = ___CSS_LOADER_API_IMPORT___(___CSS_LOADER_API_SOURCEMAP_IMPORT___);
// Module
___CSS_LOADER_EXPORT___.push([module.id, `.category-tile {
    height: 250px;
    background-size: cover;
    background-position: center;
    padding-left: 20px !important;
    padding-right: 20px !important;
    display: flex;
    justify-content: center;
    align-items: center;
    color: white;
    font-size: 30px;
    cursor: pointer;
    transition: all 0.3s;
    margin-top: 30px;
}

.card {
    border: none !important;
    width: 15rem !important;
}
img.card-img-top.product-preview-img {
    border-radius: 10px;
}

.category-tile:hover {
    transform: scale(1.05);
}

.sale__banner--container img {
    max-width: 1200px;
    display: block;
    margin: 0 auto;
}`, "",{"version":3,"sources":["webpack://./src/Home.css"],"names":[],"mappings":"AAAA;IACI,aAAa;IACb,sBAAsB;IACtB,2BAA2B;IAC3B,6BAA6B;IAC7B,8BAA8B;IAC9B,aAAa;IACb,uBAAuB;IACvB,mBAAmB;IACnB,YAAY;IACZ,eAAe;IACf,eAAe;IACf,oBAAoB;IACpB,gBAAgB;AACpB;;AAEA;IACI,uBAAuB;IACvB,uBAAuB;AAC3B;AACA;IACI,mBAAmB;AACvB;;AAEA;IACI,sBAAsB;AAC1B;;AAEA;IACI,iBAAiB;IACjB,cAAc;IACd,cAAc;AAClB","sourcesContent":[".category-tile {\n    height: 250px;\n    background-size: cover;\n    background-position: center;\n    padding-left: 20px !important;\n    padding-right: 20px !important;\n    display: flex;\n    justify-content: center;\n    align-items: center;\n    color: white;\n    font-size: 30px;\n    cursor: pointer;\n    transition: all 0.3s;\n    margin-top: 30px;\n}\n\n.card {\n    border: none !important;\n    width: 15rem !important;\n}\nimg.card-img-top.product-preview-img {\n    border-radius: 10px;\n}\n\n.category-tile:hover {\n    transform: scale(1.05);\n}\n\n.sale__banner--container img {\n    max-width: 1200px;\n    display: block;\n    margin: 0 auto;\n}"],"sourceRoot":""}]);
// Exports
___CSS_LOADER_EXPORT___.locals = {};
export default ___CSS_LOADER_EXPORT___;

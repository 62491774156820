import React from 'react'

const ErrorType = () => {
  return (
    <div id="Recommended Topics" className="section">
    <h2>Recommended Tutorials</h2>
    <p>
      This article has been a guide on what are Basics Statistics & its
      applications. Here, we explain it in detail with its types, applications,
      and examples. find some usefull tutorial here –
    </p>
    <p>
      <h2><a href="/statistics/errori&ii/typei">Type I Error</a></h2>
    </p>
    <p>
        <h2><a href="/statistics/errori&ii/typeii">Type II Error</a></h2>
    </p>
  </div>
  )
}

export default ErrorType
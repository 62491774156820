const work = [
  {
    name: "Girls_Child_Advocate",
    img: "https://reliefweb.int/sites/default/files/styles/large/public/previews/19/62/19623c6d-2071-3954-94dd-27937a8eadb2.png",
  },
  {
    name: "Role_of_Leader",
    img: "https://www.xavier.edu/student-involvement/leadership/images/leadership-.jpg",
  },
  {
    name: "Leadership_in_Africa",
    img: "https://cdn.w600.comps.canstockphoto.com/leadership-signpost-shows-vision-values-clipart_csp9530421.jpg",
  },
  {
    name: "Statistical_Guide_to_Leadership",
    img: "https://financesonline.com/uploads/2021/03/Leadership-Statistics-Image-1.jpg",
  },
];
export default work;

import React from 'react'
import {useRef, useEffect, useState} from 'react';


const Internationalmonetary = () => {
const [delay, setDelay] = useState(5);
    const interval = useRef();
  
    useEffect(() => {
      interval.current = setInterval(() => {
        setDelay(prev => prev - 1);
      }, 1000);
  
      return () => clearInterval(interval.current);
    }, []);
  
    useEffect(() => {
      if (delay === 0) {
        clearInterval(interval.current);
        window.open('https://www.imf.org/en/Data', '_blank', 'noopener,noreferrer');
      }
    }, [delay]);
  
    return <div>The imf data page will open in {delay}</div>;
  };

export default Internationalmonetary
import React from "react";
import "./Population.css";
const Outliers = () => {
  return (
    <div>
      <div className="table-of-contents">
        <h2>Table of Contents</h2>
        <ul>
          <li>
            <a href="#What Is an Outliers?">What Is an Outliers?</a>
          </li>
          <li>
            <a href="#How to Identify an Outlier">How to Identify an Outlier</a>
          </li>
          <li>
            <a
              href="#
    How to Find the Upper and Lower Quartiles in an Odd Dataset"
            >
              How to Find the Upper and Lower Quartiles in an Odd Dataset
            </a>
          </li>
          <li>
            <a href="#How to calculate">How to calculate</a>
          </li>
          <a
            href="#
                  How to find an outlier in an odd dataset"
          >
            How to find an outlier in an odd dataset
          </a>
          <li>
            <a href="#How to Find the Upper and Lower Quartiles in an Even Dataset">
              How to Find the Upper and Lower Quartiles in an Even Dataset
            </a>
          </li>
          <li>
            <a href="#How to calculate">How to calculate</a>
          </li>
          <li>
            <a href="#How to find an outlier in an even dataset">
              How to find an outlier in an even dataset
            </a>
          </li>
        </ul>
      </div>
      <p>
        <i>
          <b>Backend from</b>: www.freecodecamp.org
        </i>
      </p>
      <div id="What Is an Outliers?" className="section">
        <h1>
          What is an Outlier? Definition and How to Find Outliers in Statistics
        </h1>
        <h2>What Is an Outliers?</h2>
        <p>
          <p>
            In simple terms, an outlier is an extremely high or extremely low
            data point relative to the nearest data point and the rest of the
            neighboring co-existing values in a data graph or dataset you're
            working with.
          </p>
          Outliers are extreme values that stand out greatly from the overall
          pattern of values in a dataset or graph.{" "}
        </p>
        <p>Below, on the far left of the graph, there is an outlier.</p>
        <p>
          The value in the month of January is significantly less than in the
          other months.
        </p>
        <p>
          {/* image example here */}
          <img
            className="img_img"
            alt="outliers"
            src="https://www.freecodecamp.org/news/content/images/2021/08/Screenshot-2021-08-24-at-3.07.05-PM.jpeg"
          />
        </p>{" "}
        {/* <p>
          {" "}
          <h5>
            <i>key Points;</i>
          </h5>
          <ul>
            <li>
              The mean is the mathematical average of a set of two or more
              numbers.{" "}
            </li>

            <li>
              The arithmetic mean and the geometric mean are two types of mean
              that can be calculated.
            </li>
            <li>
              The formula for calculating the arithmetic mean is to add up the
              numbers in a set and divide by the total quantity of numbers in
              the set.
            </li>
            <li>
              The formula for calculating the geometric mean is to multiply all
              the values in a data set, then take the root of the sum equal to
              the quantity of values within that data set.{" "}
            </li>
            <li>
              A mean helps you to assess a set of numbers by telling you the
              average, helping to contextualize each data point.{" "}
            </li>
          </ul>
        </p> */}
      </div>
      <div id="How to Identify an Outlier" className="section">
        <h2>How to Identify an Outlier</h2>
        <p>
          An outlier has to satisfy either of the following two conditions:{" "}
        </p>
        <p>outlier less-than Q1 - 1.5(IQR) </p>
        <p>outlier greater-than Q3 + 1.5(IQR)</p>{" "}
        <p>
          The rule for a low outlier is that a data point in a dataset has to be
          less than Q1 - 1.5xIQR known as threshold.
        </p>{" "}
        <p>
          This means that a data point needs to fall more than 1.5 times the
          Interquartile range below the first quartile to be considered a low
          outlier.
        </p>{" "}
        <p>
          The rule for a high outlier is that if any data point in a dataset is
          more than Q3 - 1.5xIQR, it's a high outlier know as threshold.
        </p>{" "}
        <p>
          More specifically, the data point needs to fall more than 1.5 times
          the Interquartile range above the third quartile to be considered a
          high outlier
        </p>{" "}
        <p>
          As you can see, there are certain individual values you need to
          calculate first in a dataset, such as the IQR. But to find the IQR,
          you need to find the so called first and third quartiles which are Q1
          and Q3 respectively.
        </p>{" "}
        <p>
          So, let's see what each of those does and break down how to find their
          values in both an odd and an even dataset.
        </p>
      </div>
      <div
        id="How to Find the Upper and Lower Quartiles in an Odd Dataset"
        className="section"
      >
        <h2>How to Find the Upper and Lower Quartiles in an Odd Dataset</h2>
        <p>To get started, let's say that you have this dataset:</p>
        <p> 25,14,6,5,5,30,11,11,13,4,2</p>{" "}
        <p>
          The first step is to sort the values in ascending numerical order,from
          smallest to largest number.
        </p>{" "}
        <p> 2,4,5,5,6,11,11,13,14,25,30</p>{" "}
        <p>The lowest value (MIN) is 2 and the highest (MAX) is 30.</p>{" "}
      </div>
      <div id="How to calculate" className="section">
        <h2>How to calculate Q2 in an odd dataset</h2>
        <p>The next step is to find the median or quartile 2 (Q2).</p>{" "}
        <p>
          This particular set of data has an odd number of values, with a total
          of 11 scores all together.
        </p>{" "}
        <p>
          To find the median in a dataset means that you're finding the middle
          value – the single middle number in the set.
        </p>{" "}
        <p>In odd datasets, there in only one middle number.</p>{" "}
        <p>
          Since there are 11 values in total, an easy way to do this is to split
          the set in two equal parts with each side containing 5 values.
        </p>{" "}
        <p>
          The median value will have 5 values on one side and 5 values on the
          other.
        </p>{" "}
        <p>(2,4,5,5,6), 11 ,(11,13,14,25,30)</p>{" "}
        <p>
          The median is 11 as it is the number that separates the first half
          from the second half.
        </p>{" "}
        <p>An alternative way to double check if you're right is to do this:</p>{" "}
        <p>(total_number_of_scores + 1) / 2.</p>{" "}
        <p>
          This is (11 + 1) /2 = 6, which means you want the number in the 6th
          place of this set of data – which is 11.
        </p>{" "}
        <p>
          <b>So Q2 = 11.</b>
        </p>{" "}
        <h3>How to calculate Q1 in an odd dataset</h3>
        <p>
          Next, to find the lower quartile, Q1, we need to find the median of
          the first half of the dataset, which is on the left hand side.
        </p>{" "}
        <p>
          As a reminder, the initial dataset is:{" "}
          <b>(2,4,5,5,6), 11 ,(11,13,14,25,30)</b>
        </p>
        <p>
          The first half of the dataset, or the lower half, does not include the
          median:
        </p>{" "}
        <p>2,4,5,5,6</p>{" "}
        <p>
          This time, there is again an odd set of scores – specifically there
          are 5 values.
        </p>{" "}
        <p>
          You want to again split this half set into another half, with an equal
          number of two values on each side. You'll get a unique number, which
          will be the number in the middle of the 5 values.
        </p>{" "}
        <p>
          Pick the middle value that stands out:
          <b>(2,4),5,(5,6) </b>
          <i>In this case it's Q1 = 5.</i>{" "}
        </p>
        <p>
          <b>11,13,14,25,30</b>
        </p>{" "}
        <p>
          You split this half of the odd set of numbers into another half to
          find the median and subsequently the value of Q3.
        </p>
        <p>
          You again want the number in the 3rd place like you did for the first
          half.
        </p>{" "}
        <p>
          (11,13),14,(25,30)
          <b> So Q3 = 14.</b>
        </p>{" "}
        <h3>How to calculate IQR in an odd dataset</h3>
        <p>
          Now, the next step is to calculate the IQR which stands for
          Interquartile Range.
        </p>{" "}
        <p>
          This is the difference/distance between the lower quartile (Q1) and
          the upper quartile (Q3) you calculated above.
        </p>{" "}
        <p>As a reminder, the formula to do so is the following:</p>{" "}
        <p>
          <b>IQR = Q3 - Q1</b>
        </p>
        <p>To find the IQR of the dataset from above:</p>{" "}
        <p>
          <i>IQR= 14 - 5</i>
        </p>
        <p>
          <b>IQR = 9</b>
        </p>
      </div>
      <div id="How to find an outlier in an odd dataset" className="section">
        <h2>How to find an outlier in an odd dataset</h2>
        <p>To recap so far, the dataset is the one below:</p>{" "}
        <p>2,4,5,5,6,11,11,13,14,25,30</p>{" "}
        <p>and so far, you have calucalted the five number summary:</p>{" "}
        <p>
          <ul>
            <li>
              <b>MIN = 2</b>
            </li>
            <li>
              <b>Q1 = 5</b>
            </li>
            <li>
              <b>MED = 11</b>
            </li>
            <li>
              <b>Q3 = 14</b>
            </li>
            <li>
              <b>MAX = 30</b>
            </li>
          </ul>
        </p>{" "}
        <p>Finally, let's find out if there are any outliers in the dataset.</p>{" "}
        <p>
          To see if there is a lowest value outlier, you need to calculate the
          first part and see if there is a number in the set that satisfies the
          condition.
        </p>{" "}
        <p>
          <ul>
            <li>
              <b>Outlier less-than Q1 - 1.5(IQR)=</b>
            </li>
            <li>
              <b>Outlier less-than 5 - 1.5(9)</b>
            </li>
            <li>
              <b>Outlier less-than 5 - 13.5</b>
            </li>
            <li>
              <b>outlier less-than - 8.5</b>
            </li>
          </ul>
        </p>{" "}
        <p>
          There are no lower outliers, since there isn't a number less than -8.5
          in the dataset.
        </p>{" "}
        <p>Next, to see if there are any higher outliers:</p>{" "}
        <p>
          <ul>
            <li>
              <b>Outlier greater-than Q1 - 1.5(IQR)=</b>
            </li>
            <li>
              <b>Outlier greater-than 14 - 1.5(9)</b>
            </li>
            <li>
              <b>Outlier greater-than 14 - 13.5</b>
            </li>
            <li>
              <b>outlier greater-than 27.5</b>
            </li>
          </ul>
        </p>{" "}
        <p>
          And there is a number in the dataset that is more than <b>27.5</b>:
        </p>
        <p>2,4,5,5,6,11,11,13,14,25,30</p>{" "}
        <p>
          <b>In this case, 30 is the outlier in the existing dataset.</b>
        </p>
      </div>
      <div
        id="How to Find the Upper and Lower Quartiles in an Even Dataset"
        className="section"
      >
        <h2>How to Find the Upper and Lower Quartiles in an Odd Dataset</h2>
        <p>
          What happens when you have a dataset that consists of an even set of
          data?
        </p>{" "}
        <p>
          There isn't just one stand-out median (Q2), nor is there a standout
          upper quartile (Q1) or standout lower quartile (Q3).
        </p>{" "}
        <p>
          So the process of calculating quartiles and then finding an outlier is
          a bit different.
        </p>
      </div>
      <div id="How to calculate" className="section">
        <h2>How to calculate Q2 in an even dataset</h2>
        <p>Say that you have this dataset with 8 numbers:</p>{" "}
        <p>10,15,20,26,28,30,35,40</p>{" "}
        <p>
          This time, the numbers are already sorted from lowest to highest
          value.
        </p>{" "}
        <p>
          To find the median number in an even dataset, you need to find the
          value that would be in between the two numbers that are in the middle.
          You add them together and divide them by 2, like so:
        </p>{" "}
        <p>10,15,20,26,28,30,35,40</p>{" "}
        <p>
          <b>26 + 28 = 54</b>
        </p>{" "}
        <p>
          <b>54 / 2 = 27</b>
        </p>
        <h3>How to calculate Q1 in an even dataset</h3>
        <p>
          To calculate to upper and lower quartiles in an even dataset, you keep
          all the numbers in the dataset (as opposed to in the odd set you
          removed the median).
        </p>{" "}
        <p>This time, the dataset is cut in half.</p>{" "}
        <p>10,15,20,26 | 28,30,35,40</p>{" "}
        <p>
          To find Q1, you split the first half of the dataset into another half
          which leaves you with a remaining even set:
        </p>{" "}
        <p>10,15 | 20,26</p>{" "}
        <p>
          To find the median of this half, you take the two numbers in the
          middle and divide them by two:
        </p>{" "}
        <p>
          <ul>
            <li>
              <b>Q1 = (15 + 20)/2</b>
            </li>
            <li>
              <b>Q1 = 35 / 2</b>
            </li>
            <li>
              <b>Q1 = 17.5</b>
            </li>
          </ul>
        </p>{" "}
        <h3>How to calculate Q3 in an even dataset</h3>
        <p>
          To find Q3, you need to focus on the second half of the dataset and
          split that half into another half:
        </p>
        <p>28,30,35,40 greater-equal-to 28,30 | 35,40</p>{" "}
        <p>The two numbers in the middle are 30 and 35.</p>{" "}
        <p>You add them and divide them by two, and the result is:</p>
        <p>
          <ul>
            <li>
              <b>Q3 = (30 + 35)/2</b>
            </li>
            <li>
              <b>Q3 = 65 / 2</b>
            </li>
            <li>
              <b>Q3 = 32.5</b>
            </li>
          </ul>
        </p>{" "}
        <h3>How to calculate the IQR in an even dataset</h3>
        <p>
          The formula for calculating IQR is exactly the same as the one we used
          to calculate it for the odd dataset.
        </p>
        <p>
          <ul>
            <li>
              <b>IQR = Q3 - Q1</b>
            </li>
            <li>
              <b>IQR = 32.5 - 17.5</b>
            </li>
            <li>
              <b>IQR = 15</b>
            </li>
          </ul>
        </p>{" "}
      </div>
      <div id="How to find an outlier in an even dataset" className="section">
        <h2>How to Find the Upper and Lower Quartiles in an Odd Dataset</h2>
        <p>As a recap, so far the five number summary is the following:</p>{" "}
        <p>
          <ul>
            <li>
              <b>MIN = 10</b>
            </li>
            <li>
              <b>Q1 = 17.5</b>
            </li>
            <li>
              <b>MED = 27</b>
            </li>
            <li>
              <b>Q3 = 32.5</b>
            </li>
            <li>
              <b>MAX = 40</b>
            </li>
          </ul>{" "}
        </p>
        <p>To calculate any outliers in the dataset:</p>
        <p>outlier less-than Q1 - 1.5(IQR) </p>
        <p>
          <b>or</b>
        </p>
        <p>outlier greater-than Q3 + 1.5(IQR)</p>{" "}
        <p>
          To find any lower outliers, you calcualte Q1 - 1.5(IQR) and see if
          there are any values less than the result.
        </p>
        <p>
          <ul>
            <li>
              <b>Outlier less-than Q1 - 1.5(IQR)=</b>
            </li>
            <li>
              <b>Outlier less-than 17.5 - 1.5(15)</b>
            </li>
            <li>
              <b>Outlier less-than 17.5 - 22.5</b>
            </li>
            <li>
              <b>outlier less-than - 5</b>
            </li>
          </ul>
        </p>{" "}
        <p>There aren't any values in the dataset that are less than -5.</p>{" "}
        <p>
          Finally, to find any higher outliers, you calculate Q3 - 1.5(IQR) and
          see if there are any values in the dataset that are higher than the
          result
        </p>{" "}
        <p>
          <ul>
            <li>
              <b>Outlier greater-than Q1 - 1.5(IQR)=</b>
            </li>
            <li>
              <b>Outlier greater-than 32.5 - 1.5(15)</b>
            </li>
            <li>
              <b>Outlier greater-than 32.5 - 22.5</b>
            </li>
            <li>
              <b>outlier greater-than 55</b>
            </li>
          </ul>
        </p>{" "}
        <p>
          There aren't any values higher than 55 so this dataset doesn't have
          any outliers.
        </p>
      </div>
      <div id="Recommended Topics" className="section">
        <h2>Recommended Tutorials</h2>
        <p>
          This article has been a guide to what is Stochastic Process & its
          meaning. Here, we explain it in detail with its types, applications,
          and examples. You may also find some useful articles here –
        </p>
        <p>
          <h2><a href="/statistics/descriptive">Descriptive Statistics</a></h2>
        </p>
        <p>
            <h2><a href="/category/biostatistics">Biostatistics</a></h2>
        </p>
        <p>
            <h2><a href="/statistics/stochastic">Stochastic</a></h2>
        </p>
      </div>
    </div>
  );
};

export default Outliers;

import React from "react";
import "./Stats.css";

const Statistics = () => {
  return (
    <div>
      <div className="table-of-contents">
        <h2>Table of Contents</h2>
        <ul>
          <li>
            <a href="#What Is Statistics?">What Is Statistics?</a>
          </li>
          <li>
            <a href="#Understanding Statistics">Understanding Statistics</a>
          </li>
          <li>
            <a href="#Descriptive & Inferential Statistics">
              Descriptive & Inferential Statistics
            </a>
          </li>
          <li>
            <a href="#Mean, Median, and Mode">Mean, Median, and Mode</a>
          </li>
          <li>
            <a href="#Understanding Statistical Data">
              Understanding Statistical Data
            </a>
          </li>
          <li>
            <a href="#Levels of Measurement">Levels of Measurement</a>
          </li>
          <li>
            <a href="#Sampling Techniques">Sampling Techniques</a>
          </li>
          {/* <li>
            <a href="#Uses of Statistics">Uses of Statistics</a>
          </li> */}
        </ul>
      </div>
      <p>
        <i>
          <b>Backend from</b>: investopedia.com
        </i>
      </p>
      <div id="What Is Statistics?" className="section">
        <h1>Mathematical Statistics: Definition, Types, and Importance</h1>
        <h2>What Is Statistics?</h2>
        <p>
          Statistics is a branch of applied mathematics that involves the
          collection, description, analysis, and inference of conclusions from
          quantitative data. The mathematical theories behind statistics rely
          heavily on differential and integral calculus, linear algebra, and
          probability theory. People who do statistics are referred to as
          statisticians. They're particularly concerned with determining how to
          draw reliable conclusions about large groups and general events from
          the behavior and other observable characteristics of small samples.
          These small samples represent a portion of the large group or a
          limited number of instances of a general phenomenon.{" "}
        </p>

        <p>
          {" "}
          <h5>
            <i>key Points;</i>
          </h5>
          <ul>
            <li>
              Statistics is the study and manipulation of data, including ways
              to gather, review, analyze, and draw conclusions from data.{" "}
            </li>

            <li>
              The two major areas of statistics are descriptive and inferential
              statistics.
            </li>
            <li>
              Statistics can be communicated at different levels ranging from
              non-numerical descriptor (nominal-level) to numerical in reference
              to a zero-point (ratio-level).
            </li>
            <li>
              Several sampling techniques can be used to compile statistical
              data, including simple random, systematic, stratified, or cluster
              sampling.{" "}
            </li>
            <li>
              Statistics are present in almost every department of every company
              and are an integral part of investing as well.
            </li>
          </ul>
        </p>
      </div>
      <div id="Understanding Statistics" className="section">
        <h2>Understanding Statistics</h2>
        <p>
          Statistics are used in virtually all scientific disciplines, such as
          the physical and social sciences as well as in business, the
          humanities, government, and manufacturing. Statistics is fundamentally
          a branch of applied mathematics that developed from the application of
          mathematical tools, including calculus and linear algebra to
          probability theory.{" "}
        </p>
        <p>
          In practice, statistics is the idea that we can learn about the
          properties of large sets of objects or events{" "}
          <a href="/statistics/population">(a population)</a> by studying the
          characteristics of a smaller number of similar objects or events{" "}
          <a href="/statistics/sample">(a sample)</a> . Gathering comprehensive
          data about an entire population is too costly, difficult, or
          impossible in many cases so statistics start with a sample that can be
          conveniently or affordably observed.{" "}
        </p>
        <p>
          {" "}
          Statisticians measure and gather data about the individuals or
          elements of a sample, then they analyze this data to generate
          descriptive statistics. They can then use these observed
          characteristics of the sample data, which are properly called
          "statistics," to make inferences or educated guesses about the
          unmeasured (or unmeasured) characteristics of the broader population,
          known as the parameters.
        </p>
      </div>
      <div id="Descriptive & Inferential Statistics" className="section">
        <h2>Descriptive & Inferential Statistics</h2>
        <p>
          The two major areas of statistics are known as{" "}
          <a href="/statistics/descriptive">descriptive statistics</a>, which
          describes the properties of sample and population data, and
          inferential statistics, which uses those properties to test hypotheses
          and draw conclusions. Descriptive statistics include mean (average),
          variance, <a href="/statistics/skewness">skewness</a>, and{" "}
          <a href="/statistics/kurtosis">kurtosis</a>. Inferential statistics
          include linear regression analysis, analysis of variance (ANOVA),
          logit/Probit models, and null hypothesis testing.{" "}
        </p>
        <h3>Descriptive Statistics</h3>
        <p>
          Descriptive statistics mostly focus on the central tendency,
          variability, and distribution of sample data. Central tendency means
          the estimate of the characteristics, a typical element of a sample or
          population. It includes descriptive statistics such as{" "}
          <a href="/statistics/mean">mean</a>,{" "}
          <a href="/statistics/median">median</a>, and{" "}
          <a href="/statistics/mode">mode</a>.{" "}
        </p>{" "}
        <p>
          Variability refers to a set of statistics that show how much
          difference there is among the elements of a sample or population along
          the characteristics measured. It includes metrics such as range,
          <a href="/variance"> variance</a>, and{" "}
          <a href="/standard deviation">standard deviation</a>.{" "}
        </p>
        <p>
          {" "}
          The distribution refers to the overall "shape" of the data, which can
          be depicted on a chart such as a histogram or a dot plot, and includes
          properties such as the probability distribution function, skewness,
          and kurtosis. Descriptive statistics can also describe differences
          between observed characteristics of the elements of a data set. They
          can help us understand the collective properties of the elements of a
          data sample and form the basis for testing hypotheses and making
          predictions using inferential statistics.
        </p>
        <h3>Inferential Statistics</h3>
        <p>
          Inferential statistics are tools that statisticians use to draw
          conclusions about the characteristics of a population, drawn from the
          characteristics of a sample, and to determine how certain they can be
          of the reliability of those conclusions. Based on the sample size and
          distribution, statisticians can calculate the probability that
          statistics, which measure the central tendency, variability,
          distribution, and relationships between characteristics within a data
          sample, provide an accurate picture of the corresponding parameters of
          the whole population from which the sample is drawn.
        </p>{" "}
        <p>
          Inferential statistics are used to make generalizations about large
          groups, such as estimating average demand for a product by surveying a
          sample of consumers' buying habits or attempting to predict future
          events. This might mean projecting the future return of a security or
          asset class based on returns in a sample period.
        </p>{" "}
        <p>
          <a href="/Regression">Regression</a> analysis is a widely used
          technique of statistical inference used to determine the strength and
          nature of the relationship (the correlation) between a dependent
          variable and one or more explanatory (independent) variables. The
          output of a regression model is often analyzed for statistical
          significance, which refers to the claim that a result from findings
          generated by testing or experimentation is not likely to have occurred
          randomly or by chance. It's likely to be attributable to a specific
          cause elucidated by the data.
        </p>
      </div>
      <div id="Mean, Median, and Mode" className="section">
        <h2>Mean, Median, and Mode</h2>
        <p>
          The terms mean, median and mode fall under the umbrella of central
          tendency. They describe an element that’s typical in a given sample
          group. You can find the mean descriptor by adding the numbers in the
          group and dividing the result by the number of data set observations.
        </p>{" "}
        <p>
          The middle number in the set is the median. Half of all included
          numbers are higher than the median and half are lesser. The median
          home value in a neighborhood would be $350,000 if five homes were
          located there and valued at $500,000, $400,000, $350,000, $325,000,
          and $300,000. Two values are higher and two are lower.
        </p>{" "}
        <p>
          Mode identifies the number that falls between the highest and lowest
          values. It appears most frequently in the data set.
        </p>
      </div>
      <div id="Understanding Statistical Data" className="section">
        <h2>Understanding Statistical Data</h2>
        <p>
          The root of statistics is driven by variables. A variable is a data
          set that can be counted that marks a characteristic or attribute of an
          item. For example, a car can have variables such as make, model, year,
          mileage, color, or condition. By combining the variables across a set
          of data, such as the colors of all cars in a given parking lot,
          statistics allows us to better understand trends and outcomes.
        </p>{" "}
        <p>
          There are two main types of variables. First, qualitative variables
          are specific attributes that are often non-numeric. Many of the
          examples given in the car example are qualitative. Other examples of
          qualitative variables in statistics are gender, eye color, or city of
          birth. Qualitative data is most often used to determine what
          percentage of an outcome occurs for any given qualitative variable.
          <a href="/Qualitative"> Qualitative analysis</a> often does not rely
          on numbers. For example, trying to determine what percentage of women
          own a business analyzes qualitative data.
        </p>{" "}
        <p>
          The second type of variable in statistics is quantitative variables.
          Quantitative variables are studied numerically and only have weight
          when they're about a non-numerical descriptor. Similar to quantitative
          analysis, this information is rooted in numbers. In the car example
          above, the mileage driven is a quantitative variable, but the number
          60,000 holds no value unless it is understood that is the total number
          of miles driven
        </p>{" "}
        <p>
          Quantitative variables can be further broken into two categories.
          First, discrete variables have limitations in statistics and infer
          that there are gaps between potential discrete variable values. The
          number of points scored in a football game is a discrete variable
          because :
        </p>{" "}
        <ul>
          <li>There can be no decimals, and</li>
          <li> It is impossible for a team to score only one point</li>
        </ul>{" "}
        <p>
          Statistics also makes use of continuous quantitative variables. These
          values run along a scale. Discrete values have limitations, but
          continuous variables are often measured into decimals. Any value
          within possible limits can be obtained when measuring the height of
          the football players, and the heights can be measured down to 1/16th
          of an inch, if not further.
        </p>
      </div>
      <div id="Levels of Measurement" className="section">
        <h2> Statistical Levels of Measurement</h2>
        <p>
          There are several resulting levels of measurement after analyzing
          variables and outcomes. Statistics can quantify outcomes in four ways.{" "}
        </p>{" "}
        <ul>
          <li>
            <b>Nominal-level Measurement</b>
          </li>
          <li>
            <b>Ordinal-level Measurement</b>
          </li>
          <li>
            <b>Interval-level Measurement</b>
          </li>
          <li>
            <b>Ratio-level Measurement</b>
          </li>
        </ul>
        <p>
          {" "}
          <h3>Nominal-level Measurement</h3>
          There's no numerical or quantitative value, and qualities are not
          ranked. Nominal-level measurements are instead simply labels or
          categories assigned to other variables. It's easiest to think of
          nominal-level measurements as non-numerical facts about a variable.
        </p>{" "}
        <p>
          <i>
            <b>Example</b>
          </i>
          : The name of the President elected in 2020 was Joseph Robinette
          Biden, Jr.{" "}
        </p>
        <p>
          {" "}
          <h3>Ordinal-level Measurement</h3>
          Outcomes can be arranged in an order, but all data values have the
          same value or weight. Although numerical, ordinal-level measurements
          can't be subtracted against each other in statistics because only the
          position of the data point matters. Ordinal levels are often
          incorporated into nonparametric statistics and compared against the
          total variable group.
        </p>{" "}
        <p>
          <i>
            <b>Example</b>
          </i>
          : American Fred Kerley was the 2nd fastest man at the 2020 Tokyo
          Olympics based on 100-meter sprint times.{" "}
        </p>
        <p>
          {" "}
          <h3>Interval-level Measurement</h3>
          Outcomes can be arranged in order but differences between data values
          may now have meaning. Two data points are often used to compare the
          passing of time or changing conditions within a data set. There is
          often no "starting point" for the range of data values, and calendar
          dates or temperatures may not have a meaningful intrinsic zero value.
        </p>{" "}
        <p>
          <i>
            <b>Example</b>
          </i>
          : Inflation hit 8.6% in May 2022. The last time inflation was this
          high was in December 1981{" "}
        </p>
        <p>
          {" "}
          <h3>Ratio-level Measurement</h3>
          Outcomes can be arranged in order and differences between data values
          now have meaning. But there's a starting point or "zero value" that
          can be used to further provide value to a statistical value. The ratio
          between data values has meaning, including its distance away from
          zero.
        </p>{" "}
        <p>
          <i>
            <b>Example</b>
          </i>
          : The lowest meteorological temperature recorded was -128.6 degrees
          Fahrenheit in Antarctica{" "}
        </p>
      </div>
      <div id="Sampling Techniques" className="section">
        <h2>Sampling Techniques</h2>
        <p>
          It would often not be possible to gather data from every data point
          within a population to gather statistical information. Statistics
          relies instead on different sampling techniques to create a
          representative subset of the population that's easier to analyze. In
          statistics, there are several primary types of sampling in statistics.
        </p>{" "}
        <ul>
          <li>
            <b>Simple Random Sampling</b>
          </li>
          <li>
            <b>Systemic Sampling</b>
          </li>
          <li>
            <b>Stratified Sampling</b>
          </li>
          <li>
            <b>Cluster Sampling</b>
          </li>
        </ul>
        <p>
          {" "}
          <h3>Simple Random Sampling</h3>
          <a href="/Simple random sampling">Simple random sampling</a> calls for
          every member within the population to have an equal chance of being
          selected for analysis. The entire population is used as the basis for
          sampling, and any random generator based on chance can select the
          sample items. For example, 100 individuals are lined up and 10 are
          chosen at random.
        </p>{" "}
        <p>
          {" "}
          <h3>Systemic Sampling</h3>
          <a href="/Systematic sampling">Systematic sampling</a> calls for a
          random sample as well, but its technique is slightly modified to make
          it easier to conduct. A single random number is generated and
          individuals are then selected at a specified regular interval until
          the sample size is complete. For example, 100 individuals are lined up
          and numbered. The 7th individual is selected for the sample followed
          by every subsequent 9th individual until 10 sample items have been
          selected.
        </p>{" "}
        <p>
          {" "}
          <h3>Stratified Sampling</h3>
          <a href="/Stratified sampling">Stratified sampling</a> calls for more
          control over your sample. The population is divided into subgroups
          based on similar characteristics. Then you calculate how many people
          from each subgroup would represent the entire population. For example,
          100 individuals are grouped by gender and race. Then a sample from
          each subgroup is taken in proportion to how representative that
          subgroup is of the population.
        </p>{" "}
        <p>
          {" "}
          <h3>Cluster Sampling</h3>
          <a href="/Cluster sampling">Cluster sampling</a> calls for subgroups
          as well, but each subgroup should be representative of the population.
          The entire subgroup is randomly selected instead of randomly selecting
          individuals within a subgroup.
        </p>{" "}
      </div>
    </div>
  );
};

export default Statistics;

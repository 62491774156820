// Imports
import ___CSS_LOADER_API_SOURCEMAP_IMPORT___ from "../../node_modules/css-loader/dist/runtime/sourceMaps.js";
import ___CSS_LOADER_API_IMPORT___ from "../../node_modules/css-loader/dist/runtime/api.js";
var ___CSS_LOADER_EXPORT___ = ___CSS_LOADER_API_IMPORT___(___CSS_LOADER_API_SOURCEMAP_IMPORT___);
// Module
___CSS_LOADER_EXPORT___.push([module.id, `.table-of-contents {
    float: left;
  }
  
a {
    text-decoration: none;
}

  .section {
    margin: 0 auto;
    width: 50%;
    text-align: center;
  }`, "",{"version":3,"sources":["webpack://./src/pages/Stats.css"],"names":[],"mappings":"AAAA;IACI,WAAW;EACb;;AAEF;IACI,qBAAqB;AACzB;;EAEE;IACE,cAAc;IACd,UAAU;IACV,kBAAkB;EACpB","sourcesContent":[".table-of-contents {\n    float: left;\n  }\n  \na {\n    text-decoration: none;\n}\n\n  .section {\n    margin: 0 auto;\n    width: 50%;\n    text-align: center;\n  }"],"sourceRoot":""}]);
// Exports
___CSS_LOADER_EXPORT___.locals = {};
export default ___CSS_LOADER_EXPORT___;

import React from 'react'
import '../pages/notes/statistics/Population.css'

const Entertainements = () => {
  return (
    <div className="table-of-entertainments">
    <script async src="https://pagead2.googlesyndication.com/pagead/js/adsbygoogle.js?client=ca-pub-7155170575712265"
     crossorigin="anonymous"></script>
    </div>
  )
}

export default Entertainements

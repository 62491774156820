import React from "react";

const Timeseries = () => {
  return (
    <div>
      <div className="table-of-contents">
        <h2>Table of Contents</h2>
        <ul>
          <li>
            <a href="#What Is Time-Series?">What Is Time-Series?</a>
          </li>
          <li>
            <a href="#Understanding Time-Series">Understanding Time-Series</a>
          </li>
          <li>
            <a href="#Components of Time Series">Components of Time Series</a>
          </li>
          <li>
            <a href="#Time Series Analysis">Time Series Analysis</a>
          </li>
          <li>
            <a href="#Forecasting">Forecasting</a>
          </li>
          {/* <li>
            <a href="#Levels of Measurement">Levels of Measurement</a>
          </li>
          <li>
            <a href="#Sampling Techniques">Sampling Techniques</a>
          </li> */}
          {/* <li>
            <a href="#Uses of Statistics">Uses of Statistics</a>
          </li> */}
        </ul>
      </div>
      <p>
        <i>
          <b>Backend from</b>: https://www.wallstreetmojo.com/time-series/
        </i>
      </p>
      <div id="What Is Time-Series?" className="section">
        <h1>Time Series: Definition, Types, and Importance</h1>
        <h2>What Is Time-Series?</h2>
        <p>
          <i>
            Time series refers to a chain of data points observed and recorded
            in a time order over a specific period. It represents the output
            obtained from monitoring and tracking specific events or processes.
          </i>
        </p>{" "}
        <p>
          It is also known as time-stamped data and plays a major role in
          analysis and forecasting processes. It involves noting measurements at
          equally spaced time intervals. Its construction helps academics study
          how a variable changes over time. For instance, it can be applied to
          study the price movements of a security over time.{" "}
        </p>
        <p>
          {" "}
          <h5>
            <i>key Points;</i>
          </h5>
          <ul>
            <li>
              Time series refers to a chain of data points observed due to
              monitoring and recording in a time order over a specific period.{" "}
            </li>

            <li>
              Its components are the secular trend, seasonal trend, cyclical
              variations, and irregular variations.
            </li>
            <li>
              Its analysis derives meaningful statistics, interprets trends,
              identifies patterns, and contributes to decision making. Examples
              of its application include budgetary analysis and stock market
              analysis.
            </li>
            <li>
              Its applications include various models generated to forecast data
              and induce strong strategic decision-making. Examples include
              weather forecasting and sales forecasting.{" "}
            </li>
          </ul>
        </p>
      </div>
      <div id="Understanding Time-Series" className="section">
        <h2>Understanding Time-Series</h2>
        <p>
          Time series contains observation in the numerical form represented in
          chronological order. Analysis of this observed data and applying it as
          input to derive possible future developments was popularized in the
          late 20th century. It was primarily due to the textbook on time series
          analysis written by George E.P. Box and Gwilym M. Jenkins. They
          introduced the procedure to develop forecasts using the input based on
          the data points in the order of time, famously known as Box-Jenkins
          Analysis.{" "}
        </p>
        <p>
          It can be categorized into different types; one is the categorization
          into non-stationary and stationary time series. If stationary, it has
          stochastic properties like variance unvarying with time. Whereas for
          non-stationary, its properties vary with time, and it can be a trend,
          random occurrences, seasons, cycles, etc. It is easy and effective to
          model when it is stationary by applying statistical modeling methods.{" "}
        </p>
        <p>
          {" "}
          Various programming languages are used in the data analysis process
          involving time-dependent data. For example, its analysis with SPSS,
          Python and R programming. It can also check if the data presented is
          stationary or non-stationary, and time-series databases are good for
          working on time-dependent data.
        </p>
      </div>
      <div id="Components of Time Series" className="section">
        <h2>Components of Time Series</h2>
        <p>
          {/* image example here */}
          <img
            className="img_img"
            alt="time-series"
            src="https://www.wallstreetmojo.com/wp-content/uploads/2022/06/Time-Series.jpg.webp"
          />
        </p>{" "}
        <p>
          <ul>
            <li>Secular Trend</li>
            <li>Seasonal Trend</li>
            <li>Cyclical Variations</li>
            <li>Irregular Variations</li>
          </ul>
        </p>
        <h3>Secular Trend</h3>
        <p>
          It indicates the long-running pattern identified from the chain of
          data recorded. It can be increasing or decreasing, indicating the
          future direction. Although it is commonly known as an average tendency
          of any aspect, the trend may vary in specific parts oscillating
          between upward and downward. Still, the overall trend will depict a
          single movement only, either upward or downward. For example, in
          summer, the temperature may rise or decline in a day, but the overall
          trend during the first two months will show how the heat has been
          rising from the beginning.{" "}
        </p>
        <h3>Seasonal Trend</h3>
        <p>
          Seasonal variations represent the presence of rhythmic patterns.
          Certain pattern repeatedly occurs at the same period or point every
          year. For example, the sale of umbrellas increases during the rainy
          season, and air conditioners increase during summer. Apart from
          natural occurrences, man-made conventions like fashion, marriage
          season, festivals, etc., play a key role in contributing to seasonal
          trends.{" "}
        </p>{" "}
        <h3>Cyclical Variations</h3>
        <p>
          It represents a cyclical pattern composed of up and down movement. It
          may span more than one year and go from phase to phase to complete a
          cycle. A business cycle is a significant example of a cyclic
          variation, denoted that a business goes through four stages in its
          life. Starting from the introduction, expansion, prosperity, and
          decline. How well the company can perform and stretch its phases
          depends on its performance.{" "}
        </p>
        <h3>Irregular Variations</h3>
        <p>
          {" "}
          It refers to variations that are uncontrollable and inevitable. It
          occurs randomly, opposite to regular changes or occurrences, and does
          not associate with a pattern. These fluctuations are unpredictable and
          unexplainable. Forces like natural and man-made disasters can trigger
          irregular variations.
        </p>
      </div>
      <div id="Time Series Analysis" className="section">
        <h2>Time Series Analysis</h2>
        <p>
          Analysis of time-stamped data constructed helps derive meaningful
          <a href="/category/statistics"> statistics</a> and contributes to
          decision making. For example, it is used to understand and interpret
          trends and patterns. In addition, it helps in prediction,
          classification, segmentation, descriptive and intervention analysis,
          etc.
        </p>{" "}
        <p>
          Its application is generally seen with non-stationary data to observe
          how certain things change over time. Examples include its vital
          application in the stock market , forex historical dataset, sales,
          inventory, and weather analysis. Hence expertise in it is important
          among the professionals from fields like retail, financial market ,
          sales, weather forecasting, etc.
        </p>{" "}
      </div>
      <div id="Forecasting" className="section">
        <h2>Forecasting</h2>
        <p>
          Time series forecasting means assessing the time-stamped data using
          statistical calculations and modeling to make predictions and induce
          strong strategic decision-making. The process is widely adopted in
          many sectors, for example, sales forecasting and weather forecasting.
          Forecasting highly depends on the nature of the data, and the process
          is usually performed on historical data. The more simplified it is,
          the more accurate the forecasting becomes.
        </p>{" "}
        <p>
          The model does have its limitations as it does not guarantee accuracy
          and may vary from the actual outcome. However, the analysts have some
          operating authority over models to regulate such constraints. Examples
          of time series methods used for forecasting are Autoregression (AR),
          Moving Average (MA), Autoregressive Moving Average (ARMA), and
          Autoregressive Integrated Moving Average (ARIMA).
        </p>{" "}
      </div>
      <div id="Recommended Topics" className="section">
        <h2>Recommended Tutorials</h2>
        <p>
          This article has been a guide to what is Stochastic Process & its
          meaning. Here, we explain it in detail with its types, applications,
          and examples. You may also find some useful articles here –
        </p>
        <p>
          <h2><a href="/category/Statistics">Statistics</a></h2>
        </p>
        <p>
            <h2><a href="/category/biostatistics">Biostatistics</a></h2>
        </p>
        <p>
            <h2><a href="/category/econometrics">Econometrics</a></h2>
        </p>
      </div>
    </div>
  );
};

export default Timeseries;

// Imports
import ___CSS_LOADER_API_SOURCEMAP_IMPORT___ from "../../node_modules/css-loader/dist/runtime/sourceMaps.js";
import ___CSS_LOADER_API_IMPORT___ from "../../node_modules/css-loader/dist/runtime/api.js";
var ___CSS_LOADER_EXPORT___ = ___CSS_LOADER_API_IMPORT___(___CSS_LOADER_API_SOURCEMAP_IMPORT___);
// Module
___CSS_LOADER_EXPORT___.push([module.id, `h1 {
  font-size: 24px;
  font-weight: bold;
  color: #000000;
}

form {
  padding: 10px;
  margin: 10px;
}

label {
  font-size: 16px;
  font-weight: bold;
  color: #000000;
}

input[type="text"] {
  width: 300px;
  padding: 5px;
  margin: 5px;
  display: block;
}

textarea {
  width: 300px;
  padding: 5px;
  margin: 5px;
  display: block;
}

button {
  padding: 5px;
  margin: 5px;
  background-color: #000000;
  color: #FFFFFF;
}`, "",{"version":3,"sources":["webpack://./src/components/ContactUs.css"],"names":[],"mappings":"AAAA;EACE,eAAe;EACf,iBAAiB;EACjB,cAAc;AAChB;;AAEA;EACE,aAAa;EACb,YAAY;AACd;;AAEA;EACE,eAAe;EACf,iBAAiB;EACjB,cAAc;AAChB;;AAEA;EACE,YAAY;EACZ,YAAY;EACZ,WAAW;EACX,cAAc;AAChB;;AAEA;EACE,YAAY;EACZ,YAAY;EACZ,WAAW;EACX,cAAc;AAChB;;AAEA;EACE,YAAY;EACZ,WAAW;EACX,yBAAyB;EACzB,cAAc;AAChB","sourcesContent":["h1 {\n  font-size: 24px;\n  font-weight: bold;\n  color: #000000;\n}\n\nform {\n  padding: 10px;\n  margin: 10px;\n}\n\nlabel {\n  font-size: 16px;\n  font-weight: bold;\n  color: #000000;\n}\n\ninput[type=\"text\"] {\n  width: 300px;\n  padding: 5px;\n  margin: 5px;\n  display: block;\n}\n\ntextarea {\n  width: 300px;\n  padding: 5px;\n  margin: 5px;\n  display: block;\n}\n\nbutton {\n  padding: 5px;\n  margin: 5px;\n  background-color: #000000;\n  color: #FFFFFF;\n}"],"sourceRoot":""}]);
// Exports
___CSS_LOADER_EXPORT___.locals = {};
export default ___CSS_LOADER_EXPORT___;

import React from 'react'

const BasicStats = () => {
  return (
    <div id="Recommended Topics" className="section">
    <h2>Recommended Tutorials</h2>
    <p>
      This article has been a guide on what are Basics Statistics & its
      applications. Here, we explain it in detail with its types, applications,
      and examples. find some usefull tutorial here –
    </p>
    <p>
      <h2><a href="/statistics/mean">mean</a></h2>
    </p>
    <p>
        <h2><a href="/statistics/median">median</a></h2>
    </p>
    <p>
        <h2><a href="/statistics/mode">mode</a></h2>
    </p>
  </div>
  )
}

export default BasicStats
import React from "react";
import "./Population.css";
import skew from "./img/skewness.png";

const Skewness = () => {
  return (
    <div>
      <div className="table-of-contents">
        <h2>Table of Contents</h2>
        <ul>
          <li>
            <a href="#What Is Skewness?">What Is Skewness?</a>
          </li>
          <li>
            <a href="#Understanding Skewness">Understanding Skewness</a>
          </li>
          <li>
            <a href="#Measuring Skewness">Measuring Skewness</a>
          </li>
          <li>
            <a href="#Types of Skewness">Types of Skewness</a>
          </li>
          <li>
            <a href="#What Does Skewness Tell You?">
              What Does Skewness Tell You?
            </a>
          </li>
          <li>
            <a href="#Example">Example</a>
          </li>
        </ul>
      </div>
      <p>
        <i>
          <b>Backend from</b>: investopedia.com
        </i>
      </p>
      <div id="What Is Skewness?" className="section">
        <h1>Skewness: Positively and Negatively Skewed Defined With Formula</h1>
        <h2>What Is Skewness?</h2>
        <p>
          Skewness is a measurement of the distortion of symmetrical
          distribution or asymmetry in a data set. Skewness is demonstrated on a
          bell curve when data points are not distributed symmetrically to the
          left and right sides of the median on a bell curve. If the bell curve
          is shifted to the left or the right, it is said to be skewed.{" "}
        </p>
        <p>
          Skewness can be quantified as a representation of the extent to which
          a given distribution varies from a normal distribution. A normal
          distribution has a zero skew, while a lognormal distribution, for
          example, would exhibit some right skew.{" "}
        </p>

        <p>
          {" "}
          <h5>
            <i>key Points;</i>
          </h5>
          <ul>
            <li>
              Skewness is the degree of asymmetry observed in a probability
              distribution.{" "}
            </li>

            <li>
              Distributions can exhibit right (positive) skewness or left
              (negative) skewness to varying degrees. A normal distribution
              (bell curve) exhibits zero skewness.
            </li>
            <li>
              Investors note right-skewness when judging a return distribution
              because it better represents the extremes of the data set rather
              than focusing solely on the average.
            </li>
            <li>
              Skewness informs users of the direction of outliers, though it
              does not tell users the number that occurs.{" "}
            </li>
            <li>
              Skewness is often found in stock market returns as well as the
              distribution of average individual income.{" "}
            </li>
          </ul>
        </p>
      </div>
      <div id="Understanding Skewness" className="section">
        <h2>Understanding Skewness</h2>
        <p>
          There are several different types of distributions and skews. The tail
          or string of data points away from the median is impacted for both
          positive and negative skews. Negative skew refers to a longer or
          fatter tail on the left side of the distribution, while positive skew
          refers to a longer or fatter tail on the right. These two skews refer
          to the direction or weight of the distribution.{" "}
        </p>
        <p>
          In addition, a distribution can have a zero skew. Zero skew occurs
          when a data graph is symmetrical. Regardless of how long or fat the
          distribution tails are, a zero skew indicates a normal distribution of
          data. A data set can also have an undefined skewness should the data
          not provide sufficient information about its distribution.{" "}
        </p>
        <p>
          The <a href="/mean">mean</a> of positively skewed data will be greater
          than the <a href="/median">median</a>. In a negatively skewed
          distribution, the exact opposite is the case: the mean of negatively
          skewed data will be less than the median. If the data graphs
          symmetrically, the distribution has zero skewness, regardless of how
          long or fat the tails are.{" "}
        </p>
        <p>
          {" "}
          The three probability distributions depicted below are positively
          skewed (or right-skewed) to an increasing degree. Negatively-skewed
          distributions are also known as left-skewed distributions.
        </p>
      </div>
      <div id="Measuring Skewness" className="section">
        <h2>Measuring Skewness</h2>
        <p>
          {" "}
          There are several ways to measure skewness. Pearson’s first and second
          coefficients of skewness are two common methods. Pearson’s first
          coefficient of skewness, or Pearson mode skewness, subtracts the mode
          from the mean and divides the difference by the standard deviation.
          Pearson’s second coefficient of skewness, or Pearson median skewness,
          subtracts the median from the mean, multiplies the difference by
          three, and divides the product by the standard deviation.
        </p>{" "}
        <p>
          {/* image example here */}
        <img className="img_img"
          alt="skew"
          src={skew}
        />
        </p>{" "}
        <p>
          Pearson’s first coefficient of skewness is useful if the data exhibit
          a strong mode. If the data have a weak mode or multiple modes,
          Pearson’s second coefficient may be preferable, as it does not rely on
          mode as a measure of central tendency.{" "}
        </p>{" "}
      </div>
      <div id="Types of Skewness" className="section">
        <h2>Types of Skewness</h2>
        <p>
          As noted above, skewness measures asymmetry in a data set and is
          usually shown on a bell curve. Normal distributions have zero
          skewness. This means that the distribution ends up being symmetrical
          around the mean. Having said that, there are instances where skewness
          isn't symmetrical. In these cases, it can be either positive or
          negative. Below, we highlight what each type of skewness means.
        </p>
        <p>
          {" "}
          <h3>Positive Skewness</h3>A distribution is positively skewed when its
          tail is more pronounced on the right side than it is on the left.
          Since the distribution is positive, the assumption is that its value
          is positive. As such, most of the values end up being left of the
          mean. This means that the most extreme values are on the right side.
          As an investor, you may find that you have some small losses with a
          positive skew. But you may also end up realizing large gains—albeit
          fewer.
        </p>{" "}
        <p>
          {" "}
          <h3>Negative Skewness</h3>
          Negative skewness, on the other hand, occurs when the tail is more
          pronounced on the left rather than the right side. Contrary to the
          positive skew, most of the values are found on the right side of the
          mean when it comes to negative skewness. As such, the most extreme
          values are found further to the left. Having a negative skew may
          indicate that you can expect some small gains here and there. But you
          can generally expect to see a few large losses here and there as an
          investor.
        </p>{" "}
      </div>
      <div id="What Does Skewness Tell You?" className="section">
        <h2>What Does Skewness Tell You?</h2>
        <p>
          Investors note skewness when judging a return distribution because it,
          like kurtosis, considers the extremes of the data set rather than
          focusing solely on the average. Short- and medium-term investors in
          particular need to look at extremes because they are less likely to
          hold a position long enough to be confident that the average will work
          itself out.
        </p>
        <p>
          {" "}
          Investors commonly use standard deviation to predict future returns,
          but the standard deviation assumes a normal distribution. As few
          return distributions come close to normal, skewness is a better
          measure on which to base performance predictions. This is due to
          skewness risk.
        </p>{" "}
        <p>
          {" "}
          Skewness risk is the increased risk of turning up a data point of high
          skewness in a skewed distribution. Many financial models that attempt
          to predict the future performance of an asset assume a normal
          distribution, in which measures of central tendency are equal. If the
          data are skewed, this kind of model will always underestimate skewness
          risk in its predictions. The more skewed the data, the less accurate
          this financial model will be.
        </p>{" "}
      </div>
      <div id="Examples of a Skewed Distribution" className="section">
        <h2>Examples of a Skewed Distribution</h2>
        <p>
          The departure from normal returns has been observed with more
          frequency in the last two decades, beginning with the internet bubble
          of the late 1990s. In fact, asset returns tend to be increasingly
          right-skewed. This volatility occurred with notable events, such as
          the Sept. 11 terrorist attacks, the housing bubble collapse and
          subsequent financial crisis, and during the years of quantitative
          easing (QE).
        </p>
        <p>
          {" "}
          The broad stock market is often considered to have a negatively skewed
          distribution. The notion is that the market more often returns a small
          positive return more often a large negative loss. However, studies
          have shown that the equity of an individual firm may tend to be
          left-skewed.
        </p>{" "}
        <p>
          {" "}
          A common example of skewness is the distribution of household income
          within the United States, as individuals are less likely to earn very
          high annual income. For example, consider 2020 household income
          statistics. The lowest quintile of income ranged from $0 to $27,026,
          while the highest quintile of income ranged from $85,077 to $141,110.
          With the highest quintile being more than twice as large as the lowest
          quintile, higher-income data points are more dispersed and cause a
          positively-skewed distribution.
        </p>{" "}
      </div>
    </div>
  );
};

export default Skewness;

import React from "react";

const Alternative = () => {
  return (
    <div>
      <div className="table-of-contents">
        <h2>Table of Contents</h2>
        <ul>
          <li>
            <a href="#What is Alternative Hypothesis?">
              What is Alternative Hypothesis?
            </a>
          </li>
          <li>
            <a href="#Understanding Alternative Hypothesis">
              Understanding Alternative Hypothesis
            </a>
          </li>
          <li>
            <a href="#Alternative Hypothesis Formula">
              Alternative Hypothesis Formula
            </a>
          </li>
          <li>
            <a href="#Alternative Hypothesis Examples">
              Alternative Hypothesis Examples
            </a>
          </li>
          <li>
            <a href="#Interpretation">Interpretation</a>
          </li>
          {/* <li>
                <a href="#Limitations">Limitations</a>
              </li>
              <li>
                <a href="#Null Hypothesis vs. Alternate Hypothesis">
                  Null Hypothesis vs. Alternate Hypothesis
                </a>
              </li> */}
          {/* <li>
                    <a href="#Uses of Statistics">Uses of Statistics</a>
                  </li> */}
        </ul>
      </div>
      <p>
        <i>
          <b>Backend from</b>: https://www.wallstreetmojo.com/null-hypothesis/
        </i>
      </p>
      <div id="What is Alternative Hypothesis?" className="section">
        <h1>Alternative Hypothesis: Definition, Types, and Importance</h1>
        <h2>What is Alternative Hypothesis?</h2>
        <p>
          <i>
            An alternative hypothesis in statistics refers to a proposed
            statement or argument in the hypothesis test. It indicates the
            existence of the statistical relationship between variables and
            usually aligns with the research hypothesis.
          </i>
        </p>
        <p>
          It is one of the two mutually exclusive statements in statistical
          hypothesis testing. In other words, it is the hypothesis opposing the
          null hypothesis. In simple terms, it points to the hypothesis that
          gets proved if there is enough evidence to defy the null hypothesis.{" "}
        </p>
        <p>
          {/* image example here */}
          <img
            className="img_img"
            alt="stochastic"
            src="https://www.wallstreetmojo.com/wp-content/uploads/2022/09/Alternative-Hypothesis-1.jpg"
          />
        </p>{" "}
        <p>
          {" "}
          <h5>
            <i>key Points;</i>
          </h5>
          <ul>
            <li>
              The alternative hypothesis definition in research points to one of
              the stated statements in the hypothesis test. The symbols Ha or H1
              denotes it.{" "}
            </li>

            <li>
              It contradicts the null hypothesis based on the current phase, and
              successful verification of the alternative statement disproves the
              null hypothesis.
            </li>
            <li>
              It favors and proposes the presence of a statistical relationship
              between variables and usually aligns with the research hypothesis.
              The main types used in the statistical hypothesis testing are
              point, one-tailed directional, two-tailed directional, and
              non-directional.
            </li>
            <li>
              Instead of favoring the null hypothesis, researchers are more
              likely to try hard to prove the alternative so that researchers
              can explore new avenues with its interpretation.
            </li>
          </ul>
        </p>
      </div>
      <div id="Understanding Alternative Hypothesis" className="section">
        <h2>Understanding Alternative Hypothesis</h2>
        <p>
          The alternative hypothesis in research indicates a connection between
          the two variables in the study, that is, the dependent and independent
          variables. On the other hand, the null hypothesis asserts that there
          is no connection between them. An experimental hypothesis predicts
          what corresponding change(s) will occur in the dependent variable when
          the independent variable is changed. Either null or alternative
          hypotheses get accepted during the process. If the alternative is
          proved, then the rejection of null occurs. In other words, if the null
          hypothesis is disproved, then the other opposing statement gets
          accepted.{" "}
        </p>
        <p>
          It plays a significant role in contemporary statistical hypothesis
          testing. Famous statisticians Jerzy Neyman and Egon Pearson developed
          the idea of an alternative hypothesis in testing. It is employed in
          the Neyman-Pearson lemma technique, introduced by Jerzy Neyman and
          Egon Pearson, to determine whether the hypothesis test has the
          greatest statistical power. The main types are point, one-tailed
          directional, two-tailed directional, and non-directional.{" "}
        </p>
        <p>
          {" "}
          The correct formation of it is important in research. It is widely
          applied in research in statistics, medicine, psychology, science,
          mathematics, etc. Explanations for how something delivers a benefit
          are sometimes basic and vague when it is viewed in a positive light.
          It may be possible to summarize an alternative theory by addressing
          issues or important matters.
        </p>
        <p>
          In decisions made in the presence of ambiguity, alternative theories
          or suggestions are typically disregarded or given little
          consideration. It also depends on how things are portrayed or
          represented. When a particular depiction was used, the alternative
          frequently fell by the wayside or was given less consideration.
          However, a representation that prompts further research exudes hope
          and enthusiasm or makes assessments resulting in the acceptance of
          alternatives.
        </p>
      </div>
      <div id="Alternative Hypothesis Formula" className="section">
        <h2>Alternative Hypothesis Formula</h2>
        <p>
          The symbols Ha or H1 denotes it. Let’s look into some of the
          representations interpreting different scenarios:
        </p>
        <p>
          <b>
            <i>Left tailed:</i>
          </b>{" "}
          The sample proportion (π) is less than the specified value (π0), (H1 =
          π less-than π0)
        </p>{" "}
        <p>
          <b>
            <i>Two-tailed: </i>
          </b>{" "}
          The sample proportion (π) is not equal to a specific value (π0), (H1 =
          π ≠ π0)
        </p>{" "}
        <p>
          <b>
            <i>Right-tailed: </i>
          </b>{" "}
          The sample proportion (π) is greater than some value (π0), (H1 = π
          greater-than π0)
        </p>{" "}
      </div>
      <div id="Alternative Hypothesis Examples" className="section">
        <h2>Alternative Hypothesis Examples</h2>
        <p>
          Let’s look into the following null and alternative hypothesis examples
          for a better understanding of the concept:
        </p>
        <p>
          <b> Research question:</b> Does food C increases the risk of a heart
          attack?
        </p>{" "}
        <p>
          <b>
            <i>Ha: </i>
          </b>{" "}
          Consumption of food C increases the risk of heart attack (Alternative:
          align with the research question or hypothesis)
        </p>{" "}
        <p>
          <b>
            <i> H0: </i>
          </b>{" "}
          Consumption of food C does not increase the risk of heart attack (Null
          hypothesis)
        </p>{" "}
        <p>
          Here, Ha and H0 are contradictory statements. Therefore, the null
          hypothesis is initially presumed to be true, and if it is proven
          wrong, then the null hypothesis is rejected, and the alternative
          hypothesis is accepted.
        </p>
      </div>
      <div id="Interpretation" className="section">
        <h2>Interpretation of Alternative Hypothesis</h2>
        <p>Let’s look into some of the significant interpretations:</p>{" "}
        <ul>
          <li>
            First, it is important when researchers are trying to prove a
            statement.
          </li>{" "}
          <li>
            Its interpretation depends on the determination of hypothesis
            testing, ensuring that the change is not by chance and essentially
            based on the data sample and population undertaken for the
            experiment.
          </li>{" "}
          <li>
            It defines that a random cause can influence the data sample
            distracting it from the expected outcome.
          </li>{" "}
          <li>
            Researchers are more inclined toward an alternative theory than a
            null one.
          </li>{" "}
          <li>
            Its interpretation help researchers explore new possibilities and
            define a situation more accurately than the null hypothesis.
          </li>{" "}
          <li>
            Its interpretation defines new results and the potential of claims.
          </li>
        </ul>
      </div>
      <div id="Recommended Topics" className="section">
        <h2>Recommended Tutorials</h2>
        <p>
          This article has been a guide to what is Stochastic Process & its
          meaning. Here, we explain it in detail with its types, applications,
          and examples. You may also find some useful articles here –
        </p>
        <p>
          <h2>
            <a href="/Statistics/null-hypothesis">Null-Hypothesis</a>
          </h2>
        </p>
        <p>
          <h2>
            <a href="/Time Series">Time Series</a>
          </h2>
        </p>
        <p>
          <h2>
            <a href="/statistics/Mean">Mean</a>
          </h2>
        </p>
      </div>
    </div>
  );
};

export default Alternative;

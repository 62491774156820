import React from "react";
import './Population.css'
const Stochastic = () => {
  return (
    <div>
      <div className="table-of-contents">
        <h2>Table of Contents</h2>
        <ul>
          <li>
            <a href="#What Is Stochastic Process?">
              What Is Stochastic Process?
            </a>
          </li>
          <li>
            <a href="#Understanding Stochastic Process">
              Understanding Stochastic Process
            </a>
          </li>
          <li>
            <a href="#Types of Stochestic Process">
              Types of Stochestic Process
            </a>
          </li>
          <li>
            <a href="#Applications of Stochastic Process">
              Applications of Stochastic Process
            </a>
          </li>
          <li>
            <a href="#Examples">Examples</a>
          </li>
          {/* <li>
                <a href="#Example">Example</a>
              </li> */}
        </ul>
      </div>
      <p>
        <i>
          <b>Backend from</b>:
          https://www.wallstreetmojo.com/stochastic-process/
        </i>
      </p>
      <div id="What Is Stochastic Process?" className="section">
        <h1>Stochastic Process: Definition, Types, and Importance</h1>
        <h2>What Is Stochastic Process?</h2>
        <p>
          Stochastic process (random process) refers to a series of events where
          each event through random occurrence has an inbuilt pattern. For
          example, in the financial world, one uses stochastic models to
          estimate outcomes in uncertain situations concerning returns on
          investment, inflation rates, and market volatility.
        </p>{" "}
        <p>
          It is a key tool for traders, planners, portfolio managers, and
          analysts deciding on investment decisions. One can also use it to know
          about the performance of an individual security portfolio using
          <a href="/probability distributions"> probability distributions</a>.
          The random process showcases data to estimate results that account for
          a specific degree of randomness or ambiguity. It contains multiple
          factors to give a variety of outcomes about different situations so
          that dynamic effects can get recorded.{" "}
        </p>
        <p>
          {" "}
          <h5>
            <i>key Points;</i>
          </h5>
          <ul>
            <li>
              A stochastic process in finance or a random process is defined as
              a collection of random variables, with each variable arranged
              using an inbuilt set of indices representing time.{" "}
            </li>

            <li>
              Traders apply it to know about the performance of an individual
              security portfolio using probability distributions on a random
              basis.
            </li>
            <li>
              It has four main types – non-stationary stochastic processes,
              stationary stochastic processes, discrete-time stochastic
              processes, and continuous-time stochastic processes.
            </li>
            <li>
              It finds various uses in the financial world, which has uncertain
              nature like securities performance, market volatility, inflation
              rates, epidemic modeling like in coronavirus, understanding the
              disease transfer, traffic simulations, and Communications and
              Signal Processing of Electrical Engineering.{" "}
            </li>
            {/* <li>
              Skewness is often found in stock market returns as well as the
              distribution of average individual income.{" "}
            </li> */}
          </ul>
        </p>
      </div>
      <div id="Understanding Stochastic Process" className="section">
        <h2>Understanding Stochastic Process</h2>
        <p>
          A Stochastic process meaning refers to a probability model that
          describes a set of time-ordered{" "}
          <a href="/random variables">random variables</a> representing the
          potential impact of a dynamic process in a single instance.
          Researchers use it to explain all those phenomena that contain random
          variables of huge amounts but show collective effects like Bernoulli’s
          or capillary effect.{" "}
        </p>
        <p>
          A stochastic model has the following certain features that separate it
          from other probabilistic models:{" "}
        </p>
        <ul>
          <li>
            It must comprise more than one input that reflects the ambiguity of
            the predicted situation.
          </li>
          <li>
            The model must represent comprehensive aspects of a circumstance to
            predict distribution correctly.
          </li>
        </ul>
        <p>
          As the model of stochastic contains uncertainty, the results rendered
          by the model give a good forecast of possible and probable outcomes.
          Moreover, for estimating all probable outcomes, more than one input
          should facilitate random variation during a period. Hence, in such
          situations, various outcomes are shown as a result of a probability
          distribution based on mathematical functions.{" "}
        </p>
        <p>
          {" "}
          In probability theory, this process is one where chance plays a role.
          For instance, in the stock market, each stock has a predetermined
          probability of giving profit or loss at any given time. On the other
          hand, a stochastic process is more broadly defined as a family of
          random variables indexed against another variable or group of
          variables. Thus, it is one of the most broadly applicable areas of
          probability study.
        </p>{" "}
        <p>
          In an analysis of portfolio investment returns, the stochastic model
          could estimate all the probability of different returns based on
          market volatility. It happens because the random variable utilizes
          time-series data showing contrast observed within historical data
          during a period. Therefore, multiple stochastic estimations contribute
          to the final probability distribution reflecting the randomness of
          inputs.
        </p>
      </div>
      <div id="Types of Stochestic Process" className="section">
        <h2>Types of Stochestic Process</h2>
        <p>
          {" "}
          One can categorize it based on stochasticity or how random variables
          are generated. Let us discuss the four different types of stochastic
          processes below:
        </p>{" "}
        <h3>Non-Stationary Stochastic Processes</h3>
        <p>
          As the name suggests, the random variables have dynamic statistical
          properties over time.
        </p>{" "}
        <h3>Stationary Stochastic Processes</h3>
        <p>
          Under it, the random variables have constant statistical properties
          with time.
        </p>{" "}
        <h3> Discrete-Time Stochastic Processes</h3>
        <p>
          It comprises random variables in a sequence that contains individual
          variables with a limited set of values.
        </p>{" "}
        <h3>Continuous-Time Stochastic Processes</h3>
        <p>
          Under it, a sequence of random variables gets to take values that
          remain in a continuous range.
        </p>{" "}
        <p>
          Markov processes, Poisson processes, and time series, where the index
          variable is time, are some fundamental stochastic process types. It
          doesn’t matter if this indexing is discrete or continuous; what
          matters is how the variables change over time.
        </p>
        <p>
          {/* image example here */}
          <img
            className="img_img"
            alt="stochastic"
            src="https://www.wallstreetmojo.com/wp-content/uploads/2022/12/Stochastic-Process.png"
          />
        </p>{" "}
      </div>
      <div id="Applications of Stochastic Process" className="section">
        <h2>Applications of Stochastic Process</h2>
        <p>
          Stochastic programs or processes are dynamics that form as a result of
          probabilistic variations or fluctuations. It has many applications in
          the financial sector, characterized by uncertainty in several areas,
          including securities performance, market volatility, inflation rates,
          epidemic modeling for diseases like coronavirus, comprehension of
          disease transmission, traffic simulations, and electrical engineering
          communications and signal processing. More stochastic processes and
          their applications are discussed below:
        </p>{" "}
        <ul>
          <li>
            Many financial planners and managers use it to predict security and
            market behavior.
          </li>
          <li>Traffic simulations are done using it.</li>
          <li>One also uses it in epidemic modeling, like in coronavirus.</li>
          <li>
            It also gets applied in mobile cellular networks, communications,
            and signal processing of electrical engineering.
          </li>
          <li>
            Finding the present guidelines for when and how much to order would
            be a stochastic process. The corporation would have to modify and
            reevaluate these procedures until they produce a more favorable
            result if they are not successful—if a company frequently had too
            much or too enough inventory.
          </li>
          <li>
            In condensed physics, one uses it to describe a phenomenon
            accurately.
          </li>
        </ul>{" "}
      </div>
      <div id="Examples" className="section">
        <h2>Examples</h2>
        <p>
          Let’s take the help of some Random process examples to understand the
          concept of stochastic processes and their applications.
        </p>
        <h3>
          <i>Example I</i>
        </h3>
        <p>
          {" "}
          Here, the first example would be those Random Processes with discrete
          parameters and continuous state space in the securities market. For
          example, let us take the DOW-Jones Index values after the nth week.
          Here one has the discrete-time random process having the continuous
          state space, namely (0, ∞).
        </p>{" "}
        <p>
          {" "}
          When analyzed using probability, such a process gets wide use in
          making a reasonable decision regarding securities trading. As a
          result, traders use a random process for ascertaining the best time to
          trade securities.
        </p>{" "}
        <h3>
          <i>Example II</i>
        </h3>
        <p>
          The second example comprises a bus BlueCouch which takes students to
          and from the dormitory complex, allowing the student’s union to arrive
          many times during the day. Bus BlueCouch has an intake capacity of 50
          students at one go. If the student’s number is fifty or little lesser,
          then the bus makes them board it. However, when the number of students
          exceeds 50, then only fifty students will get to board the bus, and
          the remaining will wait for the next round of the bus.
        </p>
        <p>
          {" "}
          One can see that the number of students waiting for the empty bus to
          board its destination takes the form of a random process. Moreover,
          when one counts the time of bus arrival, then the number of students
          in a queue for bus arrival also forms a random process. Furthermore,
          when one considers the number of students in the queue for a bus at
          any given the time of day has the continuous space parameter. Hence,
          in this case, also, the process describes a distinct state of the
          random process concerning continuous time.
        </p>{" "}
        <p>
          {" "}
          Lastly, in this example, the nth student reaching the bus stop has a
          certain time associated with them. Thus, the nth student waiting time
          also forms a continuous-state random process.
        </p>{" "}
      </div>
      <div id="Recommended Topics" className="section">
        <h2>Recommended Tutorials</h2>
        <p>
          This article has been a guide to what is Stochastic Process & its
          meaning. Here, we explain it in detail with its types, applications,
          and examples. You may also find some useful articles here –
        </p>
        <p>
          <h2><a href="/category/Statistics">Statistics</a></h2>
        </p>
        <p>
            <h2><a href="/Time Series">Time Serie</a></h2>
        </p>
        <p>
            <h2><a href="/statistics/Mean">Mean</a></h2>
        </p>
      </div>
    </div>
  );
};

export default Stochastic;

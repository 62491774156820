import React from "react";

const Median = () => {
  return (
    <div>
      <div className="table-of-contents">
        <h2>Table of Contents</h2>
        <ul>
          <li>
            <a href="#What Is the Median?">What Is the Median?</a>
          </li>
          <li>
            <a href="#Understanding the Median">Understanding the Median</a>
          </li>
          <li>
          <a href="#Median Example">
              Median Example
            </a>
          </li>
          {/* <li>
            <a href="#Examples of Samples">Examples of Samples</a>
          </li> */}
        </ul>
      </div>
      <p>
        <i>
          <b>Backend from</b>: investopedia.com
        </i>
      </p>
      <div id="What Is the Median?" className="section">
        <h1>Median: What It Is and How to Calculate It, With Examples</h1>
        <h2>What Is the Median?</h2>
        <p>
          <p>
            The median is the middle number in a sorted, ascending or descending
            list of numbers and can be more descriptive of that data set than
            the average. It is the point above and below which half (50%) the
            observed data falls, and so represents the midpoint of the data.
          </p>
          The median is often compared with other descriptive statistics such as
          the mean (average), mode, and standard deviation.{" "}
        </p>

        <p>
          {" "}
          <h5>
            <i>key Points;</i>
          </h5>
          <ul>
            <li>
              The median is the middle number in a sorted list of numbers and
              can be more descriptive of that data set than the average.{" "}
            </li>

            <li>
              The median is sometimes used as opposed to the mean when there are
              outliers in the sequence that might skew the average of the
              values.
            </li>
            <li>
              If there is an odd amount of numbers, the median value is the
              number that is in the middle, with the same amount of numbers
              below and above.
            </li>
            <li>
              If there is an even amount of numbers in the list, the middle pair
              must be determined, added together, and divided by two to find the
              median value.{" "}
            </li>
            <li>
              In a normal distribution, the median is the same as the mean and
              the mode.{" "}
            </li>
          </ul>
        </p>
      </div>
      <div id="Understanding the Median" className="section">
        <h2>Understanding the Median</h2>
        <p>
          Median is the middle number in a sorted list of numbers. To determine
          the median value in a sequence of numbers, the numbers must first be
          sorted, or arranged, in value order from lowest to highest or highest
          to lowest. The median can be used to determine an approximate average,
          or mean, but is not to be confused with the actual mean.{" "}
          <ul>
            <li>
              If there is an odd amount of numbers, the median value is the
              number that is in the middle, with the same amount of numbers
              below and above.
            </li>
            <li>
              If there is an even amount of numbers in the list, the middle pair
              must be determined, added together, and divided by two to find the
              median value.
            </li>
          </ul>
        </p>
      </div>
      <div id="Median Example" className="section">
        <h2>Median Example</h2>
        <p>
          To find the median value in a list with an odd amount of numbers, one
          would find the number that is in the middle with an equal amount of
          numbers on either side of the median. To find the median, first
          arrange the numbers in order, usually from lowest to highest.
        </p>
        <p>
          {" "}
          For example, in a data set of (3, 13, 2, 34, 11, 26, 47), the sorted
          order becomes (2, 3, 11, 13, 26, 34, 47). The median is the number in
          the middle (2, 3, 11, 13, 26, 34, 47), which in this instance is 13
          since there are three numbers on either side.
        </p>{" "}
        <p>
          To find the median value in a list with an even amount of numbers, one
          must determine the middle pair, add them, and divide by two. Again,
          arrange the numbers in order from lowest to highest.
        </p>
        <p>
          For example, in a data set of (3, 13, 2, 34, 11, 17, 27, 47), the
          sorted order becomes (2, 3, 11, 13, 17, 27, 34, 47). The median is the
          average of the two numbers in the middle{" "}
          {(2, 3, 11, 13, 17, 26, 34, 47)}, which in this case is fifteen (13 +
          17) / 2 ={(13 + 17) / 2}.
        </p>
      </div>
    </div>
  );
};

export default Median;

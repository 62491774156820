import React from "react";
import "./Population.css";

const Nullhypothesis = () => {
  return (
    <div>
      <div className="table-of-contents">
        <h2>Table of Contents</h2>
        <ul>
          <li>
            <a href="#What is Null Hypothesis?">What is Null Hypothesis?</a>
          </li>
          <li>
            <a href="#Understanding Null Hypothesis">
              Understanding Null Hypothesis
            </a>
          </li>
          <li>
            <a href="#Null Hypothesis Formula">Null Hypothesis Formula</a>
          </li>
          <li>
            <a href="#Null Hypothesis Examples">Null Hypothesis Examples</a>
          </li>
          <li>
            <a href="#Significance">Significance</a>
          </li>
          <li>
            <a href="#Limitations">Limitations</a>
          </li>
          <li>
            <a href="#Null Hypothesis vs. Alternate Hypothesis">
              Null Hypothesis vs. Alternate Hypothesis
            </a>
          </li>
          {/* <li>
                <a href="#Uses of Statistics">Uses of Statistics</a>
              </li> */}
        </ul>
      </div>
      <p>
        <i>
          <b>Backend from</b>: https://www.wallstreetmojo.com/null-hypothesis/
        </i>
      </p>
      <div id="What is Null Hypothesis?" className="section">
        <h1>Null Hypothesis: Definition, Types, and Importance</h1>
        <h2>What is Null Hypothesis?</h2>
        <p>
          <i>
            The null-hypothesis is considered an accepted truth. It assumes that
            the research is false, that the observations are caused by random
            factors. Researchers must prove the null-hypothesis wrong to prove
            their alternate hypothesis
          </i>
        </p>
        <p>
          The null-hypothesis presumes that the sampled data and the population
          data have no difference. It is the opposite of the alternate
          hypothesis, which says that the sample or claimed data differs from
          the actual population. The null-hypothesis is denoted by H0
          (pronounced as ‘H naught’).{" "}
        </p>

        <p>
          {" "}
          <h5>
            <i>key Points;</i>
          </h5>
          <ul>
            <li>
              During, Null Hypothesis Significance Testing (NHST), if the level
              of significance is within the acceptable limit or confidence
              interval, H0 is accepted; otherwise, it is rejected.{" "}
            </li>

            <li>
              Hypothesis testing is a form of a mathematical model that is used
              to accept or reject a hypothesis within a range of confidence
              levels.
            </li>
            <li>
              Null hypothesis is an assumption that is accepted valid unless
              proven otherwise. It is used for prove or dissprove research
              statements along with statistical data.
            </li>
          </ul>
        </p>
      </div>
      <div id="Understanding Null Hypothesis" className="section">
        <h2>Understanding Null Hypothesis</h2>
        <p>
          A null-hypothesis can be defined as an accepted fact that may or may
          not be true. In the initial claim of the null-hypothesis, it is
          believed that the assumption is valid. The null hypothesis is mainly
          used for verifying the relevance of Statistical data taken as a
          sample. This sample is then compared to the characteristics of the
          whole population from which the sample was taken. Researchers can
          prove or disprove a statement or assumption by conducting Hypothesis
          Testing (NHST).{" "}
        </p>
        <p>
          For example, assume that a claim states it takes 30 days to form a
          habit. Therefore, it will be considered that it is valid until there
          is some statistical significance to prove that our assumption is
          wrong, and it does not take 30 days to form a habit. Hypothesis
          testing is a mathematical model used to accept or reject the
          hypothesis within the prescribed range of confidence level. It is also
          used for verifying the difference between alternative procedures.{" "}
        </p>
        <p>
          {" "}
          The null hypothesis serves as a base for prominent scientific
          research. For example, scientists believe that “there is life on
          Mars,” The statement could be accepted or rejected based on
          statistical analysis.
        </p>
      </div>
      <div id="Null Hypothesis Formula" className="section">
        <h2>Null Hypothesis Formula</h2>
        <p>Based on the null hypothesis, we need to prove that: </p>
        <p>
          {/* image example here */}
          <img
            className="img_img"
            alt="stochastic"
            src="https://www.wallstreetmojo.com/wp-content/uploads/2020/04/null-theory-explanation.jpg.webp"
          />
        </p>{" "}
        <p>
          A hypothesis is tested for significance levels level in the observed
          data. This is done for summarizing theoretical data.
        </p>{" "}
        <p>
          For calculation of deviation from the claimed data, we can use the
          formula:
        </p>
        <p>
          {/* image example here */}
          <img
            className="img_img"
            alt="stochastic"
            src="https://www.wallstreetmojo.com/wp-content/uploads/2020/04/For-calculation-of-deviation.jpg.webp"
          />
        </p>{" "}
        <h3>How to find the null-hypothesis?</h3>
        <p>Some of the basic steps to determine H0 are as follows:</p>{" "}
        <ul>
          <li>The first step is to assume that the given statement is true.</li>
          <li>
            Next, find the level of significance or the deviation rate. For this
            first find the difference between claimed data and the actual data
            and then divide it by claimed data. The result is multiplied by 100.
          </li>
          <li>
            If the result falls within the confidence interval, then the null
            hypothesis is accepted; however, the hypothesis is rejected if it is
            outside the <a href="/confidence interval">confidence interval</a>.
            Here, we see that the claimed or assumed value has to be equal to or
            nearly equal to the actual data for the null hypothesis to be true.
          </li>
        </ul>
      </div>
      <div id="Null Hypothesis Examples" className="section">
        <h2>Null Hypothesis Examples</h2>
        <p>Now let us apply the null hypothesis to a few examples. </p>
        <p>
          In an industrial study, it was claimed that on average production of
          100 goods, the chance of encountering a faulty product was only 1.5 %.
          But during the study of a sample taken, it was found that the chances
          of encountering a faulty product were actually 1.55%. Comment on this
          condition.
        </p>{" "}
        <h1>Solution:</h1>
        <p>
          In the case of the Null-Hypothesis Testing, the original claim is
          assumed to be correct. Here, it is assumed only 1.5 % of 100 goods
          were faulty products.
        </p>
        <p>
          {/* image example here */}
          <img
            className="img_img"
            alt="null"
            src="https://www.wallstreetmojo.com/wp-content/uploads/2020/04/Null-Hypothesis-Examples-1.jpg.webp"
          />
        </p>{" "}
        <p>
          In this case, deviation helps ascertain the level of significance.
        </p>{" "}
        <p>Calculation of Deviation Rate can be done as follows:</p>
        <p>
          {/* image example here */}
          <img
            className="img_img"
            alt="null"
            src="https://www.wallstreetmojo.com/wp-content/uploads/2020/04/Null-Hypothesis-Examples-1-1.jpg.webp"
          />
        </p>{" "}
        <p>= (1.55%-1.50%) * 100/1.50%</p>{" "}
        <p>Therefore, the Deviation Rate will be:</p>
        <p>
          {/* image example here */}
          <img
            className="img_img"
            alt="null"
            src="https://www.wallstreetmojo.com/wp-content/uploads/2020/04/Null-Hypothesis-Examples-1-2.jpg.webp"
          />
        </p>{" "}
        <p>Deviation Rate = 3.33%</p> <h3>Explanation</h3>
        <p>
          In this example, the{" "}
          <a href="standard deviation">standard deviation </a> from the assumed
          parameter is 3.33 %, which falls within the acceptable range, i.e., 1%
          to 5%. Thus, the null hypothesis can be accepted even when the actual
          valuation differs from the assumption. But if such deviation exceeds
          5%, the hypothesis will be rejected. Beyond that percentage, the
          assumption made will have no justification.
        </p>{" "}
        <p>
          There are many ways to verify a presumed statement. For example, with
          null assumptions, the mean of the sample is compared to the{" "}
          <a
            href="/population
          mean"
          >
            population mean
          </a>
          . Here, the term ‘mean’ could be defined as the average value of the
          parameter and the number of variables.
        </p>
      </div>
      <div id="Significance" className="section">
        <h2>Significance</h2>
        <p>
          While we conduct various statistical tests like{" "}
          <a href="/P-value">P-value</a>, the results can be analyzed by
          determining the null-hypothesis and alternative hypothesis. Some of
          the reasons for its importance are discussed below:
        </p>{" "}
        <ul>
        <li>
          <b>
            <i>Logic Behind Statistical Significance Testing: </i>
          </b>
          Statistics is used to test if the assumptions occur by chance or for
          particular reasons. It helps in ruling out random factors causing an
          observation.
        </li>{" "}
        <li>
          <b>
            <i>Prove or Disprove Relational Statement: </i>
          </b>
          Null-hypothesis proves that there is no relation between two
          variables; thus, it is used for relational inference.
        </li>{" "}
        <li>
          <b>
            <i>Facilitates Alternate Hypothesis Testing: </i>
          </b>
          The alternate hypothesis is just one side of the coin. The
          null-hypothesis is essential for finding and validating its result.
        </li>{" "}
        <li>
          <b>
            <i>Confidence Interval: </i>
          </b>
          It reflects the same underlying statistical reasoning.
        </li>{" "}
        <li>
          <b>
            <i>Applicable in Different Fields of Study: </i>
          </b>
          Whether research, science, psychology, statistics, or investment,
          everything requires hypothesis testing.
        </li>{" "}
        </ul>
      </div>
      <div id="Limitations" className="section">
        <h2> Statistical Limitations</h2>
        <p>
          The null-hypothesis is based on analysis; therefore, interpretation is
          critical. Unfortunately, it can be easily misinterpreted and
          manipulated. In most cases, the significance testing is usually
          conducted to get rejected; thus, the results often come out false.{" "}
        </p>{" "}
        <p>
          Another significant issue is selecting an appropriate sample size for
          finding the probability or mean. A small sample size fails to provide
          accurate results. Similarly, a huge sample complicates the
          calculation.
        </p>
      </div>
      <div id="Null Hypothesis vs. Alternate Hypothesis" className="section">
        <h2>Null Hypothesis vs. Alternate Hypothesis</h2>
        <p>
          Null-hypothesis refers to a statistical approach where the sample
          value deems to be the same as the population data. In this condition,
          their statistical significance lies somewhere within the confidence
          level. In contrast, for an alternate hypothesis, the sample value
          differs from the population data. In such a case, the statistical
          significance of these two values does not fall within the confidence
          level.
        </p>{" "}
        <p>
          The null-hypothesis signifies the possibility of observations being
          caused by chance or random factors. In contrast, the alternate
          hypothesis highlights that the observations were caused by specific
          reasons.
        </p>
      </div>
      <div id="Recommended Topics" className="section">
        <h2>Recommended Tutorials</h2>
        <p>
          This article has been a guide to what is Stochastic Process & its
          meaning. Here, we explain it in detail with its types, applications,
          and examples. You may also find some useful articles here –
        </p>
        <p>
          <h2><a href="/category/Statistics">Statistics</a></h2>
        </p>
        <p>
            <h2><a href="/Time Series">Time Serie</a></h2>
        </p>
        <p>
            <h2><a href="/statistics/Mean">Mean</a></h2>
        </p>
      </div>
    </div>
  );
};

export default Nullhypothesis;

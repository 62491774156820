import React from "react";

const Certificat = () => {
  return (
    <div id="Recommended Topics" className="section">
      <h2>
        <i>Awards & Certifications</i>
      </h2>
      <p>
        {/* I am Nafisat Yusuf, a statistician with extensive experience in academic
        research and consulting projects. A graduate of Statistics from Kano
        University of Science and Technology, Wudil, I was one of the brightest
        female student in the department of Statistics in my time, and I am also
        a member of the Nigerian Statistical Association and have contributed to
        girls' child development in northern Nigeria. My most recent work as an
        author is on the performance of “MCD” and “MRCD” at different thresholds
        in the detection of outliers using the R statistical package. */}
      </p>{" "}
      <ul>
        <li>
        <h2>
          <i>Award Certificate of Social Excellence and Patriotism</i>
        </h2>
        <p>
          <b>Awarded by: </b>
          <i>
            Army Day "Girls" Secondary School, Bukavu Barracks, Kano, June.
            2022.
          </i>
        </p></li>
        <li>
        <h2>
          <i>Merituos Leadership Award.</i>
        </h2>
        <p>
          <b>Awarded by: </b>
          <i>Lubehouse Nigeria, November. 2022.</i>
        </p></li>
        <li>
        <h2>
          <i>Certificate of Meritorious service</i>
        </h2>
        <p>
          <b>Awarded by: </b>
          <i>NYSC Kaduna State chapter, August. 2021.</i>
        </p></li>
        <li>
        <h2>
          <i>Award for Best Performing student</i>
        </h2>
        <p>
          <b>Awarded by: </b>
          <i>
            Department of Statistics, Kano University of Science and Technology,
            wudil, Kano State, session 2018/2019.
          </i>
        </p></li>
        <li>
        <h2>
          <i>Award Certificate of Honor</i>
        </h2>
        <p>
          <b>Awarded by: </b>
          <i>
            {" "}
            Yoruba Students Association, Kano University of Science and
            Technology, wudil, Kano State, December. 2019.
          </i>
        </p></li>
        <li>
        <h2>
          <i>Award for Best Student</i>
        </h2>
        <p>
          <b>Awarded by: </b>
          <i>
            {" "}
            Department of Statistics, Kano University of Science and Technology,
            wudil, Kano State, session 2017/2018.
          </i>
        </p></li>
      </ul>
    </div>
  );
};

export default Certificat;

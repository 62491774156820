import React from 'react'
import { Link } from 'react-router-dom';
import { MDBTable, MDBTableHead, MDBTableBody } from 'mdb-react-ui-kit';


const Binomial = () => {
  return (
    <div>
      <h3> Binomial Distribution </h3>
      <div>
      <Link to="/downloads">download binomial table</Link>
      </div>
      
      <p>B(x;n,p) = SUM b(y;n,p)</p>
    <MDBTable>
      <MDBTableHead>
        <tr>
          <th scope='col'>n</th>
          <th scope='col'>x</th>
          <th scope='col'>0.05</th>
          <th scope='col'>0.10</th>
          <th scope='col'>0.15</th>
          <th scope='col'>0.20</th>
          <th scope='col'>0.25</th>
          <th scope='col'>0.30</th>
          <th scope='col'>0.35</th>
          <th scope='col'>0.40</th>
          <th scope='col'>0.45</th>
          <th scope='col'>0.50</th>
        </tr>
      </MDBTableHead>
      <MDBTableBody>
        <tr>
          <th scope='row'>5</th>
          <td>0</td>
          <td>0.774</td>
          <td>0.590</td>
          <td>0.444</td>
          <td>0.328</td>
          <td>0.237</td>
          <td>0.168</td>
          <td>0.116</td>
          <td>0.078</td>
          <td>0.050</td>
          <td>0.031</td>
        </tr>

        <tr className='table-primary'>
          <th scope='row'>5</th>
          <td>1</td>
          <td>0.977</td>
          <td>0.919</td>
          <td>0.835</td>
          <td>0.737</td>
          <td>0.633</td>
          <td>0.528</td>
          <td>0.428</td>
          <td>0.337</td>
          <td>0.256</td>
          <td>0.188</td>
        </tr>
        <tr className='table-secondary'>
          <th scope='row'>5</th>
          <td>2</td>
          <td>0.999</td>
          <td>0.991</td>
          <td>0.973</td>
          <td>0.942</td>
          <td>0.896</td>
          <td>0.837</td>
          <td>0.765</td>
          <td>0.683</td>
          <td>0.593</td>
          <td>0.500</td>
        </tr>
        <tr className='table-success'>
          <th scope='row'>5</th>
          <td>3</td>
          <td>1.000</td>
          <td>1.000</td>
          <td>0.998</td>
          <td>0.993</td>
          <td>0.984</td>
          <td>0.969</td>
          <td>0.946</td>
          <td>0.913</td>
          <td>0.869</td>
          <td>0.813</td>
        </tr>
        <tr className='table-danger'>
          <th scope='row'>5</th>
          <td>4</td>
          <td>1.000</td>
          <td>1.000</td>
          <td>1.000</td>
          <td>1.000</td>
          <td>0.999</td>
          <td>0.998</td>
          <td>0.995</td>
          <td>0.990</td>
          <td>0.982</td>
          <td>0.969</td>
        </tr>
        <tr className='table-secondary'>
          <th scope='row'>5</th>
          <td>5</td>
          <td>1.000</td>
          <td>1.000</td>
          <td>1.000</td>
          <td>1.000</td>
          <td>1.000</td>
          <td>1.000</td>
          <td>1.000</td>
          <td>1.000</td>
          <td>1.000</td>
          <td>1.000</td>
        </tr>


        <tr>
          <th scope='row'>10</th>
          <td>0</td>
          <td>0.599</td>
          <td>0.349</td>
          <td>0.197</td>
          <td>0.107</td>
          <td>0.056</td>
          <td>0.028</td>
          <td>0.013</td>
          <td>0.006</td>
          <td>0.003</td>
          <td>0.001</td>
        </tr>

        <tr className='table-primary'>
          <th scope='row'>10</th>
          <td>1</td>
          <td>0.914</td>
          <td>0.736</td>
          <td>0.544</td>
          <td>0.376</td>
          <td>0.244</td>
          <td>0.149</td>
          <td>0.086</td>
          <td>0.046</td>
          <td>0.023</td>
          <td>0.011</td>
        </tr>
        <tr className='table-secondary'>
          <th scope='row'>10</th>
          <td>2</td>
          <td>0.988</td>
          <td>0.930</td>
          <td>0.820</td>
          <td>0.678</td>
          <td>0.526</td>
          <td>0.383</td>
          <td>0.262</td>
          <td>0.167</td>
          <td>0.100</td>
          <td>0.055</td>
        </tr>
        <tr className='table-success'>
          <th scope='row'>10</th>
          <td>3</td>
          <td>0.999</td>
          <td>0.987</td>
          <td>0.950</td>
          <td>0.879</td>
          <td>0.776</td>
          <td>0.650</td>
          <td>0.514</td>
          <td>0.382</td>
          <td>0.266</td>
          <td>0.172</td>
        </tr>
        <tr className='table-danger'>
          <th scope='row'>10</th>
          <td>4</td>
          <td>1.000</td>
          <td>0.998</td>
          <td>0.990</td>
          <td>0.967</td>
          <td>0.922</td>
          <td>0.850</td>
          <td>0.751</td>
          <td>0.633</td>
          <td>0.504</td>
          <td>0.377</td>
        </tr>
        <tr className='table-success'>
          <th scope='row'>10</th>
          <td>5</td>
          <td>1.000</td>
          <td>1.000</td>
          <td>0.999</td>
          <td>0.994</td>
          <td>0.980</td>
          <td>0.953</td>
          <td>0.905</td>
          <td>0.834</td>
          <td>0.738</td>
          <td>0.623</td>
        </tr>

        <tr className='table-primary'>
          <th scope='row'>10</th>
          <td>6</td>
          <td>1.000</td>
          <td>1.000</td>
          <td>1.000</td>
          <td>0.999</td>
          <td>0.996</td>
          <td>0.989</td>
          <td>0.974</td>
          <td>0.945</td>
          <td>0.898</td>
          <td>0.828</td>
        </tr>
        <tr className='table-secondary'>
          <th scope='row'>10</th>
          <td>7</td>
          <td>1.000</td>
          <td>1.000</td>
          <td>1.000</td>
          <td>1.000</td>
          <td>1.000</td>
          <td>0.998</td>
          <td>0.995</td>
          <td>0.988</td>
          <td>0.973</td>
          <td>0.945</td>
        </tr>
        <tr className='table-success'>
          <th scope='row'>10</th>
          <td>8</td>
          <td>1.000</td>
          <td>1.000</td>
          <td>1.000</td>
          <td>1.000</td>
          <td>1.000</td>
          <td>1.000</td>
          <td>0.999</td>
          <td>0.998</td>
          <td>0.995</td>
          <td>0.989</td>
        </tr>
        <tr className='table-primary'>
          <th scope='row'>10</th>
          <td>9</td>
          <td>1.000</td>
          <td>1.000</td>
          <td>1.000</td>
          <td>1.000</td>
          <td>1.000</td>
          <td>1.000</td>
          <td>1.000</td>
          <td>1.000</td>
          <td>1.000</td>
          <td>0.999</td>
        </tr>
        <tr className='table-secondary'>
          <th scope='row'>10</th>
          <td>10</td>
          <td>1.000</td>
          <td>1.000</td>
          <td>1.000</td>
          <td>1.000</td>
          <td>1.000</td>
          <td>1.000</td>
          <td>1.000</td>
          <td>1.000</td>
          <td>1.000</td>
          <td>1.000</td>
        </tr>


        <tr>
          <th scope='row'>15</th>
          <td>0</td>
          <td>0.463</td>
          <td>0.206</td>
          <td>0.087</td>
          <td>0.035</td>
          <td>0.013</td>
          <td>0.005</td>
          <td>0.002</td>
          <td>0.000</td>
          <td>0.000</td>
          <td>0.000</td>
        </tr>

        <tr className='table-primary'>
          <th scope='row'>15</th>
          <td>1</td>
          <td>0.829</td>
          <td>0.549</td>
          <td>0.319</td>
          <td>0.167</td>
          <td>0.080</td>
          <td>0.035</td>
          <td>0.014</td>
          <td>0.005</td>
          <td>0.002</td>
          <td>0.000</td>
        </tr>
        <tr className='table-secondary'>
          <th scope='row'>15</th>
          <td>2</td>
          <td>0.964</td>
          <td>0.816</td>
          <td>0.604</td>
          <td>0.398</td>
          <td>0.236</td>
          <td>0.127</td>
          <td>0.062</td>
          <td>0.027</td>
          <td>0.011</td>
          <td>0.004</td>
        </tr>
        <tr className='table-success'>
          <th scope='row'>15</th>
          <td>3</td>
          <td>0.995</td>
          <td>0.944</td>
          <td>0.823</td>
          <td>0.648</td>
          <td>0.461</td>
          <td>0.297</td>
          <td>0.173</td>
          <td>0.091</td>
          <td>0.042</td>
          <td>0.018</td>
        </tr>
        <tr className='table-danger'>
          <th scope='row'>15</th>
          <td>4</td>
          <td>0.999</td>
          <td>0.987</td>
          <td>0.938</td>
          <td>0.836</td>
          <td>0.686</td>
          <td>0.515</td>
          <td>0.352</td>
          <td>0.217</td>
          <td>0.120</td>
          <td>0.059</td>
        </tr>
        <tr className='table-secondary'>
          <th scope='row'>15</th>
          <td>5</td>
          <td>1.000</td>
          <td>0.998</td>
          <td>0.983</td>
          <td>0.939</td>
          <td>0.852</td>
          <td>0.722</td>
          <td>0.564</td>
          <td>0.403</td>
          <td>0.261</td>
          <td>0.151</td>
        </tr>
        <tr className='table-success'>
          <th scope='row'>15</th>
          <td>6</td>
          <td>1.000</td>
          <td>1.000</td>
          <td>0.996</td>
          <td>0.982</td>
          <td>0.943</td>
          <td>0.869</td>
          <td>0.755</td>
          <td>0.610</td>
          <td>0.452</td>
          <td>0.304</td>
        </tr>

        <tr className='table-primary'>
          <th scope='row'>15</th>
          <td>7</td>
          <td>1.000</td>
          <td>1.000</td>
          <td>0.999</td>
          <td>0.996</td>
          <td>0.983</td>
          <td>0.950</td>
          <td>0.887</td>
          <td>0.787</td>
          <td>0.654</td>
          <td>0.500</td>
        </tr>
        <tr className='table-secondary'>
          <th scope='row'>15</th>
          <td>8</td>
          <td>1.000</td>
          <td>1.000</td>
          <td>1.000</td>
          <td>0.999</td>
          <td>0.996</td>
          <td>0.985</td>
          <td>0.958</td>
          <td>0.905</td>
          <td>0.818</td>
          <td>0.696</td>
        </tr>
        <tr className='table-success'>
          <th scope='row'>15</th>
          <td>9</td>
          <td>1.000</td>
          <td>1.000</td>
          <td>1.000</td>
          <td>1.000</td>
          <td>0.999</td>
          <td>0.996</td>
          <td>0.988</td>
          <td>0.966</td>
          <td>0.923</td>
          <td>0.849</td>
        </tr>
        <tr className='table-danger'>
          <th scope='row'>15</th>
          <td>10</td>
          <td>1.000</td>
          <td>1.000</td>
          <td>1.000</td>
          <td>1.000</td>
          <td>1.000</td>
          <td>0.999</td>
          <td>0.997</td>
          <td>0.991</td>
          <td>0.975</td>
          <td>0.941</td>
          
        </tr>
        <tr className='table-secondary'>
          <th scope='row'>15</th>
          <td>11</td>
          <td>1.000</td>
          <td>1.000</td>
          <td>1.000</td>
          <td>1.000</td>
          <td>1.000</td>
          <td>1.000</td>
          <td>1.000</td>
          <td>0.998</td>
          <td>0.994</td>
          <td>0.982</td>
        </tr>
        <tr className='table-success'>
          <th scope='row'>15</th>
          <td>12</td>
          <td>1.000</td>
          <td>1.000</td>
          <td>1.000</td>
          <td>1.000</td>
          <td>1.000</td>
          <td>1.000</td>
          <td>1.000</td>
          <td>1.000</td>
          <td>0.999</td>
          <td>0.996</td>
        </tr>

        <tr className='table-primary'>
          <th scope='row'>15</th>
          <td>13</td>
          <td>1.000</td>
          <td>1.000</td>
          <td>1.000</td>
          <td>1.000</td>
          <td>1.000</td>
          <td>1.000</td>
          <td>1.000</td>
          <td>1.000</td>
          <td>1.000</td>
          <td>1.000</td>
        </tr>
        <tr className='table-secondary'>
          <th scope='row'>15</th>
          <td>14</td>
          <td>1.000</td>
          <td>1.000</td>
          <td>1.000</td>
          <td>1.000</td>
          <td>1.000</td>
          <td>1.000</td>
          <td>1.000</td>
          <td>1.000</td>
          <td>1.000</td>
          <td>1.000</td>
        </tr>
        <tr className='table-success'>
          <th scope='row'>15</th>
          <td>15</td>
          <td>1.000</td>
          <td>1.000</td>
          <td>1.000</td>
          <td>1.000</td>
          <td>1.000</td>
          <td>1.000</td>
          <td>1.000</td>
          <td>1.000</td>
          <td>1.000</td>
          <td>1.000</td>
        </tr>
      </MDBTableBody>
    </MDBTable>
  </div>  
  );
}

export default Binomial
   

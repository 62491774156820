import React from "react";
import "./Population.css";
import kurt from "./img/kurt.png";
import gets2 from "./img/gets2.png";
import gets4 from "./img/gets4.png";
import moment from "./img/moment.png";
import form from "./img/form.png";

const kurtosis = () => {
  return (
    <div>
      <div className="table-of-contents">
        <h2>Table of Contents</h2>
        <ul>
          <li>
            <a href="#What Is Kurtosis?">What Is Kurtosis?</a>
          </li>
          <li>
            <a href="#Understanding Kurtosis">Understanding Kurtosis</a>
          </li>
          <li>
            <a href="#Formula and Calculation of Kurtosis">
              Formula and Calculation of Kurtosis
            </a>
          </li>
          <li>
            <a href="#Types of Kurtosis">Types of Kurtosis</a>
          </li>
          <li>
            <a href="#Using Kurtosis">Using Kurtosis</a>
          </li>
          <li>
            <a href="#Kurtosis vs. Other Commonly Used Measurements">
              Kurtosis vs. Other Commonly Used Measurements
            </a>
          </li>
          <li>
            <a href="#Example">Example</a>
          </li>
        </ul>
      </div>
      <p>
        <i>
          <b>Backend from</b>: investopedia.com
        </i>
      </p>
      <div id="What Is Kurtosis?" className="section">
        <h1>Kurtosis Definition, Types, and Importance</h1>
        <h2>What Is Kurtosis?</h2>
        <p>
          Kurtosis is a statistical measure used to describe a characteristic of
          a dataset. When normally distributed data is plotted on a graph, it
          generally takes the form of a bell. This is called the bell curve. The
          plotted data that are furthest from the mean of the data usually form
          the tails on each side of the curve. Kurtosis indicates how much data
          resides in the tails.{" "}
        </p>
        <p>
          Distributions with a large kurtosis have more tail data than normally
          distributed data, which appears to bring the tails in toward the mean.
          Distributions with low kurtosis have fewer tail data, which appears to
          push the tails of the bell curve away from the mean.{" "}
        </p>
        <p>
          For investors, high kurtosis of the return distribution curve implies
          that there have been many price fluctuations in the past (positive or
          negative) away from the average returns for the investment. So, an
          investor might experience extreme price fluctuations with an
          investment with high kurtosis. This phenomenon is known as kurtosis
          risk.{" "}
        </p>

        <p>
          {" "}
          <h5>
            <i>key Points;</i>
          </h5>
          <ul>
            <li>
              Kurtosis describes the "fatness" of the tails found in probability
              distributions.{" "}
            </li>

            <li>
              There are three kurtosis categories—mesokurtic (normal),
              platykurtic (less than normal), and leptokurtic (more than
              normal).
            </li>
            <li>
              Kurtosis risk is a measurement of how often an investment's price
              moves dramatically.
            </li>
            <li>
              A curve's kurtosis characteristic tells you how much kurtosis risk
              the investment you're evaluating has.{" "}
            </li>
          </ul>
        </p>
      </div>
      <div id="Understanding Kurtosis" className="section">
        <h2>Understanding Kurtosis</h2>
        <p>
          Kurtosis is a measure of the combined weight of a distribution's tails
          relative to the center of the distribution curve (the mean). For
          example, when a set of approximately normal data is graphed via a
          histogram, it shows a bell peak, with most of the data residing within
          three standard deviations (plus or minus) of the mean. However, when
          high kurtosis is present, the tails extend farther than the three
          standard deviations of the normal bell-curved distribution.{" "}
        </p>
        <p>
          Kurtosis is sometimes confused with a measure of the peakedness of a
          distribution. However, kurtosis is a measure that describes the shape
          of a distribution's tails in relation to its overall shape. A
          distribution can be sharply peaked with low kurtosis, and a
          distribution can have a lower peak with high kurtosis. Thus, kurtosis
          measures "tailedness," not "peakedness."{" "}
        </p>
      </div>
      <div id="Formula and Calculation of Kurtosis" className="section">
        <h2>Formula and Calculation of Kurtosis</h2>
        <p>
          <h3>Calculating With Spreadsheets</h3> There are several different
          methods for calculating kurtosis. The simplest way is to use the Excel
          or Google Sheets formula. For instance, assume you have the following
          sample data: 4, 5, 6, 3, 4, 5, 6, 7, 5, and 8 residing in cells A1
          through A10 on your spreadsheet. The spreadsheets use this formula for
          calculating kurtosis:
        </p>{" "}
        <p>
          {/* image example here */}
          <img className="img_img" alt="kurt" src={kurt} />
        </p>{" "}
        <p>
          However, we'll use the following formula in Google Sheets, which
          calculates it for us, assuming the data resides in cells A1 through
          A10: <h3>=KURT(A1:A10)</h3>
        </p>{" "}
        <p>
          The result is a kurtosis of -0.1518, indicating the curve has lighter
          tails and is platykurtic.
        </p>
        <p>
          <h3>Solving Kurtosis by using calculator</h3> Calculating kurtosis by
          hand is a lengthy endeavor, and takes several steps to get to the
          results. We'll use new data points and limit their number to simplify
          the calculation. The new data points are 27, 13, 17, 57, 113, and 25.
        </p>{" "}
        <p>
          First, you need to calculate the mean. Add up the numbers and divide
          by six to get 42. Next, use the following formulas to calculate two
          sums, s2 (the square of the deviation from the mean) and s4 (the
          square of the deviation from the mean squared). Note—these numbers do
          not represent standard deviation; they represent the variance of each
          data point.
        </p>{" "}
        <p>
          <b>
            <h1>s2=∑(yi​−yˉ​)2</h1>
            <h1>s4=∑(yi​−yˉ​)4</h1>
          </b>
        </p>
        <p>
          <h2>where:</h2>
          <h3> yi​=ith variable of the sample</h3>
          <h3> yˉ​=Mean of the sample​</h3>
        </p>{" "}
        <p>
          {/* image example here */}
          <img className="img_img" alt="gets2" src={gets2} />
        </p>{" "}
        <p>
          {/* image example here */}
          <img className="img_img" alt="gets4" src={gets4} />
        </p>{" "}
        <p>
          Now, calculate m2 and m4, the second and fourth moments of the
          kurtosis formula:
        </p>
        <p>
          {/* image example here */}
          <img className="img_img" alt="moment" src={moment} />
        </p>{" "}
        <p>
          We can now calculate kurtosis using a formula found in many statistics
          textbooks that assumes a perfectly normal distribution with kurtosis
          of zero:
        </p>
        <p>
          {/* image example here */}
          <img className="img_img" alt="formt" src={form} />
        </p>{" "}
      </div>
      <div id="Types of Kurtosis" className="section">
        <h2>Types of Kurtosis</h2>
        <p>
          There are three categories of kurtosis that a set of data can
          display—mesokurtic, leptokurtic, and platykurtic. All measures of
          kurtosis are compared against a normal distribution curve.
        </p>
        <img
          className="img_img"
          alt="/"
          src="https://www.investopedia.com/thmb/c44qXYOk2W9AEdPsiRySkjPgIXw=/750x0/filters:no_upscale():max_bytes(150000):strip_icc():format(webp)/Clipboard01-8bd7469732784d998c4059c2fd898489.jpg"
        />
        <p>
          {" "}
          <h3>Mesokurtic (Kurtosis = 3.0)</h3>A distribution is positively
          skewed when its The first category of kurtosis is mesokurtic
          distribution. This distribution has a kurtosis similar to that of the
          normal distribution, meaning the extreme value characteristic of the
          distribution is similar to that of a normal distribution. Therefore, a
          stock with a mesokurtic distribution generally depicts a moderate
          level of risk.
        </p>{" "}
        <p>
          {" "}
          <h3>Leptokurtic (Kurtosis greater than 3.0)</h3>
          The second category is leptokurtic distribution. Any distribution that
          is leptokurtic displays greater kurtosis than a mesokurtic
          distribution. This distribution appears as a curve one with long tails
          (outliers.) The "skinniness" of a leptokurtic distribution is a
          consequence of the outliers, which stretch the horizontal axis of the
          histogram graph, making the bulk of the data appear in a narrow
          ("skinny") vertical range.
        </p>{" "}
        <p>
          <h3>Platykurtic (Kurtosis less than 3.0)</h3>
          The final type of distribution is platykurtic distribution. These
          types of distributions have short tails (fewer outliers.). Platykurtic
          distributions have demonstrated more stability than other curves
          because extreme price movements rarely occurred in the past. This
          translates into a less-than-moderate level of risk.
        </p>
      </div>
      <div id="Using Kurtosis" className="section">
        <h2>Using Kurtosis</h2>
        <p>
          Kurtosis is used in financial analysis to measure an investment's risk
          of price volatility. Kurtosis measures the amount of volatility an
          investment's price has experienced regularly. High Kurtosis of the
          return distribution implies that an investment will yield occasional
          extreme returns. Be mindful that this can swing both ways, meaning
          high kurtosis indicates either large positive returns or extreme
          negative returns.
        </p>
        <p>
          {" "}
          For example, imagine a stock had an average price of $25.85 per share.
          If the stock's price swung widely and often enough, the bell curve
          would have heavy tails (high kurtosis). This means that there is a lot
          of variation in the stock price—an investor should anticipate wide
          price swings often.
        </p>{" "}
        <p>
          {" "}
          On the other hand, a portfolio with a low kurtosis value indicates a
          more stable and predictable return profile, which may indicate lower
          risk. In this light, investors may intentionally seek investments with
          lower kurtosis values when building safer, less volatile portfolios.
        </p>{" "}
        <p>
          {" "}
          Kurtosis can also be used to strategically implement an investment
          allocation approach. For example, a portfolio manager who specializes
          in value investing may prefer to invest in assets with a negative
          kurtosis value, as a negative kurtosis value indicates a flatter
          distribution with more frequent small returns. Conversely, a portfolio
          manager who specializes in momentum investing may prefer to invest in
          assets with a positive kurtosis value with peaked distributions of
          less frequent but larger returns.
        </p>{" "}
      </div>
      <div
        id="Kurtosis vs. Other Commonly Used Measurements"
        className="section"
      >
        <h2>Kurtosis vs. Other Commonly Used Measurements</h2>
        <p>
          Kurtosis risk differs from more commonly used measurements. Alpha
          measures excess return relative to a benchmark index. While kurtosis
          measures the nature of the peak or flatness of the distribution, alpha
          measures the skewness or asymmetry of the distribution
        </p>
        <p>
          Beta measures the volatility a stock compared to the broader market.
          Each security or investment has a single beta that indicates whether
          or not that security is more or less volatile compared to a market
          benchmark. Again, beta measures the degree of asymmetry of the
          distribution, while kurtosis measures the peak or flatness of the
          distribution.
        </p>
        <p>
          R-squared measures the percent of movement a portfolio or fund has
          that can be explained by a benchmark. Though r-squared is used in
          regression analysis to assess the goodness of fit of a regression
          model, kurtosis is used in descriptive statistics to describe the
          shape of a distribution.
        </p>
        <p>
          Last, the Sharpe ratio compares return to risk. The Sharpe ratio is
          used by investors to better understand whether the level of returns
          they are receiving are commiserate with the level of risk incurred.
          While kurtosis analyzes the distribution of a dataset, the Sharpe
          ratio more commonly is used to evaluate investment performance.
        </p>
      </div>
     
    </div>
  );
};

export default kurtosis;

import React from 'react'

const Typei = () => {
    return (
        <div>
          <div className="table-of-contents">
            <h2>Table of Contents</h2>
            <ul>
              <li>
                <a href="#What Is a Type I Error?">What Is a Type I Error?</a>
              </li>
              <li>
                <a href="#How It Works">How It Works</a>
              </li>
              <li>
                <a href="#False Positive">False Positive</a>
              </li>
              <li>
                <a href="#Examples">Examples</a>
              </li>
              {/* <li>
                    <a href="#Understanding Statistical Data">
                      Understanding Statistical Data
                    </a>
                  </li>
                  <li>
                    <a href="#Levels of Measurement">Levels of Measurement</a>
                  </li>
                  <li>
                    <a href="#Sampling Techniques">Sampling Techniques</a>
                  </li> */}
              {/* <li>
                    <a href="#Uses of Statistics">Uses of Statistics</a>
                  </li> */}
            </ul>
          </div>
          <p>
            <i>
              <b>Backend from</b>: investopedia.com
            </i>
          </p>
          <div id="What Is a Type I Error?" className="section">
            <h1>Type 1 Error: Definition, False Positives, and Examples</h1>
            <h2>What Is a Type I Error?</h2>
            <p>
              The term type I error is a statistical concept that refers to the
              incorrect rejection of an accurate null hypothesis. Put simply, a type
              I error is a false positive result. Making a type I error often can't
              be avoided because of the degree of uncertainty involved. A null
              hypothesis is established during hypothesis testing before a test
              begins. In some cases, a type I error assumes there's no
              cause-and-effect relationship between the tested item and the stimuli
              to trigger an outcome to the test.{" "}
            </p>
    
            <p>
              {" "}
              <h5>
                <i>key Points;</i>
              </h5>
              <ul>
                <li>
                  A type I error occurs during hypothesis testing when a null
                  hypothesis is rejected, even though it is accurate and should not
                  be rejected.{" "}
                </li>
    
                <li>
                  Hypothesis testing is a testing process that uses sample data.
                </li>
                <li>
                  The null hypothesis assumes no cause-and-effect relationship
                  between the tested item and the stimuli applied during the test.
                </li>
                <li>
                  A type I error is a false positive leading to an incorrect
                  rejection of the null hypothesis.{" "}
                </li>
                <li>
                  A false positive can occur If something other than the stimuli
                  causes the outcome of the test.
                </li>
              </ul>
            </p>
          </div>
          <div id="How It Works" className="section">
            <h2>How a Type I Error Works</h2>
            <p>
              Hypothesis testing is a testing process that uses sample data. The
              test is designed to provide evidence that the hypothesis or conjecture
              is supported by the data being tested. A null hypothesis is a belief
              that there is no statistical significance or effect between the two
              data sets, variables, or populations being considered in the
              hypothesis. A researcher would generally try to disprove the null
              hypothesis.{" "}
            </p>
            <p>
              For example, let's say the null hypothesis states that an investment
              strategy doesn't perform any better than a market index like the S&P
              500. The researcher would take samples of data and test the historical
              performance of the investment strategy to determine if the strategy
              performed at a higher level than the S&P. If the test results show
              that the strategy performed at a higher rate than the index, the null
              hypothesis is rejected.{" "}
            </p>
            <p>
              {" "}
              This condition is denoted as n=0. If the result seems to indicate that
              the stimuli applied to the test subject caused a reaction when the
              test is conducted, the null hypothesis stating that the stimuli do not
              affect the test subject then needs to be rejected.
            </p>
          </div>
          <div id="False Positive" className="section">
            <h2>False Positive Type I Error</h2>
            <p>
              A type I error is also called a false positive result. This result
              leads to an incorrect rejection of the null hypothesis. It rejects an
              idea that shouldn't have been rejected in the first place. Rejecting
              the null hypothesis under the assumption that there is no relationship
              between the test subject, the stimuli, and the outcome may sometimes
              be incorrect. If something other than the stimuli causes the outcome
              of the test, it can cause a false positive result.{" "}
            </p>
          </div>
          <div id="Examples" className="section">
            <h2>Examples of Type I Errors</h2>
            <p>
              Let's look at a couple of hypothetical examples to show how type I
              errors occur.
            </p>{" "}
            <h3>Criminal Trials</h3>
            <p>
              Type I errors commonly occur in criminal trials, where juries are
              required to come up with a verdict of either innocent or guilty. In
              this case, the null hypothesis is that the person is innocent, while
              the alternative is guilty. A jury may come up with a type I error if
              the members find that the person is found guilty and is sent to jail,
              despite actually being innocent.
            </p>{" "}
            <h3>Medical Testing</h3>
            <p>
              In medical testing, a type I error would cause the appearance that a
              treatment for a disease has the effect of reducing the severity of the
              disease when, in fact, it does not. When a new medicine is being
              tested, the null hypothesis will be that the medicine does not affect
              the progression of the disease. Let's say a lab is researching a new
              cancer drug. Their null hypothesis might be that the drug does not
              affect the growth rate of cancer cells.
            </p>{" "}
            <p>
              After applying the drug to the cancer cells, the cancer cells stop
              growing. This would cause the researchers to reject their null
              hypothesis that the drug would have no effect. If the drug caused the
              growth stoppage, the conclusion to reject the null, in this case,
              would be correct. However, if something else during the test caused
              the growth stoppage instead of the administered drug, this would be an
              example of an incorrect rejection of the null hypothesis (i.e., a type
              I error).
            </p>
          </div>
        </div>
      );
}

export default Typei
import React from "react";
import "./Stats.css";

const Biostats = () => {
  return (
    <div>
      <div className="table-of-contents">
        <h2>Table of Contents</h2>
        <ul>
          <li>
            <a href="#What Is Biostatistics?">What Is Biostatistics?</a>
          </li>
          <li>
            <a href="#Understanding Biostatistics">
              Understanding Biostatistics
            </a>
          </li>
          <li>
            <a href="#Methods of Data Collection">Methods of Data Collection</a>
          </li>
          <li>
            <a href="#Importance of biostatistics">
              Importance of biostatistics
            </a>
          </li>
          <li>
            <a href="#Applications of biostatistics">
              Applications of biostatistics
            </a>
          </li>
          {/* <li>
            <a href="#Levels of Measurement">Levels of Measurement</a>
          </li>
          <li>
            <a href="#Sampling Techniques">Sampling Techniques</a>
          </li> */}
          {/* <li>
            <a href="#Uses of Statistics">Uses of Statistics</a>
          </li> */}
        </ul>
      </div>
      <p>
        <i>
          <b>Backend from</b>:
          https://sg.indeed.com/career-advice/career-development/biostatistics
        </i>
      </p>
      <div id="What Is Biostatistics?" className="section">
        <h1>Biostatistics: Definition, Importance, and Applications</h1>
        <h2>What Is Biostatistics?</h2>
        <p>
          Biostatistics or biometry is a branch of biological science concerned
          with the study and methods for collecting, presenting, analysing and
          interpreting biological research data. The primary aim of this branch
          of science is to allow researchers, health care providers and public
          health administrators to make decisions concerning a population using
          sample data. For example, the government wants to know the prevalence
          of a specific health problem amongst dock workers. If there are
          200,000 dock workers, it may not be realistic to test them
          individually and determine whether they have the disease or are
          susceptible to it.{" "}
        </p>
        <p>
          A realistic and cost-effective approach is to study a representative
          subset of the population and apply their results to the entire group.
          This discipline makes research possible by providing tools and
          techniques for collecting, analysing and interpreting biological and
          medical data, allowing stakeholders to draw actionable insights about
          a population from sample data. Biostatisticians usually get their data
          from a wide range of sources, including medical records, peer-reviewed
          literature, claims records, vital records, disease registries,
          surveillance, experiments and surveys. These professionals collaborate
          with scientists, health care providers, public health administrators
          and other stakeholders.
        </p>

        <p>
          {" "}
          <h5>
            <i>key Points;</i>
          </h5>
          <ul>
            <li>
              Statistics is the study and manipulation of data, including ways
              to gather, review, analyze, and draw conclusions from data.{" "}
            </li>

            <li>
              The two major areas of statistics are descriptive and inferential
              statistics.
            </li>
            <li>
              Statistics can be communicated at different levels ranging from
              non-numerical descriptor (nominal-level) to numerical in reference
              to a zero-point (ratio-level).
            </li>
            <li>
              Several sampling techniques can be used to compile statistical
              data, including simple random, systematic, stratified, or cluster
              sampling.{" "}
            </li>
            <li>
              Statistics are present in almost every department of every company
              and are an integral part of investing as well.
            </li>
          </ul>
        </p>
      </div>
      <div id="Understanding Biostatistics" className="section">
        <h2>Understanding Biostatistics</h2>
        <p>
          Understanding the concept of biostatistics by using statistical
          techniques to solve biological problems. The field requires
          professionals to have in-depth knowledge of biology and statistics.
          Here are some important concepts to help you understand how this
          science works:{" "}
        </p>
        <h3>Design of experiments</h3>
        <p>
          The first aspect is the design of experiments. The design of
          experiments is a systematic method of studying the relationship
          between multiple input variables and specific output variables. It
          provides researchers with a structured and efficient method of
          observing phenomena under controlled conditions to collect data and
          make discoveries. A typical design of experiments involves:{" "}
        </p>{" "}
        <ul>
          <li>Setting objectives</li>
          <li>
            Selecting the process variables and detecting{" "}
            <a href="/statistics/outliers">outliers</a>
          </li>
          <li>Selecting an experimental design</li>
          <li>Implementing the experimental design</li>
          <li>
            Checking that collected data is consistent with the experimental
            hypothesis
          </li>
          <li>Analysing and interpreting results</li>
          <li>Using and presenting the results</li>
        </ul>
      </div>
      <div id="Methods of Data Collection" className="section">
        <h2>Methods of Data Collection</h2>
        <p>
          After designing an experiment, the next step is data collection. There
          are several ways researchers can get data for their biological
          research. For example, in public health, researchers can get data from
          these sources:{" "}
        </p>
        <ul>
          <li>
            <b>Medical records:</b> Medical records can provide researchers with
            data about diagnoses, lab tests and procedures common amongst a
            specific population, such as people above 50 years working in the
            police force.
          </li>
          <li>
            <b>Claims data:</b> Scientists can get data about doctor's
            appointments and medical bills in claims data.
          </li>
          <li>
            <b>Vital records:</b> Vital records contain information about
            births, deaths, causes of death and divorces.
          </li>
          <li>
            <b>Peer-reviewed literature:</b> Researchers can also pull data from
            the articles and studies that experts in a particular field
            published in peer-reviewed journals.
          </li>
          <li>
            <b>Surveys:</b> The researchers can collect primary data using
            surveys designed specifically for an experiment.
          </li>
          <li>
            <b>Disease registries:</b> These systems help to collect, store,
            analyse, retrieve and disseminate information regarding people
            living with specific diseases.
          </li>
        </ul>
        <h3>Data classification</h3>
        <p>
          After data collection, the next step is to classify the data. There
          are different types of data classification, depending on the
          characteristics. You can classify data into structured and
          unstructured, primary or secondary or qualitative and quantitative.
          Classifying data is an important step to ensure proper analysis.{" "}
        </p>{" "}
        <h3>Data analysis</h3>
        <p>
          After classifying the data, researchers analyse it. There are several
          methods of data analysis, depending on the size, complexity and number
          of variables. Descriptive analysis is suitable for analysing and
          presenting data, such as mean and median. If you're trying to
          establish a functional relationship between variables, more advanced
          analytical techniques, such as correlation and regression, are ideal.{" "}
        </p>
        <h3>Data interpretation</h3>
        <p>
          {" "}
          The final step is the interpretation of the data. Data interpretation
          involves inferring conclusions from the results of data analysis. This
          exercise allows researchers to categorise, manipulate and summarise
          their findings to answer important questions in public health, biology
          and medicine.
        </p>
      </div>
      <div id="Importance of biostatistics" className="section">
        <h2>Importance of biostatistics</h2>
        <p>
          Biostatistics is an important discipline that can help solve problems
          in the medical, research and pharmaceutical industries. Here are some
          benefits of biostatistical methods:
        </p>{" "}
        <h3>Derive conclusions about populations from samples</h3>
        <p>
          One major importance of biostatistical methods is that they help
          researchers derive far-reaching conclusions about a population from
          samples. Due to several factors, such as finances, size and time
          constraints, it's not always possible for researchers to collect data
          about an entire population when testing assumptions about them.
          Biostatistical methods provide researchers and administrators with the
          tools they require to select a sample that's representative of the
          population, choose the right independent and dependent variables and
          derive logical conclusions from the data.
        </p>{" "}
        <h3>Check drug efficacy</h3>
        <p>
          In the medical and pharmaceutical fields, biostatistical research is
          used to check the efficacy and effectiveness of treatments during
          clinical trials. Researchers can also use it to find possible side
          effects of drugs. These methods are ideal for conducting drug
          treatment trials and performing other experiments to understand the
          impact of different medications and medical devices on the human body.
        </p>{" "}
        <h3>Perform genetics studies</h3>
        <p>
          It's an important discipline in the study of Mendelian genetics.
          Geneticists use it to study the inheritance patterns of genes. They
          also use it to study the genetic structure of a population.
          Researchers also use biometry to map chromosomes and understand the
          behaviour of genes in a population.
        </p>{" "}
        <h3>Study demography</h3>
        <p>
          Demography is the statistical study of the human population. The field
          uses statistical techniques to describe births, deaths, income,
          disease disparity and other structural changes in human populations.
          Using census data, surveys and statistical models, biostatisticians
          can analyse the structure, size and movement of populations, providing
          insights for government agencies, health care administrators, town
          planners and other stakeholders to create and adjust their plans based
          on the dynamics of the population.
        </p>{" "}
      </div>
      <div id="Applications of biostatistics" className="section">
        <h2>Applications of biostatistics</h2>
        <p>
          Biostatistical methods can be applied to conduct research in the areas
          of biology, public health, and medicine, including:
        </p>{" "}
        <h3>Evaluate a programme's impact</h3>
        <p>
          Researchers can use biometric techniques to assess the impact of a
          health programme on the target population. With biometric techniques,
          researchers can use insights from data to:{" "}
        </p>
        <ul>
          <li>measure the performance of public health interventions</li>
          <li>boost immunisation rates</li>
          <li>
            increase the number of patients attending post-surgery appointments
          </li>
          <li>improve training and supervision of health care professionals</li>
        </ul>{" "}
        <h3>Create population-based interventions</h3>
        <p>
          <a href="/biostats/biometrics">Biometrics</a> can also help researchers, health
          care providers and public health administrators to create
          population-based health interventions based on the results of
          biostatistical data analysis and interpretation. These data insights
          can be used to:
        </p>{" "}
        <ul>
          <li>
            identify populations that require interventions to reduce their
            exposure to specific health problems
          </li>
          <li>identify areas susceptible to high risk of certain diseases</li>
          <li>
            identify the factors influencing the high cases of health
            disparities within a population
          </li>
          <li>
            identify members of a population that require the highest level of
            health care
          </li>
        </ul>{" "}
        <h3>Control epidemics</h3>
        <p>
          Biostatistical techniques can also help public health officials,
          health care practitioners and epidemiologists to control epidemics.
          Researchers not only use statistical analysis to understand how
          diseases spread, but they can also use it to determine the mortality
          rate amongst specific populations. It can also help health care
          professionals determine the most at-risk members of the population and
          create a framework for formulating strategies to stop the spread of
          such diseases.
        </p>{" "}
        <h3>Identify barriers to care</h3>
        <p>
          Researchers and health care professionals can use biostatistical
          methods to learn about the barriers preventing people from getting
          access to quality care. They can use surveys to identify the factors
          that limit access to health care. Medical records, interviews and
          claims records can show patient perceptions about health care
          services, providing insights to make such services more accessible and
          acceptable to target populations for higher efficiency.
        </p>{" "}
      </div>
      {/* <div id="Levels of Measurement" className="section">
        <h2> Statistical Levels of Measurement</h2>
        <p>
          There are several resulting levels of measurement after analyzing
          variables and outcomes. Statistics can quantify outcomes in four ways.{" "}
        </p>{" "}
        <ul>
          <li>
            <b>Nominal-level Measurement</b>
          </li>
          <li>
            <b>Ordinal-level Measurement</b>
          </li>
          <li>
            <b>Interval-level Measurement</b>
          </li>
          <li>
            <b>Ratio-level Measurement</b>
          </li>
        </ul>
        <p>
          {" "}
          <h3>Nominal-level Measurement</h3>
          There's no numerical or quantitative value, and qualities are not
          ranked. Nominal-level measurements are instead simply labels or
          categories assigned to other variables. It's easiest to think of
          nominal-level measurements as non-numerical facts about a variable.
        </p>{" "}
        <p>
          <i>
            <b>Example</b>
          </i>
          : The name of the President elected in 2020 was Joseph Robinette
          Biden, Jr.{" "}
        </p>
        <p>
          {" "}
          <h3>Ordinal-level Measurement</h3>
          Outcomes can be arranged in an order, but all data values have the
          same value or weight. Although numerical, ordinal-level measurements
          can't be subtracted against each other in statistics because only the
          position of the data point matters. Ordinal levels are often
          incorporated into nonparametric statistics and compared against the
          total variable group.
        </p>{" "}
        <p>
          <i>
            <b>Example</b>
          </i>
          : American Fred Kerley was the 2nd fastest man at the 2020 Tokyo
          Olympics based on 100-meter sprint times.{" "}
        </p>
        <p>
          {" "}
          <h3>Interval-level Measurement</h3>
          Outcomes can be arranged in order but differences between data values
          may now have meaning. Two data points are often used to compare the
          passing of time or changing conditions within a data set. There is
          often no "starting point" for the range of data values, and calendar
          dates or temperatures may not have a meaningful intrinsic zero value.
        </p>{" "}
        <p>
          <i>
            <b>Example</b>
          </i>
          : Inflation hit 8.6% in May 2022. The last time inflation was this
          high was in December 1981{" "}
        </p>
        <p>
          {" "}
          <h3>Ratio-level Measurement</h3>
          Outcomes can be arranged in order and differences between data values
          now have meaning. But there's a starting point or "zero value" that
          can be used to further provide value to a statistical value. The ratio
          between data values has meaning, including its distance away from
          zero.
        </p>{" "}
        <p>
          <i>
            <b>Example</b>
          </i>
          : The lowest meteorological temperature recorded was -128.6 degrees
          Fahrenheit in Antarctica{" "}
        </p>
      </div>
      <div id="Sampling Techniques" className="section">
        <h2>Sampling Techniques</h2>
        <p>
          It would often not be possible to gather data from every data point
          within a population to gather statistical information. Statistics
          relies instead on different sampling techniques to create a
          representative subset of the population that's easier to analyze. In
          statistics, there are several primary types of sampling in statistics.
        </p>{" "}
        <ul>
          <li>
            <b>Simple Random Sampling</b>
          </li>
          <li>
            <b>Systemic Sampling</b>
          </li>
          <li>
            <b>Stratified Sampling</b>
          </li>
          <li>
            <b>Cluster Sampling</b>
          </li>
        </ul>
        <p>
          {" "}
          <h3>Simple Random Sampling</h3>
          <a href="/Simple random sampling">Simple random sampling</a> calls for
          every member within the population to have an equal chance of being
          selected for analysis. The entire population is used as the basis for
          sampling, and any random generator based on chance can select the
          sample items. For example, 100 individuals are lined up and 10 are
          chosen at random.
        </p>{" "}
        <p>
          {" "}
          <h3>Systemic Sampling</h3>
          <a href="/Systematic sampling">Systematic sampling</a> calls for a
          random sample as well, but its technique is slightly modified to make
          it easier to conduct. A single random number is generated and
          individuals are then selected at a specified regular interval until
          the sample size is complete. For example, 100 individuals are lined up
          and numbered. The 7th individual is selected for the sample followed
          by every subsequent 9th individual until 10 sample items have been
          selected.
        </p>{" "}
        <p>
          {" "}
          <h3>Stratified Sampling</h3>
          <a href="/Stratified sampling">Stratified sampling</a> calls for more
          control over your sample. The population is divided into subgroups
          based on similar characteristics. Then you calculate how many people
          from each subgroup would represent the entire population. For example,
          100 individuals are grouped by gender and race. Then a sample from
          each subgroup is taken in proportion to how representative that
          subgroup is of the population.
        </p>{" "}
        <p>
          {" "}
          <h3>Cluster Sampling</h3>
          <a href="/Cluster sampling">Cluster sampling</a> calls for subgroups
          as well, but each subgroup should be representative of the population.
          The entire subgroup is randomly selected instead of randomly selecting
          individuals within a subgroup.
        </p>{" "}
      </div> */}
    </div>
  );
};

export default Biostats;

import React from 'react'
import "./Stats.css";

const Publication = () => {
  return (
    <div className='section'>
      <div>
      <h2>My Publications</h2>
      <ul>
          {/* <li>
            <a href="#Detection">Detection of Outliers Using Mahalanobis test of Distance</a>:<i> Awaiting approval</i>
            <h4>Abstract:</h4>
            <p></p>
          </li> */}
          {/* <li>
            <a href="#Comperative">Comperative Studies of the performance of male & female students in mathematics</a><i> Awaiting approval</i>
            <h4>Abstract:</h4>
            <p></p>
          </li> */}
          {/* <li>
            <a href="#Descriptive & Inferential Statistics">
              Descriptive & Inferential Statistics
            </a>
          </li>
          <li>
            <a href="#Mean, Median, and Mode">Mean, Median, and Mode</a>
          </li>
          <li>
            <a href="#Understanding Statistical Data">
              Understanding Statistical Data
            </a>
          </li>
          <li>
            <a href="#Levels of Measurement">Levels of Measurement</a>
          </li>
          <li>
            <a href="#Sampling Techniques">Sampling Techniques</a>
          </li> */}
          {/* <li>
            <a href="#Uses of Statistics">Uses of Statistics</a>
          </li> */}
        </ul>
      </div>
      <div>
      <h2>Scholar's Publications</h2>
      <ul>
          {/* <li>
            <a href="#Detection">Detection of Outliers Using Mahalanobis test of Distance</a>:<i> Awaiting approval</i>
            <p></p>
          </li>
          <li>
            <a href="#Comperative">Comperative Studies of the performance of male & female students in mathematics</a><i> Awaiting approval</i>
            <p></p>
          </li> */}
          {/* <li>
            <a href="#Descriptive & Inferential Statistics">
              Descriptive & Inferential Statistics
            </a>
          </li>
          <li>
            <a href="#Mean, Median, and Mode">Mean, Median, and Mode</a>
          </li>
          <li>
            <a href="#Understanding Statistical Data">
              Understanding Statistical Data
            </a>
          </li>
          <li>
            <a href="#Levels of Measurement">Levels of Measurement</a>
          </li>
          <li>
            <a href="#Sampling Techniques">Sampling Techniques</a>
          </li> */}
          {/* <li>
            <a href="#Uses of Statistics">Uses of Statistics</a>
          </li> */}
        </ul>
      </div>
    </div>
    
  )
}

export default Publication
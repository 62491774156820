import React from "react";

const Biometrics = () => {
  return (
    <div>
      <div className="table-of-contents">
        <h2>Table of Contents</h2>
        <ul>
          <li>
            <a href="#What Is Biometrics?">What Is Biometrics?</a>
          </li>
          <li>
            <a href="#Understanding Biometrics">Understanding Biometrics</a>
          </li>
          <li>
            <a href="#Protecting Financial Data">Protecting Financial Data</a>
          </li>
          <li>
            <a href="#Biometrics and Business">Biometrics and Business</a>
          </li>
          <li>
            <a href="#Limitations of Biometrics">Limitations of Biometrics</a>
          </li>
          <li>
            <a href="#Special Consideration">Special Consideration</a>
          </li>
        </ul>
      </div>
      <p>
        <i>
          <b>Backend from</b>: investopedia.com
        </i>
      </p>
      <div id="What Is Biometrics?" className="section">
        <h1>Biometrics Explained: Types and Biometrics and Business</h1>
        <h2>What Is Biometrics?</h2>
        <p>
          Biometrics are a group of digital security methods that rely on
          biological or physiological attributes and that are used to prevent
          data breaches such as credit card hacks or unauthorized logins.
          Biometrics use criteria that are physically unique to an individual
          that can prove their identity, such as a fingerprint or voice pattern,
          rather than relying on passwords or PIN codes that can be more easily
          hacked or stolen.{" "}
        </p>

        <p>
          {" "}
          <h5>
            <i>key Points;</i>
          </h5>
          <ul>
            <li>
              Biometrics refers to digitally encoding physical attributes of a
              user to gain access to data or computer systems.{" "}
            </li>
            <li>
              Fingerprints, facial recognition, and voice patterns are among
              some of the most widespread uses of biometrics today in both
              consumer and commercial use.
            </li>
            <li>
              By using physical identifiers that are unique to individuals,
              biometrics seeks to make hacking or gaining unauthorized access to
              computer systems much more difficult.
            </li>{" "}
            <li>
              Biometrics are used across business including in accounting, tax,
              human resources, and marketing.
            </li>{" "}
            <li>
              Biometrics may yield false positives, be costly to implement, and
              may not be a truly accessible option for all.
            </li>{" "}
          </ul>
        </p>
      </div>
      <div id="Understanding Biometrics" className="section">
        <h2>Understanding Biometrics</h2>
        <p>
          One common method of employing biometrics is the use of fingerprints
          as identification. This system can be used in more high-tech or
          high-security situations, but it has more recently been adapted on the
          individual consumer level.{" "}
        </p>
        <p>
          For example, Apple was the first major phone manufacturer to implement
          a fingerprint login system, starting with its iPhone 5s, and other
          companies followed soon after.1 Apple. "Apple Announces iPhone 5s—The
          Most Forward-Thinking Smartphone in the World." Other systems of
          biometrics include iris or retina scans and voice recognition
          software.{" "}
        </p>
        <p>
          Academics, financial analysts, and fund managers alike may determine a
          particular stock's probability distribution to evaluate the possible
          expected returns that the stock may yield in the future.{" "}
        </p>{" "}
        <p>
          As the world starts relying more heavily on technology and sharing
          information electronically, data breaches are increasingly common.
          Well-known examples of corporations that have been targeted by hackers
          over the past few years include Target and Home Depot. Biometrics is
          one method of counteracting these breaches.
        </p>
      </div>
      <div id="Protecting Financial Data" className="section">
        <h2>Biometrics and Protecting Financial Data</h2>
        <p>
          Since so many people rely on credit cards, online banking, and apps to
          transfer payments, it's essential to employ the use of techniques such
          as biometrics to prevent hacks, breaches, and fraud. Many banks and
          financial institutions use the technology inherent in newer phones by
          creating apps that require fingerprints to access data, and their
          individual biometrics systems become more advanced as the mobile
          technology develops. Customers can access secure banking information
          at the press of a finger.
        </p>{" "}
        <p>
          Other forms of biometrics also secure financial data. Facial
          recognition technology is used to secure customers' account
          information by relying on algorithms to match a face to a
          pre-registered template. ECG-reading bracelets can detect a person's
          heartbeat, synchronize it with a digital device, and use that data to
          log in to a secure network through Bluetooth. Voice recognition can be
          used to pre-register a template, then compare the authenticity of an
          identity based on the user's speech.
        </p>
      </div>
      <div id="Biometrics and Business" className="section">
        <h2>Biometrics and Business</h2>
        <p>
          Beyond personal finance, there is an entire world of biometric
          capabilities used to secure the financial assets of a company. In
          addition, biometrics can be used to protect customers. Below are
          various uses of biometrics divided across departments.
        </p>{" "}
        <h3>Biometrics and Accounting</h3>
        <p>
          Biometrics can play a vital part in not only accounting but the
          audit-related functions of a business. To ensure that only authorized
          individuals have access to sensitive financial data, biometrics can be
          used to manage access to accounting systems and software. This can
          involve utilizing a fingerprint or facial recognition system to access
          financial records or log into accounting software.{" "}
        </p>{" "}
        <p>
          By guaranteeing that only authorized personnel may approve financial
          transactions, biometrics can also be utilized to prevent fraud.
          Biometric authentication can be used, for instance, to verify the
          identification of those who authorize wire transfers and other
          financial activities.{" "}
        </p>{" "}
        <p>
          Employers can correctly report their work hours by using biometrics to
          track employee time and attendance. This can involve logging into
          accounting systems using a fingerprint or facial recognition system to
          clock in and out of work. Secure digital signatures for financial
          documents can also be provided through biometrics. This may involve
          having customers sign contracts, invoices, and other financial
          documents using their fingerprints or facial recognition technology.
          In either case, the audit function is enhanced by the ability to
          collect stronger, consistent data.{" "}
        </p>{" "}
        <h3>Biometrics and Marketing</h3>
        <p>
          Biometrics have a much different place in marketing. By assessing
          client reactions to product displays or making product recommendations
          based on their facial expressions, a face recognition system can be
          utilized to customize the customer experience. It can also be used to
          track customer eye movements to determine which products,
          advertisements, or displays the customer seem to be most interested
          in.{" "}
        </p>{" "}
        <p>
          Customer interactions with chatbots, virtual assistants, and other
          digital marketing channels can be examined using voice recognition
          technologies. By responding in a more tailored and efficient manner,
          this can assist marketers in improving the customer experience.{" "}
        </p>{" "}
        <p>
          Customer emotions can be detected and analyzed using biometric
          technology, giving marketers information into how customers feel about
          various goods, services, and marketing campaigns. This can aid
          marketers in developing campaigns that are more compelling and
          effective.{" "}
          <p>
            Last, customer feedback can be gathered through biometric surveys in
            a more precise and complete manner. Customers could be asked to
            review a product while having their pulse rate monitored, for
            instance, to gain more information into their degree of engagement
            and contentment.
          </p>
          <h3>Biometrics and Human Resources</h3>
          <p>
            The world of background checks may be altered due to biometrics, as
            employers can utilize biometric technology to confirm job
            candidates' identities and that they are who they say they are. This
            may involve verifying a candidate's identity during the recruiting
            process using fingerprint or facial recognition technology.{" "}
          </p>{" "}
          <p>
            The application of biometric technologies can also enhance workplace
            health and safety. For instance, some organizations use facial
            recognition to track employee temperatures and make sure that
            workers are adhering to safety and health procedures.{" "}
          </p>{" "}
          <p>
            Last, as biometrics are prevalent in enhancing security of personal
            information, employees may be at lower risk when using biometrics.
            Biometrics can be leveraged to lower the danger of data breaches
            which enhancing user experience. For instance, some workplaces that
            have implemented fingerprint or facial recognition for logging into
            software and systems used in the office may experience, arguably
            creating a safer, more secure environment for employees to work.{" "}
          </p>{" "}
          <h3>Biometrics and Tax</h3>
          <p>
            Taxpayers' identities can be verified using biometric technology,
            lowering their risk of fraud and identity theft. This may involve
            verifying the identity of taxpayers who file their tax returns
            online using a fingerprint or facial recognition technology.
          </p>{" "}
          <p>
            In some cases, biometrics can be used to reduce the exposure of
            sensitive information. Only authorized staff can access sensitive
            taxpayer information by using biometrics to manage access to tax
            systems and software. This can involve employing any number of
            biometric strategies discussed above to access taxpayer details or
            log into tax preparation software.
          </p>
          <p>
            Biometrics can even be used to identify duplicate filings or bogus
            claims in order to detect and stop tax fraud. For instance, based on
            the habits and behavior of the taxpayer, biometric technology can be
            used to examine tax returns for indications of fraud.
          </p>{" "}
          <p>
            Last, with the help of biometrics, the security of taxpayer data may
            be improved, guaranteeing that private data is shielded from illegal
            access or disclosure. This may involve limiting access to taxpayer
            data kept on computers or in the cloud. The only way to access this
            information is with verifiable biometric authorization.
          </p>
        </p>{" "}
      </div>
      <div id="Limitations of Biometrics" className="section">
        <h2>Limitations of Biometrics</h2>
        <p>
          Despite its strengths, biometrics may not be suitable in all cases.
          There are downsides of using biometrics for authentication such as:
        </p>{" "}
        <ul>
          <li>
            <b>Accuracy: </b> No biometric technique is 100% accurate, and false
            positives or false negatives are always a possibility. Accuracy can
            be impacted by external elements like lighting, background noise,
            and the biometric sensor's quality.
          </li>
          <li>
            <b>Security: </b> Just like any other sensitive data, biometric data
            is susceptible to theft or hacking. A hack of a biometric database
            may have detrimental effects, especially to corporations and
            especially if that entity was overly-reliant on the biometric
            security.
          </li>
          <li>
            <b>Privacy: </b> Since the usage of biometric data entails gathering
            and keeping sensitive information about persons, privacy issues are
            raised. Biometric information has the potential to be misused, such
            as when it is used for tracking or surveillance.
          </li>
          <li>
            <b>Cost: </b> Implementing and maintaining biometric technology can
            be expensive, particularly for large enterprises or governmental
            institutions.
          </li>
          <li>
            <b>Cultural and Social Factors: </b> Some cultures and communities
            may not accept or be appropriate for biometric technology, and some
            people may feel uncomfortable supplying their biometric information.
          </li>
          <li>
            <b>Accessibility: </b> Due to physical impairments like missing
            fingers or poor vision, some people may not be able to utilize some
            forms of biometric technology.
          </li>
        </ul>
      </div>
      <div id="Special Consideration" className="section">
        <h2>Special Consideration</h2>
        <p>
          The promise of using data unique to each person in order to keep
          information safe is an attractive idea to financial investors, since
          biometrics is synonymous with enhanced security. Biometrics is an
          evolving technology, and it holds promise for the future. However, if
          you are considering investing in a company and are taking into account
          its use of biometrics, be sure to research the exact technology in
          use.
        </p>{" "}
        <p>
          Some forms of biometrics are more secure than others; some companies
          have more intelligent systems than others. It is worth looking into
          the company's history to see if the technology has ever been subject
          to controversy or even has been breached in the past. You should also
          take into account how well the particular biometric technology
          functions in the context of the company's operation.
        </p>
      </div>
    </div>
  );
};

export default Biometrics;

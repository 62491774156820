import React from "react";
import "./Population.css";

const DescriptiveStats = () => {
  return (
    <div>
      <div className="table-of-contents">
        <h2>Table of Contents</h2>
        <ul>
          <li>
            <a href="# What Are Descriptive Statistics?">
              {" "}
              What Are Descriptive Statistics?
            </a>
          </li>
          <li>
            <a href="#Understanding Descriptive Statistics">
              Understanding Descriptive Statistics
            </a>
          </li>
          <li>
            <a href="#Types of Descriptive Statistics">
              Types of Descriptive Statistics
            </a>
          </li>
          <li>
            <a href="#Understanding Univariate vs. Bivariate">
              Understanding Univariate vs. Bivariate
            </a>
          </li>
          <li>
            <a href="#Descriptive Statistics vs. Inferential Statistics">
              Descriptive Statistics vs. Inferential Statistics
            </a>
          </li>
        </ul>
      </div>
      <p>
        <i>
          <b>Backend from</b>: investopedia.com
        </i>
      </p>
      <div id=" What Are Descriptive Statistics?" className="section">
        <h1>Descriptive Statistics: Definition, Overview, Types, Example</h1>
        <h2> What Are Descriptive Statistics?</h2>
        <p>
          Descriptive statistics are brief informational coefficients that
          summarize a given data set, which can be either a representation of
          the entire population or a sample of a population. Descriptive
          statistics are broken down into measures of central tendency and
          measures of variability (spread). Measures of central tendency include
          the mean, median, and mode, while measures of variability include
          standard deviation, variance, minimum and maximum variables,{" "}
          <a href="/statistics/kurtosis">kurtosis</a>, and <a href="statistics/skewness">skewness</a>
          .{" "}
        </p>

        <p>
          {" "}
          <h5>
            <i>key Points;</i>
          </h5>
          <ul>
            <li>
              Descriptive statistics summarizes or describes the characteristics
              of a data set.{" "}
            </li>

            <li>
              Descriptive statistics consists of three basic categories of
              measures: measures of central tendency, measures of variability
              (or spread), and frequency distribution.
            </li>
            <li>
              Measures of central tendency describe the center of the data set
              (mean, median, mode).
            </li>
            <li>
              Measures of variability describe the dispersion of the data set
              (variance, standard deviation).{" "}
            </li>
            <li>
              Measures of frequency distribution describe the occurrence of data
              within the data set (count).{" "}
            </li>
          </ul>
        </p>
      </div>
      <div id="Understanding Descriptive Statistics" className="section">
        <h2>Understanding Descriptive Statistics</h2>
        <p>
          Descriptive statistics, in short, help describe and understand the
          features of a specific data set by giving short summaries about the
          sample and measures of the data. The most recognized types of
          descriptive statistics are measures of center: the mean, median, and
          mode, which are used at almost all levels of math and statistics. The
          mean, or the average, is calculated by adding all the figures within
          the data set and then dividing by the number of figures within the
          set.{" "}
        </p>
        <p>
          For example, the sum of the following data set is 20: (2, 3, 4, 5, 6).
          The mean is 4 (20/5). The mode of a data set is the value appearing
          most often, and the median is the figure situated in the middle of the
          data set. It is the figure separating the higher figures from the
          lower figures within a data set. However, there are less common types
          of descriptive statistics that are still very important.{" "}
        </p>
        <p>
          People use descriptive statistics to repurpose hard-to-understand
          quantitative insights across a large data set into bite-sized
          descriptions. A student's grade point average (GPA), for example,
          provides a good understanding of descriptive statistics. The idea of a
          GPA is that it takes data points from a wide range of exams, classes,
          and grades, and averages them together to provide a general
          understanding of a student's overall academic performance. A student's
          personal GPA reflects their mean academic performance.{" "}
        </p>
      </div>
      <div id="Types of Descriptive Statistics" className="section">
        <h2>Types of Descriptive Statistics</h2>
        <p>
          All descriptive statistics are either measures of central tendency or
          measures of variability, also known as measures of dispersion.
        </p>
        <p>
          {" "}
          <h3>Central Tendency</h3>
          Measures of central tendency focus on the average or middle values of
          data sets, whereas measures of variability focus on the dispersion of
          data. These two measures use graphs, tables and general discussions to
          help people understand the meaning of the analyzed data.
        </p>{" "}
        <p>
          Measures of central tendency describe the center position of a
          distribution for a data set. A person analyzes the frequency of each
          data point in the distribution and describes it using the mean,
          median, or mode, which measures the most common patterns of the
          analyzed data set.{" "}
        </p>{" "}
        <p>
          {" "}
          <h3>Stratified Random Sampling</h3>
          This type of sampling, also referred to as proportional random
          sampling or quota random sampling, divides the overall population into
          smaller groups. These are known as strata. People within the strata
          share similar characteristics.
        </p>{" "}
        <p>
          What if age was an important factor that researchers would like to
          include in their data? Using the stratified random sampling technique,
          they could create layers or strata for each age group. The selection
          from each stratum would have to be random so that everyone in the
          bracket has a likely chance of being included in the sample. For
          example, two participants, Alex and David, are 22 and 24 years old,
          respectively. The sample selection cannot pick one over the other
          based on some preferential mechanism. They both should have an equal
          chance of being selected from their age group. The strata could look
          something like this:{" "}
        </p>{" "}
        {/* image example here */}
        <img
          className="img_img"
          alt="The red circles form a sample of the population of all circles on the page. C.K.Taylor"
          src="https://www.investopedia.com/thmb/o1-dvQQUK9GKvpX80-uJpQKUjhk=/1394x0/filters:no_upscale():max_bytes(150000):strip_icc():format(webp)/sample-569d3c825f9b58eba4ac0a79.jpg"
        />
        <p>
          From the table, the population has been divided into age groups. For
          example, 30,000 people within the age range of 20 to 24 years old took
          the CFA exam in 2021. Using this same proportion, the sample group
          will have (30,000 ÷ 200,000) × 1,000 = 150 test-takers that fall
          within this group. Alex or David—or both or neither—may be included
          among the 150 random exam participants of the sample.{" "}
        </p>{" "}
        <p>
          There are many more strata that could be compiled when deciding on a
          sample size. Some researchers might populate the job functions,
          countries, marital status, etc., of the test-takers when deciding how
          to create the sample.{" "}
        </p>{" "}
      </div>
      <div id="Understanding Univariate vs. Bivariate" className="section">
        <h2>Understanding Univariate vs. Bivariate</h2>
        <p>
          In 2021, the population of the world was nearly 7.9 billion, out of
          which 49.6% were female and 50% were male.4 The World Bank Group.
          "Population, Female (% of Total Population)." 56 The total number of
          people in any given country can also be a population size. The total
          number of students in a city can be taken as a population, and the
          total number of dogs in a city is also a population size. Samples can
          be taken from these populations for research purposes.
        </p>{" "}
        <p>
          Following our CFA exam example, the researchers could take a sample of
          1,000 CFA participants from the total 200,000 test-takers—the
          population—and run the required data on this number. The mean of this
          sample would be taken to estimate the average of CFA exam takers that
          passed even though they only studied for less than 40 hours
        </p>{" "}
        <p>
          The sample group taken should not be biased. This means that if the
          sample mean of the 1,000 CFA exam participants is 50, the population
          mean of the 200,000 test-takers should also be approximately 50.
        </p>
        <p>
          As an illustration, assume that the population being studied is all of
          the zeroes depicted in the image below. The red circles form a sample
          of the population of all circles on the page.
        </p>{" "}
      </div>
    </div>
  );
};

export default DescriptiveStats;

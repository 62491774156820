import React from "react";
import "./ContactUs.css";
const ContactUsPage = () => {
  return (
    <div>
      <div id="contact?" className="section">
        <h1>Contact</h1>
        <h2>
          <i>Office Address: </i>Department of Statistics and computing: Kano university of Science and Tecnology,
          Wudil, LGA, Kano State – 234064. Nigeria
        </h2>
        <p>
          <h1>Talk to Us</h1>
         <p><b>Projects and Consultation: </b>supports@feenahconsult.org.ng</p> 
          {/* Query: yusuffeenah1139@yahoo.com */}
        </p>
      </div>
      <h1>Contact Us</h1>
      <form>
        <label>Subject: </label>
        <input type="text" name="subject" />
        <label>Comment: </label>
        <textarea name="comment" />
        <button type="submit">Submit</button>
      </form>
    </div>
  );
};

export default ContactUsPage;

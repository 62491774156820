import React from "react";
import 'bootstrap/dist/css/bootstrap.min.css';
import { BrowserRouter, Route, Routes } from "react-router-dom";
import Navigator from "./components/Navigator";
import Home from "./Home";
import Biostats from "./pages/Biostats";
import Econometrics from "./pages/Econometrics";
import Statistics from "./pages/Statistics";
import Projects from "./pages/Projects";
import Publication from "./pages/Publication";
import Resources from "./pages/Resources";
import About from "./components/About";
import Datum from "./pages/Datum";
import Nationalbs from "./data/Nationalbs";
import Openeuro from "./data/Openeuro";
import Statisticsauthority from "./data/Statisticsauthority";
import Worldhealth from "./data/Worldhealth";
import Internationalmonetary from "./data/Internationalmonetary";
import Usaiddata from "./data/Usaiddata";
import Worlbankdata from "./data/Worlbankdata";
import Tables from "./pages/Tables";
import Normaldist from "./Tables/Normaldist";
import Binomial from "./Tables/Binomial";
import Chisquare from "./Tables/Chisquare";
import Studenttable from "./Tables/Studenttable";
import Fdistribution from "./Tables/Fdistribution";
import Mannwhitney from "./Tables/Mannwhitney";
import Kruskal from "./Tables/Kruskal";
import Wilcoxon from "./Tables/Wilcoxon";
import Kendall from "./Tables/Kendall";
import Kilmogorov from "./Tables/Kilmogorov";
import Smirnovequal from "./Tables/Smirnovequal";
import Smirnovdiff from "./Tables/Smirnovdiff";
import Population from "./pages/notes/statistics/Population";
import Samples from "./pages/notes/statistics/Samples";
import DescriptiveStats from "./pages/notes/statistics/DescriptiveStats";
import Skewness from "./pages/notes/statistics/Skewness";
import Kutosis from "./pages/notes/statistics/Kutosis";
import Mean from "./pages/notes/statistics/Mean";
import Footer from "./components/Footer";
import Median from "./pages/notes/statistics/Median";
import ContactUsPage from "./components/ContactUs";
import Mode from "./pages/notes/statistics/Mode";
import Probabilty from "./pages/notes/statistics/Probabilty";
import Biometrics from "./pages/notes/biostats/Biometrics";
import Stochastic from "./pages/notes/statistics/Stochastic";
import Nullhypothesis from "./pages/notes/statistics/Nullhypothesis";
import Timeseries from "./pages/Timeseries";
import InferentialStats from "./pages/notes/statistics/InferentialStats";
import BasicStats from "./pages/notes/statistics/BasicStats";
import Bestfits from "./pages/notes/statistics/Bestfits";
import ErrorType from "./pages/notes/statistics/ErrorType";
import Typei from "./pages/notes/statistics/Typei";
import Typeii from "./pages/notes/statistics/Typeii";
import Alternative from "./pages/notes/statistics/Alternative";
import Outliers from "./pages/notes/statistics/Outliers";
import Entertainements from "./components/Entertainements";
import Certificat from "./pages/Certificat";
import Works from "./pages/Works";
import GirlChild from "./pages/notes/works/GirlChild";
import Leadership from "./pages/notes/works/Leadership";
function App() {
  return (
    <div className="App">
    <BrowserRouter>
    <Navigator />
    <Entertainements />
    <Routes>
      <Route path="/" element={<Home />} />
      <Route path="/contactus" element={<ContactUsPage />} />
      <Route path="/data" element={<Datum />} />
      <Route path="/awards&certs" element={<Certificat />} />
      <Route path="/basic-statistics" element={<BasicStats />} />

      <Route path="/inferential-statistics" element={<InferentialStats />} />

      <Route path="/bestfits" element={<Bestfits />} />

      <Route path="/statistics/stochastic" element={<Stochastic />} />
      

      <Route path="/category/biostatistics" element={<Biostats />} />
      <Route path="/biostats/biometrics" element={<Biometrics />} />



      <Route path="/category/econometrics" element={<Econometrics />} />

      <Route path="/category/time-series" element={<Timeseries />} />


      
      <Route path="/category/statistics" element={<Statistics />} />
      <Route path="/statistics/population" element={<Population />} />
      <Route path="/statistics/descriptive" element={<DescriptiveStats />} />
      <Route path="/statistics/sample" element={<Samples />} />
      <Route path="/statistics/skewness" element={<Skewness />} />
      <Route path="/statistics/kurtosis" element={<Kutosis />} />
      <Route path="/statistics/mean" element={<Mean />} />
      <Route path="/statistics/median" element={<Median />} />
      <Route path="/statistics/null-hypothesis" element={<Nullhypothesis />} />
      <Route path="/statistics/alternative-hypothesis" element={<Alternative />} />
      <Route path="/statistics/mode" element={<Mode />} />
      <Route path="/statistics/errori&ii" element={<ErrorType />} />
      <Route path="/statistics/errori&ii/typei" element={<Typei />} />
      <Route path="/statistics/errori&ii/typeii" element={<Typeii />} />
      <Route path="/statistics/outliers" element={<Outliers />} />


      <Route path="/probabilitydistribution" element={<Probabilty />} />

      
      <Route path="/works_comm_development" element={<Works />} />
      <Route path="/works_comm_development/girls_child_advocate" element={<GirlChild />} />
      <Route path="/works_comm_development/role_of_leader" element={<Leadership />} />


      <Route path="/category/project" element={<Projects />} />
      <Route path="/category/publication" element={<Publication />} />
      <Route path="/about" element={<About />} />
      <Route path="/resource" element={<Resources />} />
      <Route path="/nbs-nigeria" element={<Nationalbs />} />
      <Route path="/openneuro" element={<Openeuro />} />
      <Route path="/uk-stats" element={<Statisticsauthority />} />
      <Route path="/world-health-org" element={<Worldhealth />} />
      <Route path="/international-monetary-funds" element={<Internationalmonetary />} />
      <Route path="/usaid" element={<Usaiddata />} />
      <Route path="/world-bank" element={<Worlbankdata />} />
      <Route path="/tables" element={<Tables />} />
      <Route path="/binomial-dist-table" element={<Binomial />} />
      <Route path="/chi-square-dist-table" element={<Chisquare />} />
      <Route path="/normal-dist-table" element={<Normaldist />} />
      <Route path="/student-t-dist-table" element={<Studenttable />} />
      <Route path="/f-dist-table" element={<Fdistribution />} />
      <Route path="/mann-whitney-test-stats" element={<Mannwhitney />} />
      <Route path="/kruskal-wallis-stats" element={<Kruskal />} />
      <Route path="/wilcoxon-signed-rank-test" element={<Wilcoxon />} />
      <Route path="/kendall-test-stats" element={<Kendall />} />
      <Route path="/kilmogorov-test-stats" element={<Kilmogorov />} />
      <Route path="/smirnov-test-stats-equal" element={<Smirnovequal />} />
      <Route path="/smirnov-test-stats-different" element={<Smirnovdiff />} />
    </Routes>
      <Footer />
    </BrowserRouter>
  </div>
)
}

export default App;
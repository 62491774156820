import React from "react";
import "./Population.css";

const Population = () => {
  return (
    <div>
      <div className="table-of-contents">
        <h2>Table of Contents</h2>
        <ul>
          <li>
            <a href="#What Is Population?">What Is Population?</a>
          </li>
          <li>
            <a href="#Understanding Populations">Understanding Populations</a>
          </li>
          <li>
            <a href="#How to Calculate a Population">
              How to Calculate a Population
            </a>
          </li>
          <li>
            <a href="#Population vs. Samples">Population vs. Samples</a>
          </li>
          <li>
            <a href="#Demographic Meaning of Population">
              Demographic Meaning of Population
            </a>
          </li>
          {/* <li>
            <a href="#Levels of Measurement">Levels of Measurement</a>
          </li>
          <li>
            <a href="#Sampling Techniques">Sampling Techniques</a>
          </li> */}
          {/* <li>
            <a href="#Uses of Statistics">Uses of Statistics</a>
          </li> */}
        </ul>
      </div>
      <p>
        <i>
          <b>Backend from</b>: investopedia.com
        </i>
      </p>
      <div id="What Is Population?" className="section">
        <h1>Statistical Population Definition and How to Measure It</h1>
        <h2>What Is Population?</h2>
        <p>
          A population is the complete set group of individuals, whether that
          group comprises a nation or a group of people with a common
          characteristic. In <a href="/category/statistics">statistics</a>, a
          population is the pool of individuals from which a statistical sample
          is drawn for a study. Thus, any selection of individuals grouped by a
          common feature can be said to be a population. A{" "}
          <a href="/sample">sample</a> may also refer to a statistically
          significant portion of a population, not an entire population. For
          this reason, a statistical analysis of a sample must report the
          approximate standard deviation, or standard error, of its results from
          the entire population. Only an analysis of an entire population would
          have no standard error.{" "}
        </p>

        <p>
          {" "}
          <h5>
            <i>key Points;</i>
          </h5>
          <ul>
            <li>
              In ordinary usage, a population is a distinct group of individuals
              with shared citizenship, identity, or characteristics.{" "}
            </li>

            <li>
              In statistics, a population is a representative sample of a larger
              group of people (or even things) with one or more characteristics
              in common.
            </li>
            <li>
              The members of a sample population must be randomly selected for
              the results of the study to accurately reflect the whole.
            </li>
            <li>
              The U.S. Census is perhaps the most ambitious survey in existence,
              given that it entails a door-to-door canvas of the entire
              population rather than a sample group study..{" "}
            </li>
            <li>
              Population surveys large and small inform many if not most
              decisions by government and business.
            </li>
          </ul>
        </p>
      </div>
      <div id="Understanding Populations" className="section">
        <h2>Understanding Populations</h2>
        <p>
          In most everyday uses, the word population implies a group of people
          or at least a group of living beings. However, statisticians refer to
          whatever group they are studying as a population. The population of a
          study might be babies born in North America in 2021, the total number
          of tech startups in Asia since the year 2000, the average height of
          all accounting examination candidates, or the mean weight of U.S.
          taxpayers.{" "}
        </p>
        <p>
          Statisticians and researchers prefer to know the characteristics of
          every entity in a population to draw the most precise conclusions
          possible. This is impossible or impractical most of the time, however,
          since population sets tend to be quite large.{" "}
        </p>
        <p>
          {" "}
          <i>For instance</i>, if a company wanted to know whether most of its
          50,000 customers were satisfied with the company's service last year,
          it would be impractical to call every client on the phone to conduct a
          survey. A sample of the population must be taken since the
          characteristics of every individual in a population cannot be measured
          due to constraints of time, resources, and accessibility.
        </p>
      </div>
      <div id="How to Calculate a Population" className="section">
        <h2>How to Calculate a Population</h2>
        <p>
          A population can be defined narrowly, such as the number of newborn
          babies in North America with brown eyes, the number of startups in
          Asia that failed in less than three years, the average height of all
          female accounting examination candidates, or the mean weight of all
          U.S. taxpayers over age 30.{" "}
        </p>
        <p>
          The science of political polling offers a good example of the
          difficulty of selecting a random sampling of the population. One of
          the reasons why many of the last two presidential election polls have
          been wrong could be that the type of people who willingly answer poll
          questions may not constitute a random sample of the population of
          likely voters.{" "}
        </p>{" "}
        <p>
          Nonetheless, surveys and polls may be the only efficient way to
          identify and validate issues and trends that affect the wider
          population. For example, growing concerns have been expressed about
          harassment online, but how common is it? A study by Pew Research
          indicates that 41% of American adults have experienced online
          harassment, with 11% reporting they had been outright stalked, and 14%
          saying they had been physically threatened..{" "}
        </p>
      </div>
      <div id="Population vs. Samples" className="section">
        <h2>Population vs. Samples</h2>
        <p>
          A sample is a random selection of members of a population. It is a
          smaller group drawn from the population that has the characteristics
          of the entire population. The observations and conclusions made
          against the sample data are attributed to the population as a whole.
        </p>{" "}
        <p>
          The information obtained from the statistical sample allows
          statisticians to develop hypotheses about the larger population. In
          statistical equations, the population is usually denoted with an
          uppercase N while the sample is usually denoted with a lowercase n.
        </p>{" "}
        <p>
          There are several ways to obtain samples{" "}
          <a href="/sampling">(known as sampling)</a> from a population. These
          include a simple <a href="/randomsample">random sample</a>, stratified
          sampling, representative sampling, and convenience sampling.
          Researchers and analysts employ a range of statistical techniques to
          infer information about the broader population using just the smaller
          sample chosen. Note that sample size is an important issue when
          conducting such inference - if the sample is too small it may be
          biased and not trustworthy, while larger samples may be overly
          expensive and time-consuming to collect and analyze.
        </p>
        <p>
          As an illustration, assume that the population being studied is all of
          the zeroes depicted in the image below. The red circles form a sample
          of the population of all circles on the page.
        </p>{" "}
        <img className="img_img"
          alt="The red circles form a sample of the population of all circles on the page. C.K.Taylor"
          src="https://www.investopedia.com/thmb/o1-dvQQUK9GKvpX80-uJpQKUjhk=/1394x0/filters:no_upscale():max_bytes(150000):strip_icc():format(webp)/sample-569d3c825f9b58eba4ac0a79.jpg"
        />
        <p>
          <i>
            The red circles form a sample of the population of all circles on
            the page. C.K.Taylor
          </i>
        </p>
        <p>
          {" "}
          <h3>Population Parameters</h3>A parameter is data based on an entire
          population. Statistics such as <a href="/averages">averages</a>{" "}
          (means) and <a href="/standarddeviations">standard deviations</a>,
          when taken from populations, are referred to as population parameters.
          The population mean and population standard deviation are represented
          by the Greek letters µ and σ, respectively
        </p>{" "}
        <p>
          A valid statistic may be drawn from either a population sample or a
          study of an entire population. The objective of a random sample is to
          avoid <a href="/bias">bias</a> in the results. A sample is random if
          every member of the whole population has an equal chance to be
          selected to participate.
        </p>{" "}
        <p>
          While a parameter is a characteristic of a population, a statistic is
          a characteristic of a sample. Inferential statistics enables you to
          make an educated guess about a population parameter based on a
          statistic computed from a sample randomly drawn from that population.
        </p>{" "}
        <p>
          The standard deviation, for example, is the variation of some variable
          in the population, which can be inferred from the variation observed
          in the sample. But, because this is being inferred from a sample,
          there will always be some sort of error term describing how likely it
          is that the analysis from the sample does not reflect the true
          standard deviation (or mean, etc.). Various statistical tools like
          <a href="/confidenceintervals"> confidence intervals</a>, the{" "}
          <a href="/t-test">t-test</a>, and <a href="p-values">p-values</a> can
          inform an analyst of how confident they might be in making such
          inferences.
        </p>{" "}
      </div>
      <div id="Demographic Meaning of Population" className="section">
        <h2>Demographic Meaning of Population</h2>
        <p>
          While population can refer to any complete set of data in the
          statistical sense, population takes on another meaning when we talk
          about the demographic or geopolitical context. Here, a population
          refers to the entirety of the people inhabiting a particular region,
          country, or even the entire planet. Census counts keep track of the
          number of citizens that populate different counties along with their
          characteristics such as age, race, gender, income, occupation, and so
          on. Population counts are important for governments in order to
          collect taxes and allocate the proper amount of funding to various
          infrastructure and social programs.
        </p>{" "}
        <p>
          Demography is the study of populations and their characteristics, and
          how these change over time and from place to place. Population
          statistics and <a href="/demographics">demographics</a> inform public policy and business
          decisions. Some examples:
        </p>{" "}
        <p>
          <i>
            <b>Example</b>
          </i>
          : The World Bank is an international organization that aims to reduce
          global poverty by lending money to poor nations for projects that
          improve their economies and raise their overall standard of living. To
          pinpoint where help is most needed, the Bank conducts an
          authoritative, country-by-country headcount based on local data of
          people living in extreme poverty. The numbers fell steadily from over
          40% of the global population in 1981 to as low as 8.7% in 2018,
          according to the Bank.3 However, in 2020, the impact of the COVID-19
          epidemic was expected to cause the first yearly increase in extreme
          poverty in more than 20 years.{" "}
        </p>
        <p>
          <i>
            <b>Example</b>
          </i>
          : <a href="/The-U.S.-Census">The U.S. Census</a>, mandated once a decade by the U.S. Constitution, is
          probably the most ambitious <a href="/population-study">population study</a> in existence, given that
          it is not a sample but an actual door-to-door count. It is used to
          determine how many congressional seats each state gets and how federal
          funds are distributed. The data also is used by many other entities,
          private and public, to decide where hospitals and schools are built,
          where businesses locate, and what types of homes are built.{" "}
        </p>
        <p>
          <i>
            <b>Example</b>
          </i>
          : The Centers for Disease Control and Prevention has been conducting a
          National Health Interview Survey since 1957 to identify and track
          health issues and problems.6 Centers for Disease Control and
          Prevention. "National Health Interview Survey." Its recent reports
          include studies of chronic conditions among military veterans,
          opioid-related visits to emergency wards, and the quality of care for
          Americans suffering from dementia.{" "}
        </p>
      </div>
    </div>
  );
};

export default Population;

import React from 'react'
import { Link } from 'react-router-dom'

const Projects = () => {
  return (
    <div>
     <h2> My Projects </h2> 
      <ul><Link to="/">Comperative Studies on the performance of female and male students in mathematics</Link></ul>
      <p>Summary:</p>
      <ul><Link to="/">Using Mahalonobis to eliminate anomaly in data</Link></ul>
      <p>Summary:</p>
    </div>
  )
}

export default Projects
import React from 'react'
import work from '../work'
import { Row, Col } from "react-bootstrap";
import { LinkContainer } from "react-router-bootstrap";

const Works = () => {
  return (
    <div className="recent-products-container mt-4">
    <h2>Works & Community Development</h2>
    <Row>
      {work.map((category) => (
        <LinkContainer
          to={`/works_comm_development/${category.name.toLocaleLowerCase()}`}
        >
          <Col md={4}>
            <div
              className="category-tile"
              style={{
                backgroundImage: `linear-gradient(rgba(0, 0, 0, 0.5), rgba(0, 0, 0, 0.5)), url(${category.img})`,
                gap: "10px",
              }}
            >
              {category.name}
            </div>
          </Col>
        </LinkContainer>
      ))}
    </Row>
  </div>
  )
}

export default Works
import React from "react";
import {
  AiFillFacebook,
  AiFillInstagram,
  AiFillYoutube,
  AiOutlineTwitter,
} from "react-icons/ai";
import "./Footer.css";

const Footer = () => {
  return (
    <div className="inner_content">
      <div className="first_container">
        <h1 className="subs">
          <span className="new_events">Subscribe</span> to our newsletter and upcoming<br />
          events
        </h1>
        <div>
          <input
            type="text"
            required
            placeholder="Enter your email..."
            className="text_area"
          />
          <button className="buuton">Submit</button>
        </div>
      </div>
      <div className="socials">
        <ul className="socials_inner">
          <img src="/" alt="" style={{ filter: "brightness(0) invert(1)" }} />
          <br />
          <p>
            The home to Understanding the basic and meaning of data analysis.
          </p>
          <div className="social_icons">
                      <a href="https://www.facebook.com/" target="_blank" rel="noreferrer">
                        <AiFillFacebook size={25} style={{ marginLeft: "15px", cursor: "pointer" }} />
                      </a>
                      <a href="https://twitter.com/" target="_blank" rel="noreferrer">
                        <AiOutlineTwitter
                          size={25}
                          style={{ marginLeft: "15px", cursor: "pointer" }}
                        />
                      </a>
                      <a href="https://www.instagram.com/" target="_blank " rel="noreferrer">
                        <AiFillInstagram
                          size={25}
                          style={{ marginLeft: "15px", cursor: "pointer" }}
                        />
                      </a>
                      <a href="https://www.youtube.com/" target="_blank" rel="noreferrer">
                        <AiFillYoutube
                          size={25}
                          style={{ marginLeft: "15px", cursor: "pointer" }}
                        />
                      </a>
          </div>
        </ul>
      </div>

      <div className="footer">
        <span><i>© 2023 Developer_Mine</i></span>
        <br />
        <span>
          <a href="/">Terms</a> · <a href="/">Privacy Policy</a>
        </span>
      </div>
    </div>
  );
};

export default Footer;

import React from "react";
import banner from "../statistics/img/banner.jpg";
import banner2 from "../statistics/img/awards.jpg";

import "./Work.css";

const GirlChild = () => {
  return (
    <div id="Recommended Topics" className="section">
      <h2>
        <i>Introduction ::</i>
      </h2>
      <p>
        In a world driven by technology, promoting girl child participation in
        STEM (Science, Technology, Engineering, and Mathematics) is not just a
        goal but a necessity. As an enthusiastic advocate for education and
        gender equality, my ongoing volunteer role has been focused on inspiring
        young girls to break barriers and embrace STEM.
      </p>{" "}
      <h2>
        <i>Motivation & Volunteer Role ::</i>
      </h2>
      <p>
        Growing up, I witnessed the gender disparities within STEM fields and
        their detrimental effects on girl's dreams. Recognizing the untapped
        potential and the urgent need for change, I embarked on a mission to
        shatter stereotypes and encourage girls to envision themselves as future
        scientists, engineers, and innovators. It was personal—the empowerment
        of one girl ripples into the empowerment of an entire generation.
      </p>{" "}
      <p>
        As a dedicated volunteer, I've engaged in various initiatives aimed at
        igniting girl's interest in STEM. From organizing interactive workshops
        to fostering mentorship and hopefully, will connect with accomplished
        women in STEM very soon, my programs foster a sense of belonging and
        aspiration. These events are not merely educational; they are
        opportunities for girls to explore, experiment, and envision their
        potential.
      </p>
      <h2>
        <i>Achievements::</i>
      </h2>
      <p>
        My efforts have yielded remarkable achievements. The number of girls
        participating in my program has surged, indicating a shift in
        perceptions and ambitions. Through hands-on activities and engaging
        discussions, I've witnessed the spark of curiosity turning into a flame
        of passion. Girls who were once hesitant are now enthusiastic
        participants, excited to learn and innovate.
      </p>
      <h2>
        <i>IMPORTANCE OF STEM EDUCATION FOR GIRL’S::</i>
      </h2>
      <p>
        The value of STEM education for girls cannot be overstated. Access to
        quality education equips them with tools to contribute meaningfully to
        society, bridging the gender gap in lucrative fields. Educated girls
        become empowered women who drive economic growth, champion social
        change, and inspire the next generation. Gender Equality: Education is a
        fundamental right and a catalyst for gender equality. When girls are
        educated, they are more likely to challenge traditional gender roles and
        stereotypes, advocate for their rights, and participate in
        decision-making processes. Education promotes equal opportunities for
        girls, bridging the gender gap and reducing discrimination. Health and
        Well-being: Educated girls are more likely to have better health
        outcomes for themselves and their families. They are more knowledgeable
        about nutrition, hygiene, reproductive health, and child care, leading
        to improved maternal and child health. Education also helps prevent
        early marriages and pregnancies, reducing the risks associated with
        adolescent motherhood.
      </p>
      <p>
        STEM is critical to society as it drives innovation, economic growth,
        and job creation, fosters problem-solving skills, addresses global
        challenges, promotes sustainable development, enhances global
        competitiveness, and advances equity and inclusion. Emphasizing STEM
        education and nurturing STEM talent is essential for building a
        prosperous and sustainable future for individuals, communities, and the
        world as a whole. Furthermore, girl child education is crucial for
        individual empowerment, gender equality, health, economic development,
        poverty reduction, social and political participation, and protection
        against exploitation. It is a key investment in building a more
        equitable and sustainable future for societies worldwide.
      </p>
      <div className="img_prog">
        {/* image example here */}
        <img alt="banner" src={banner} />

        <img alt="banner" src={banner2} />

        
      </div>{" "}
    </div>
  );
};

export default GirlChild;
